import {
  BookingStatus,
  BookingType,
  Direction,
  OrderBy,
  PageInfo,
  SupportTicketStatus,
} from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateRangeMode } from 'components/DateFilter';

export interface States {
  dateRangeMode: DateRangeMode;
  endCursor: string;
  endTime: string | null;
  filterTicketStatus: SupportTicketStatus | string;
  first: number | null;
  firstRecordDate: string | null;
  isGridReady: boolean;
  isShowUtilityDrawer: boolean;
  lastRecordDate: string | null;
  orderBy: OrderBy;
  pageInfo: PageInfo | null | undefined;
  pageNumber: number;
  pageSize: number;
  recordLoaded: number;
  rowIndex: number;
  startTime: string | null;
}

const initialState: States = {
  dateRangeMode: 'last-1y-desc',
  endCursor: '',
  endTime: null,
  filterTicketStatus: SupportTicketStatus.Open,
  first: 1000,
  firstRecordDate: null,
  isGridReady: false,
  isShowUtilityDrawer: false,
  lastRecordDate: null,
  orderBy: {
    direction: Direction.Desc,
    field: 'dateOpened',
  },
  pageInfo: null,
  pageNumber: 0,
  pageSize: 100,
  recordLoaded: 0,
  rowIndex: -1,
  startTime: null,
};

export const managementSystemListTicketsSlice = createSlice({
  initialState,
  name: 'managementSystemListTickets',
  reducers: {
    setDateRangeMode: (state, { payload }: PayloadAction<DateRangeMode>) => {
      state.dateRangeMode = payload;
    },
    setEndCursor: (state, { payload }: PayloadAction<string>) => {
      state.endCursor = payload;
    },
    setEndTime: (state, { payload }: PayloadAction<string>) => {
      state.endTime = payload;
    },
    setFilterTicketStatus: (
      state,
      { payload }: PayloadAction<SupportTicketStatus | string>,
    ) => {
      state.filterTicketStatus = payload;
    },
    setFirst: (state, { payload }: PayloadAction<number | null>) => {
      state.first = payload;
    },
    setFirstRecordDate: (state, { payload }: PayloadAction<string>) => {
      state.firstRecordDate = payload;
    },
    setIsGridReady: (state, { payload }: PayloadAction<boolean>) => {
      state.isGridReady = payload;
    },
    setIsShowUtilityDrawer: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowUtilityDrawer = payload;
    },
    setLastRecordDate: (state, { payload }: PayloadAction<string>) => {
      state.lastRecordDate = payload;
    },
    setOrderBy: (state, { payload }: PayloadAction<OrderBy>) => {
      state.orderBy = payload;
    },
    setPageInfo: (state, { payload }: PayloadAction<PageInfo>) => {
      state.pageInfo = payload;
    },
    setPageNumber: (state, { payload }: PayloadAction<number>) => {
      state.pageNumber = payload;
    },
    setPageSize: (state, { payload }: PayloadAction<number>) => {
      state.pageSize = payload;
    },
    setRecordLoaded: (state, { payload }: PayloadAction<number>) => {
      state.recordLoaded = payload;
    },
    setRowIndex: (state, { payload }: PayloadAction<number>) => {
      state.rowIndex = payload;
    },
    setStartTime: (state, { payload }: PayloadAction<string>) => {
      state.startTime = payload;
    },
  },
});

export default managementSystemListTicketsSlice.reducer;

export const {
  setDateRangeMode: setDateRangeModeAction,
  setEndCursor: setEndCursorAction,
  setEndTime: setEndTimeAction,
  setFilterTicketStatus: setFilterTicketStatusAction,
  setFirst: setFirstAction,
  setFirstRecordDate: setFirstRecordDateAction,
  setIsGridReady: setIsGridReadyAction,
  setIsShowUtilityDrawer: setIsShowUtilityDrawerAction,
  setLastRecordDate: setLastRecordDateAction,
  setOrderBy: setOrderByAction,
  setPageInfo: setPageInfoAction,
  setPageNumber: setPageNumberAction,
  setPageSize: setPageSizeAction,
  setRecordLoaded: setRecordLoadedAction,
  setRowIndex: setRowIndexAction,
  setStartTime: setStartTimeAction,
} = managementSystemListTicketsSlice.actions;
