import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type AlertModal = {
  isOpen: boolean;
  message: string;
  title: string;
};

type AlertModalInput = {
  message: string;
  title: string;
};

export type NavGroups = {
  bookings: boolean;
  clubOperation: boolean;
  developer: boolean;
  management: boolean;
  members: boolean;
  notifications: boolean;
  org: boolean;
  report: boolean;
  settings: boolean;
};

export interface Breadcrumb {
  label: string;
  path: string;
  states?: Record<string, unknown>;
  tip?: string;
}

interface States {
  alertModal: AlertModal;
  appPath: string;
  breadcrumbs: Breadcrumb[];
  greeting: Greetings;
  isCallingBlockingApi: boolean | 'simple';
  isRefetching: boolean;
  isShowCoachRedirectModal: boolean;
  isShowDrawer: boolean;
  navGroups: NavGroups;
  pageChange: {
    pageName: string;
  };
  reloadOnNextPageChange: boolean;
}

type Greetings = 'Good morning' | 'Good afternoon' | 'Good evening' | null;

const initialState: States = {
  alertModal: {
    isOpen: false,
    message: '',
    title: '',
  },
  appPath: '',
  breadcrumbs: [],
  greeting: null,
  isCallingBlockingApi: false,
  isRefetching: false,
  isShowCoachRedirectModal: false,
  isShowDrawer: false,
  navGroups: {
    bookings: true,
    clubOperation: true,
    developer: true,
    management: true,
    members: true,
    notifications: true,
    org: true,
    report: true,
    settings: true,
  },
  pageChange: {
    pageName: '',
  },
  reloadOnNextPageChange: false,
};

export const uiSlice = createSlice({
  initialState,
  name: 'ui',
  reducers: {
    closeAlertModal: (state) => {
      state.alertModal = { ...initialState.alertModal };
    },
    logout: (state) => {
      state.isCallingBlockingApi = false;
      state.alertModal = { ...initialState.alertModal };
    },
    openAlertModal: (state, { payload }: PayloadAction<AlertModalInput>) => {
      state.alertModal = {
        isOpen: true,
        message: payload.message,
        title: payload.title,
      };
    },
    replaceBreadcrumbs: (state, { payload }: PayloadAction<Breadcrumb[]>) => {
      state.breadcrumbs = payload;

      localStorage.setItem('breadcrumbs', JSON.stringify(payload));
    },
    setAppPath: (state, { payload }: PayloadAction<string>) => {
      state.appPath = payload;
    },
    setBreadcrumbs: (state, { payload }: PayloadAction<Breadcrumb>) => {
      const breadcrumbs = state.breadcrumbs;
      const index = breadcrumbs.findIndex((b) => b.label === payload.label);

      if (index > -1 && index !== breadcrumbs.length - 1) {
        const truncated = breadcrumbs.slice(0, index + 1);
        state.breadcrumbs = truncated;

        localStorage.setItem('breadcrumbs', JSON.stringify(truncated));
      }

      if (index < 0) {
        const merged = [...breadcrumbs, payload];
        state.breadcrumbs = merged;

        localStorage.setItem('breadcrumbs', JSON.stringify(merged));
      }
    },
    setGreeting: (state, { payload }: PayloadAction<Greetings>) => {
      state.greeting = payload;
    },
    setIsCallingBlockingApi: (
      state,
      { payload }: PayloadAction<boolean | 'simple'>,
    ) => {
      state.isCallingBlockingApi = payload;
    },
    setIsRefetching: (state, { payload }: PayloadAction<boolean>) => {
      state.isRefetching = payload;
    },
    setIsShowCoachRedirectModal: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isShowCoachRedirectModal = payload;
    },
    setIsShowDrawer: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowDrawer = payload;
    },
    setNavGroups: (state, { payload }: PayloadAction<NavGroups>) => {
      state.navGroups = payload;
    },
    setReloadOnNextPageChange: (state, { payload }: PayloadAction<boolean>) => {
      state.reloadOnNextPageChange = payload;
    },
    toggleDrawer: (state) => {
      state.isShowDrawer = !state.isShowDrawer;
    },
    toggleNavGroup: (state, { payload }: PayloadAction<keyof NavGroups>) => {
      const key = payload;
      state.navGroups[key] = !state.navGroups[key];

      localStorage.setItem(
        'uiConfig',
        JSON.stringify({ navGroups: state.navGroups }),
      );
    },
  },
});

export default uiSlice.reducer;

export const {
  closeAlertModal: closeAlertModalAction,
  logout: logoutAction,
  openAlertModal: openAlertModalAction,
  replaceBreadcrumbs: replaceBreadcrumbsAction,
  setAppPath: setAppPathAction,
  setBreadcrumbs: setBreadcrumbsAction,
  setGreeting: setGreetingAction,
  setIsCallingBlockingApi: setIsCallingBlockingApiAction,
  setIsRefetching: setIsRefetchingAction,
  setIsShowCoachRedirectModal: setIsShowCoachRedirectModalAction,
  setIsShowDrawer: setIsShowDrawerAction,
  setNavGroups: setNavGroupsAction,
  setReloadOnNextPageChange: setReloadOnNextPageChangeAction,
  toggleDrawer: toggleDrawerAction,
  toggleNavGroup: toggleNavGroupAction,
} = uiSlice.actions;
