import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';
import { useRef } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store';
import { closeAlertModalAction } from 'store/ui';

interface AlertModalProps {
  alertModal: RootState['ui']['alertModal'];
  closeAlertModal: typeof closeAlertModalAction;
}

const Component = (props: AlertModalProps) => {
  const { alertModal, closeAlertModal } = props;
  const { isOpen, message, title } = alertModal;
  const cancelRef = useRef(null);

  const handleCloseAlertModal = () => {
    closeAlertModal();
  };

  return (
    <AlertDialog
      isCentered
      preserveScrollBarGap
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      variant="primary"
      onClose={handleCloseAlertModal}
    >
      <AlertDialogOverlay backdropFilter="blur(5px)" bg="blackAlpha.800" />
      <AlertDialogContent>
        <AlertDialogHeader
          bg="blue.50"
          borderBottomColor="blue.100"
          borderBottomWidth={1}
          borderTopRadius="5px"
          fontSize={16}
          fontWeight={600}
        >
          {title}
        </AlertDialogHeader>
        <AlertDialogCloseButton
          _hover={{
            bgColor: 'gray.200',
          }}
          bg="#ffffff"
          borderRadius="50%"
        />
        <AlertDialogBody pb={4} pt={6}>
          <span className="leading-7">{message}</span>
        </AlertDialogBody>

        <AlertDialogFooter>
          <Button
            ref={cancelRef}
            colorScheme="secondary-action"
            variant="solid"
            onClick={handleCloseAlertModal}
          >
            Close
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

const states = (state: RootState) => ({
  alertModal: state.ui.alertModal,
});

const dispatch = {
  closeAlertModal: closeAlertModalAction,
};

export const GlobalAlertModal = connect(states, dispatch)(Component);
