import {
  AdminMemberProductGroupPackage,
  AdminMemberProductPackageExtension,
  AdminMemberProductPackageSubService,
  ServiceTransactionBasic,
} from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DrawerTempLinkButtonValue } from 'components/_basic/DrawerComponents';

export type SelectedMember = {
  memberIndividualId: string;
  memberName: string;
  memberUuid: string;
  membershipUuid: string;
};

export type SelectedGroupPackage = {
  memberIndividualId: string;
  memberName: string;
  packageName: string;
  sourceServiceId: string;
  targetServiceId: string;
};

export interface States {
  extensionDetails: AdminMemberProductPackageExtension | null;
  extensionsPageNumber: number;
  extensionsPageSize: number;
  extensionsRowIndex: number;

  groupPageNumber: number;
  groupPageSize: number;
  groupRowIndex: number;
  groupService: AdminMemberProductGroupPackage | null;

  groupServiceId: string | null;
  isDaily: boolean;
  isGridReady: boolean;
  isGroup: boolean;
  isOpenGroupPackageModal: boolean;
  isOpenReactivateConfirmModal: boolean;
  isOpenSelectGroupPackageModal: boolean;
  isOpenSelectMemberModal: boolean;
  isOpenSharePackageModal: boolean;
  isOpenSuccessReactivateModal: boolean;
  isOpenTransferModal: boolean;

  isOpenUnlinkModal: boolean;

  membershipUuid: string;
  parentServiceId: string | null;
  selectedGroupPackage: SelectedGroupPackage | null;
  selectedMember: SelectedMember | null;
  selectedPackage: DrawerTempLinkButtonValue;

  serviceId: string | null;
  sharingPageNumber: number;
  sharingPageSize: number;

  sharingRowIndex: number;
  subService: AdminMemberProductPackageSubService | null;
  tabIndex: number;

  transactionDetails: ServiceTransactionBasic | null;
  transactionsPageNumber: number;
  transactionsPageSize: number;
  transactionsRowIndex: number;
}

const initialState: States = {
  extensionDetails: null,
  extensionsPageNumber: 0,
  extensionsPageSize: 100,
  extensionsRowIndex: -1,

  groupPageNumber: 0,
  groupPageSize: 100,
  groupRowIndex: -1,
  groupService: null,
  groupServiceId: null,

  isDaily: false,
  isGridReady: false,
  isGroup: false,
  isOpenGroupPackageModal: false,
  isOpenReactivateConfirmModal: false,
  isOpenSelectGroupPackageModal: false,
  isOpenSelectMemberModal: false,
  isOpenSharePackageModal: false,
  isOpenSuccessReactivateModal: false,
  isOpenTransferModal: false,
  isOpenUnlinkModal: false,

  membershipUuid: '',
  parentServiceId: null,
  selectedGroupPackage: null,
  selectedMember: null,
  selectedPackage: {
    id: '',
    label: '',
    meta: '',
  },
  serviceId: null,

  sharingPageNumber: 0,
  sharingPageSize: 100,
  sharingRowIndex: -1,

  subService: null,
  tabIndex: 0,

  transactionDetails: null,
  transactionsPageNumber: 0,
  transactionsPageSize: 100,
  transactionsRowIndex: -1,
};

export const membersEditPackageSlice = createSlice({
  initialState,
  name: 'membersEditPackage',
  reducers: {
    setExtensionDetails: (
      state,
      { payload }: PayloadAction<AdminMemberProductPackageExtension | null>,
    ) => {
      state.extensionDetails = payload;
    },
    setExtensionsPageNumber: (state, { payload }: PayloadAction<number>) => {
      state.extensionsPageNumber = payload;
    },
    setExtensionsPageSize: (state, { payload }: PayloadAction<number>) => {
      state.extensionsPageSize = payload;
    },

    setExtensionsRowIndex: (state, { payload }: PayloadAction<number>) => {
      state.extensionsRowIndex = payload;
    },
    setGroupPageNumber: (state, { payload }: PayloadAction<number>) => {
      state.groupPageNumber = payload;
    },
    setGroupPageSize: (state, { payload }: PayloadAction<number>) => {
      state.groupPageSize = payload;
    },
    setGroupRowIndex: (state, { payload }: PayloadAction<number>) => {
      state.groupRowIndex = payload;
    },
    setGroupService: (
      state,
      { payload }: PayloadAction<AdminMemberProductGroupPackage | null>,
    ) => {
      state.groupService = payload;
    },
    setGroupServiceId: (state, { payload }: PayloadAction<string | null>) => {
      state.groupServiceId = payload;
    },
    setIsDaily: (state, { payload }: PayloadAction<boolean>) => {
      state.isDaily = payload;
    },
    setIsGridReady: (state, { payload }: PayloadAction<boolean>) => {
      state.isGridReady = payload;
    },
    setIsGroup: (state, { payload }: PayloadAction<boolean>) => {
      state.isGroup = payload;
    },
    setIsOpenGroupPackageModal: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isOpenGroupPackageModal = payload;
    },
    setIsOpenReactivateConfirmModal: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isOpenReactivateConfirmModal = payload;
    },
    setIsOpenSelectGroupPackageModal: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isOpenSelectGroupPackageModal = payload;
    },
    setIsOpenSelectMemberModal: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isOpenSelectMemberModal = payload;
    },

    setIsOpenSharePackageModal: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isOpenSharePackageModal = payload;
    },

    setIsOpenSuccessReactivateModal: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isOpenSuccessReactivateModal = payload;
    },
    setIsOpenTransferModal: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpenTransferModal = payload;
    },
    setIsOpenUnlinkModal: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpenUnlinkModal = payload;
    },
    setMembershipUuid: (state, { payload }: PayloadAction<string>) => {
      state.membershipUuid = payload;
    },
    setParentServiceId: (state, { payload }: PayloadAction<string | null>) => {
      state.parentServiceId = payload;
    },
    setSelectedGroupPackage: (
      state,
      { payload }: PayloadAction<SelectedGroupPackage>,
    ) => {
      state.selectedGroupPackage = payload;
    },

    setSelectedMember: (
      state,
      { payload }: PayloadAction<SelectedMember | null>,
    ) => {
      state.selectedMember = payload;
    },

    setSelectedPackage: (
      state,
      { payload }: PayloadAction<DrawerTempLinkButtonValue>,
    ) => {
      state.selectedPackage = payload;
    },
    setServiceId: (state, { payload }: PayloadAction<string | null>) => {
      state.serviceId = payload;
    },
    setSharingPageNumber: (state, { payload }: PayloadAction<number>) => {
      state.sharingPageNumber = payload;
    },

    setSharingPageSize: (state, { payload }: PayloadAction<number>) => {
      state.sharingPageSize = payload;
    },

    setSharingRowIndex: (state, { payload }: PayloadAction<number>) => {
      state.sharingRowIndex = payload;
    },
    setSubService: (
      state,
      { payload }: PayloadAction<AdminMemberProductPackageSubService>,
    ) => {
      state.subService = payload;
    },
    setTabIndex: (state, { payload }: PayloadAction<number>) => {
      state.tabIndex = payload;
    },
    setTransactionDetails: (
      state,
      { payload }: PayloadAction<ServiceTransactionBasic | null>,
    ) => {
      state.transactionDetails = payload;
    },
    setTransactionsPageNumber: (state, { payload }: PayloadAction<number>) => {
      state.transactionsPageNumber = payload;
    },
    setTransactionsPageSize: (state, { payload }: PayloadAction<number>) => {
      state.transactionsPageSize = payload;
    },
    setTransactionsRowIndex: (state, { payload }: PayloadAction<number>) => {
      state.transactionsRowIndex = payload;
    },
  },
});

export default membersEditPackageSlice.reducer;

export const {
  setExtensionDetails: setExtensionDetailsAction,
  setExtensionsPageNumber: setExtensionsPageNumberAction,
  setExtensionsPageSize: setExtensionsPageSizeAction,
  setExtensionsRowIndex: setExtensionsRowIndexAction,
  setGroupPageNumber: setGroupPageNumberAction,
  setGroupPageSize: setGroupPageSizeAction,
  setGroupRowIndex: setGroupRowIndexAction,
  setGroupService: setGroupServiceAction,
  setGroupServiceId: setGroupServiceIdAction,
  setIsDaily: setIsDailyAction,
  setIsGridReady: setIsGridReadyAction,
  setIsGroup: setIsGroupAction,
  setIsOpenGroupPackageModal: setIsOpenGroupPackageModalAction,
  setIsOpenReactivateConfirmModal: setIsOpenReactivateConfirmModalAction,
  setIsOpenSelectGroupPackageModal: setIsOpenSelectGroupPackageModalAction,
  setIsOpenSelectMemberModal: setIsOpenSelectMemberModalAction,
  setIsOpenSharePackageModal: setIsOpenSharePackageModalAction,
  setIsOpenSuccessReactivateModal: setIsOpenSuccessReactivateModalAction,
  setIsOpenTransferModal: setIsOpenTransferModalAction,
  setIsOpenUnlinkModal: setIsOpenUnlinkModalAction,
  setMembershipUuid: setMembershipUuidAction,
  setParentServiceId: setParentServiceIdAction,
  setSelectedGroupPackage: setSelectedGroupPackageAction,
  setSelectedMember: setSelectedMemberAction,
  setSelectedPackage: setSelectedPackageAction,
  setServiceId: setServiceIdAction,
  setSharingPageNumber: setSharingPageNumberAction,
  setSharingPageSize: setSharingPageSizeAction,
  setSharingRowIndex: setSharingRowIndexAction,
  setSubService: setSubServiceAction,
  setTabIndex: setTabIndexAction,
  setTransactionDetails: setTransactionDetailsAction,
  setTransactionsPageNumber: setTransactionsPageNumberAction,
  setTransactionsPageSize: setTransactionsPageSizeAction,
  setTransactionsRowIndex: setTransactionsRowIndexAction,
} = membersEditPackageSlice.actions;
