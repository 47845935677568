import { CountryCode, Direction, OrderBy, PageInfo } from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface States {
  filterCountry: CountryCode | string;
  filterStaffName: string;
  isGridReady: boolean;
  listClubhouseStaffEndCursor: string;
  listClubhouseStaffFirst: number | null;
  listClubhouseStaffOrderBy: OrderBy;
  listClubhouseStaffPageNumber: number;
  listClubhouseStaffPageSize: number;
  listClubhouseStaffRowIndex: number;
  pageInfo: PageInfo | null | undefined;
  recordLoaded: number;
}

const initialState: States = {
  filterCountry: '',
  filterStaffName: '',
  isGridReady: false,
  listClubhouseStaffEndCursor: '',
  listClubhouseStaffFirst: 1000,
  listClubhouseStaffOrderBy: {
    direction: Direction.Desc,
    field: 'lastAccessTokenDate',
  },
  listClubhouseStaffPageNumber: 0,
  listClubhouseStaffPageSize: 25,
  listClubhouseStaffRowIndex: -1,
  pageInfo: null,
  recordLoaded: 0,
};

export const clubhouseStaffListClubhouseStaffSlice = createSlice({
  initialState,
  name: 'clubhouseStaffListClubhouseStaff',
  reducers: {
    setFilterCountry: (
      state,
      { payload }: PayloadAction<CountryCode | string>,
    ) => {
      state.filterCountry = payload;
    },
    setFilterStaffName: (state, { payload }: PayloadAction<string>) => {
      state.filterStaffName = payload;
    },
    setIsGridReady: (state, { payload }: PayloadAction<boolean>) => {
      state.isGridReady = payload;
    },
    setListClubhouseStaffEndCursor: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listClubhouseStaffEndCursor = payload;
    },
    setListClubhouseStaffFirst: (
      state,
      { payload }: PayloadAction<number | null>,
    ) => {
      state.listClubhouseStaffFirst = payload;
    },
    setListClubhouseStaffOrderBy: (
      state,
      { payload }: PayloadAction<OrderBy>,
    ) => {
      state.listClubhouseStaffOrderBy = payload;
    },
    setListClubhouseStaffPageNumber: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listClubhouseStaffPageNumber = payload;
    },
    setListClubhouseStaffPageSize: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listClubhouseStaffPageSize = payload;
    },
    setListClubhouseStaffRowIndex: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listClubhouseStaffRowIndex = payload;
    },
    setPageInfo: (state, { payload }: PayloadAction<PageInfo>) => {
      state.pageInfo = payload;
    },
    setRecordLoaded: (state, { payload }: PayloadAction<number>) => {
      state.recordLoaded = payload;
    },
  },
});

export default clubhouseStaffListClubhouseStaffSlice.reducer;

export const {
  setFilterCountry: setFilterCountryAction,
  setFilterStaffName: setFilterStaffNameAction,
  setIsGridReady: setIsGridReadyAction,
  setListClubhouseStaffEndCursor: setListClubhouseStaffEndCursorAction,
  setListClubhouseStaffFirst: setListClubhouseStaffFirstAction,
  setListClubhouseStaffOrderBy: setListClubhouseStaffOrderByAction,
  setListClubhouseStaffPageNumber: setListClubhouseStaffPageNumberAction,
  setListClubhouseStaffPageSize: setListClubhouseStaffPageSizeAction,
  setListClubhouseStaffRowIndex: setListClubhouseStaffRowIndexAction,
  setPageInfo: setPageInfoAction,
  setRecordLoaded: setRecordLoadedAction,
} = clubhouseStaffListClubhouseStaffSlice.actions;
