import {
  AdminCoachCombinedAvailability,
  AdminGroupBooking,
  CoachClubhouse,
  SlotItem,
} from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface BookingQuickLook {
  bookings: AdminGroupBooking[];
  isShowModal: boolean;
}

export type FacilityMode =
  | 'hour_1'
  | 'hour_2'
  | 'hour_3'
  | 'hour_4'
  | 'hour_5'
  | 'putting'
  | 'putting_30'
  | 'putting_60'
  | 'trial';

export interface AvailabiltyState {
  bi09: string;
  bi10: string;
  bi11: string;
  bi12: string;
  bi13: string;
  bi14: string;
  bi15: string;
  bi16: string;
  bi17: string;
  bi18: string;
  bi19: string;
  bi20: string;
  bs09: string;
  bs10: string;
  bs11: string;
  bs12: string;
  bs13: string;
  bs14: string;
  bs15: string;
  bs16: string;
  bs17: string;
  bs18: string;
  bs19: string;
  bs20: string;
  date: string;
  displayName: string;
  l09: string;
  l10: string;
  l11: string;
  l12: string;
  l13: string;
  l14: string;
  l15: string;
  l16: string;
  l17: string;
  l18: string;
  l19: string;
  l20: string;
}

interface SetBookingPayload {
  bookingDescription: string;
  bookingId: string;
  time: string;
}

interface SetClubhousePayload {
  clubhouseCode: string;
  time: string;
}

interface SetOutdoorBookingPayload {
  biOutdoor: string;
  hasOutdoorBooking: boolean;
}

export interface States extends AvailabiltyState {
  biOutdoor: string;
  bookingId: string;
  bookingQuickLook: BookingQuickLook | null;
  clubhouses: CoachClubhouse[];
  combinedAvailability: AdminCoachCombinedAvailability[];
  currentDate: string | null;
  facilityCode: string | null;
  facilityMode: FacilityMode;
  hasOutdoorBooking: boolean;
  hasStudios: boolean;
  hr1: SlotItem | null;
  hr2: SlotItem | null;
  hr3: SlotItem | null;
  hr4: SlotItem | null;
  hr5: SlotItem | null;
  isShowCoachModal: boolean;
  isShowModalFacilitySelector: boolean;
  resourceName: string;
}

const initialState: States = {
  bi09: '',
  bi10: '',
  bi11: '',
  bi12: '',
  bi13: '',
  bi14: '',
  bi15: '',
  bi16: '',
  bi17: '',
  bi18: '',
  bi19: '',
  bi20: '',
  biOutdoor: '',
  bookingId: '',
  bookingQuickLook: null,
  bs09: '',
  bs10: '',
  bs11: '',
  bs12: '',
  bs13: '',
  bs14: '',
  bs15: '',
  bs16: '',
  bs17: '',
  bs18: '',
  bs19: '',
  bs20: '',
  clubhouses: [],
  combinedAvailability: [],
  currentDate: null,
  date: '',
  displayName: '',
  facilityCode: null,
  facilityMode: 'hour_1',
  hasOutdoorBooking: false,
  hasStudios: false,
  hr1: null,
  hr2: null,
  hr3: null,
  hr4: null,
  hr5: null,
  isShowCoachModal: false,
  isShowModalFacilitySelector: false,
  l09: '',
  l10: '',
  l11: '',
  l12: '',
  l13: '',
  l14: '',
  l15: '',
  l16: '',
  l17: '',
  l18: '',
  l19: '',
  l20: '',
  resourceName: '',
};

export const coachCombinedAvailabilitySlice = createSlice({
  initialState,
  name: 'coachCombinedAvailability',
  reducers: {
    clearFacilities: (state) => {
      state.facilityCode = null;
      state.facilityMode = 'hour_1';
      state.hasStudios = false;
      state.hr1 = null;
      state.hr2 = null;
      state.hr3 = null;
      state.hr4 = null;
      state.hr5 = null;
    },
    setBooking: (state, { payload }: PayloadAction<SetBookingPayload>) => {
      const { bookingDescription, bookingId } = payload;
      const dAccessor = `bd${payload.time}` as keyof AvailabiltyState;
      state[dAccessor] = bookingDescription;
      const iAccessor = `bi${payload.time}` as keyof AvailabiltyState;
      state[iAccessor] = bookingId;
    },
    setBookingId: (state, { payload }: PayloadAction<string>) => {
      state.bookingId = payload;
    },
    setBookingQuickLook: (
      state,
      { payload }: PayloadAction<BookingQuickLook | null>,
    ) => {
      state.bookingQuickLook = payload;
    },
    setClubhouse: (state, { payload }: PayloadAction<SetClubhousePayload>) => {
      const { clubhouseCode, time } = payload;
      const accessor = `l${time}` as keyof AvailabiltyState;
      state[accessor] = clubhouseCode;
    },
    setClubhouses: (state, { payload }: PayloadAction<CoachClubhouse[]>) => {
      state.clubhouses = payload;
    },
    setCombinedAvailability: (
      state,
      { payload }: PayloadAction<AdminCoachCombinedAvailability[]>,
    ) => {
      state.combinedAvailability = payload;
    },
    setCurrentDate: (state, { payload }: PayloadAction<string>) => {
      state.currentDate = payload;
    },
    setDate: (state, { payload }: PayloadAction<string>) => {
      state.date = payload;
    },
    setDisplayName: (state, { payload }: PayloadAction<string>) => {
      state.displayName = payload;
    },
    setFacilityCode: (state, { payload }: PayloadAction<string | null>) => {
      state.facilityCode = payload;
    },
    setFacilityMode: (state, { payload }: PayloadAction<FacilityMode>) => {
      state.facilityMode = payload;
    },
    setHasStudios: (state, { payload }: PayloadAction<boolean>) => {
      state.hasStudios = payload;
    },
    setHr1: (state, { payload }: PayloadAction<SlotItem | null>) => {
      state.hr1 = payload;
    },
    setHr2: (state, { payload }: PayloadAction<SlotItem | null>) => {
      state.hr2 = payload;
    },
    setHr3: (state, { payload }: PayloadAction<SlotItem | null>) => {
      state.hr3 = payload;
    },
    setHr4: (state, { payload }: PayloadAction<SlotItem | null>) => {
      state.hr4 = payload;
    },
    setHr5: (state, { payload }: PayloadAction<SlotItem | null>) => {
      state.hr5 = payload;
    },
    setIsShowCoachModal: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowCoachModal = payload;
    },
    setIsShowModalFacilitySelector: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isShowModalFacilitySelector = payload;
    },
    setOutdoorBooking: (
      state,
      { payload }: PayloadAction<SetOutdoorBookingPayload>,
    ) => {
      const { biOutdoor, hasOutdoorBooking } = payload;
      state.hasOutdoorBooking = hasOutdoorBooking;
      state.biOutdoor = biOutdoor;
    },
    setResourceName: (state, { payload }: PayloadAction<string>) => {
      state.resourceName = payload;
    },
  },
});

export default coachCombinedAvailabilitySlice.reducer;

export const {
  clearFacilities: clearFacilitiesAction,
  setBooking: setBookingAction,
  setBookingId: setBookingIdAction,
  setBookingQuickLook: setBookingQuickLookAction,
  setClubhouse: setClubhouseAction,
  setClubhouses: setClubhousesAction,
  setCombinedAvailability: setCombinedAvailabilityAction,
  setCurrentDate: setCurrentDateAction,
  setDate: setDateAction,
  setDisplayName: setDisplayNameAction,
  setFacilityCode: setFacilityCodeAction,
  setFacilityMode: setFacilityModeAction,
  setHasStudios: setHasStudiosAction,
  setHr1: setHr1Action,
  setHr2: setHr2Action,
  setHr3: setHr3Action,
  setHr4: setHr4Action,
  setHr5: setHr5Action,
  setIsShowCoachModal: setIsShowCoachModalAction,
  setIsShowModalFacilitySelector: setIsShowModalFacilitySelectorAction,
  setOutdoorBooking: setOutdoorBookingAction,
  setResourceName: setResourceNameAction,
} = coachCombinedAvailabilitySlice.actions;
