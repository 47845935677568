import { AdminManagementSystemSupportTicketUser } from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface States {
  isShowSettingsModal: boolean;
  users: AdminManagementSystemSupportTicketUser[];
}

export const initialState: States = {
  isShowSettingsModal: false,
  users: [],
};

export const managementSystemEditTicketSlice = createSlice({
  initialState,
  name: 'managementSystemEditTicket',
  reducers: {
    setIsShowSettingsModal: (state, action: PayloadAction<boolean>) => {
      state.isShowSettingsModal = action.payload;
    },
    setUsers: (
      state,
      action: PayloadAction<AdminManagementSystemSupportTicketUser[]>,
    ) => {
      state.users = action.payload;
    },
  },
});
export const {
  setIsShowSettingsModal: setIsShowSettingsModalAction,
  setUsers: setUsersAction,
} = managementSystemEditTicketSlice.actions;
