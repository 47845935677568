import {
  AdminProductBasic,
  AdminProductEdge,
  CountryCode,
  PageInfo,
} from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SelectedMember = {
  memberIndividualId: string;
  memberName: string;
  memberUuid: string;
  membershipUuid: string;
};

type StatusHandlerMember = {
  memberName: string;
  memberUuid: string;
};

export interface States {
  filterCountry: CountryCode | string;
  isConfirmModalOpen: boolean;
  isGridReady: boolean;
  isSuccessModalOpen: boolean;
  packages: AdminProductEdge[];
  pageInfo: PageInfo | null;
  product: AdminProductBasic | null;
  recordLoaded: number;
  selectedMember: SelectedMember | null;
  statusHandlerMember: StatusHandlerMember | null;
  tabIndex: number;
}

const initialState: States = {
  filterCountry: '',
  isConfirmModalOpen: false,
  isGridReady: false,
  isSuccessModalOpen: false,
  packages: [],
  pageInfo: null,
  product: null,
  recordLoaded: 0,
  selectedMember: null,
  statusHandlerMember: null,
  tabIndex: 0,
};

export const productsAddPackageSlice = createSlice({
  initialState,
  name: 'productsAddPackage',
  reducers: {
    setFilterCountry: (
      state,
      { payload }: PayloadAction<CountryCode | string>,
    ) => {
      state.filterCountry = payload;
    },
    setIsConfirmModalOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isConfirmModalOpen = payload;
    },
    setIsGridReady: (state, { payload }: PayloadAction<boolean>) => {
      state.isGridReady = payload;
    },
    setIsSuccessModalOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isSuccessModalOpen = payload;
    },
    setPackages: (state, { payload }: PayloadAction<AdminProductEdge[]>) => {
      state.packages = payload;
    },
    setPageInfo: (state, { payload }: PayloadAction<PageInfo>) => {
      state.pageInfo = payload;
    },
    setProduct: (
      state,
      { payload }: PayloadAction<AdminProductBasic | null>,
    ) => {
      state.product = payload;
    },
    setRecordLoaded: (state, { payload }: PayloadAction<number>) => {
      state.recordLoaded = payload;
    },
    setSelectedMember: (
      state,
      { payload }: PayloadAction<SelectedMember | null>,
    ) => {
      state.selectedMember = payload;
    },
    setStatusHandlerMember: (
      state,
      { payload }: PayloadAction<StatusHandlerMember | null>,
    ) => {
      state.statusHandlerMember = payload;
    },
    setTabIndex: (state, { payload }: PayloadAction<number>) => {
      state.tabIndex = payload;
    },
  },
});

export default productsAddPackageSlice.reducer;

export const {
  setFilterCountry: setFilterCountryAction,
  setIsConfirmModalOpen: setIsConfirmModalOpenAction,
  setIsGridReady: setIsGridReadyAction,
  setIsSuccessModalOpen: setIsSuccessModalOpenAction,
  setPackages: setPackagesAction,
  setPageInfo: setPageInfoAction,
  setProduct: setProductAction,
  setRecordLoaded: setRecordLoadedAction,
  setSelectedMember: setSelectedMemberAction,
  setStatusHandlerMember: setStatusHandlerMemberAction,
  setTabIndex: setTabIndexAction,
} = productsAddPackageSlice.actions;
