import {
  AccountTypeFilter,
  CountryCode,
  Direction,
  MemberStatus,
  OrderBy,
  PageInfo,
} from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface States {
  filterAccountType: AccountTypeFilter | string;
  filterCountry: CountryCode | string;
  filterMemberName: string;
  filterMemberStatus: MemberStatus | string;
  filterMemberTier: string;
  isGridReady: boolean;
  isShowUtilityDrawer: boolean;
  listMembersEndCursor: string;
  listMembersFirst: number | null;
  listMembersOrderBy: OrderBy;
  listMembersPageNumber: number;
  listMembersPageSize: number;
  listMembersRowIndex: number;
  pageInfo: PageInfo | null | undefined;
  recordLoaded: number;
}

const initialState: States = {
  filterAccountType: AccountTypeFilter.Member,
  filterCountry: '',
  filterMemberName: '',
  filterMemberStatus: '',
  filterMemberTier: '',
  isGridReady: false,
  isShowUtilityDrawer: false,
  listMembersEndCursor: '',
  listMembersFirst: 1000,
  listMembersOrderBy: {
    direction: Direction.Desc,
    field: 'createdAt',
  },
  listMembersPageNumber: 0,
  listMembersPageSize: 100,
  listMembersRowIndex: -1,
  pageInfo: null,
  recordLoaded: 0,
};

export const membersListMembersSlice = createSlice({
  initialState,
  name: 'membersLisMembers',
  reducers: {
    setFilterAccountType: (
      state,
      { payload }: PayloadAction<AccountTypeFilter | string>,
    ) => {
      state.filterAccountType = payload;
    },
    setFilterCountry: (
      state,
      { payload }: PayloadAction<CountryCode | string>,
    ) => {
      state.filterCountry = payload;
    },
    setFilterMemberName: (state, { payload }: PayloadAction<string>) => {
      state.filterMemberName = payload;
    },
    setFilterMemberStatus: (
      state,
      { payload }: PayloadAction<MemberStatus | string>,
    ) => {
      state.filterMemberStatus = payload;
    },
    setFilterMemberTier: (state, { payload }: PayloadAction<string>) => {
      state.filterMemberTier = payload;
    },
    setIsGridReady: (state, { payload }: PayloadAction<boolean>) => {
      state.isGridReady = payload;
    },
    setIsShowUtilityDrawer: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowUtilityDrawer = payload;
    },
    setListMembersEndCursor: (state, { payload }: PayloadAction<string>) => {
      state.listMembersEndCursor = payload;
    },
    setListMembersFirst: (state, { payload }: PayloadAction<number | null>) => {
      state.listMembersFirst = payload;
    },
    setListMembersOrderBy: (state, { payload }: PayloadAction<OrderBy>) => {
      state.listMembersOrderBy = payload;
    },
    setListMembersPageNumber: (state, { payload }: PayloadAction<number>) => {
      state.listMembersPageNumber = payload;
    },
    setListMembersPageSize: (state, { payload }: PayloadAction<number>) => {
      state.listMembersPageSize = payload;
    },
    setListMembersRowIndex: (state, { payload }: PayloadAction<number>) => {
      state.listMembersRowIndex = payload;
    },
    setPageInfo: (state, { payload }: PayloadAction<PageInfo>) => {
      state.pageInfo = payload;
    },
    setRecordLoaded: (state, { payload }: PayloadAction<number>) => {
      state.recordLoaded = payload;
    },
  },
});

export default membersListMembersSlice.reducer;

export const {
  setFilterAccountType: setFilterAccountTypeAction,
  setFilterCountry: setFilterCountryAction,
  setFilterMemberName: setFilterMemberNameAction,
  setFilterMemberStatus: setFilterMemberStatusAction,
  setFilterMemberTier: setFilterMemberTierAction,
  setIsGridReady: setIsGridReadyAction,
  setIsShowUtilityDrawer: setIsShowUtilityDrawerAction,
  setListMembersEndCursor: setListMembersEndCursorAction,
  setListMembersFirst: setListMembersFirstAction,
  setListMembersOrderBy: setListMembersOrderByAction,
  setListMembersPageNumber: setListMembersPageNumberAction,
  setListMembersPageSize: setListMembersPageSizeAction,
  setListMembersRowIndex: setListMembersRowIndexAction,
  setPageInfo: setPageInfoAction,
  setRecordLoaded: setRecordLoadedAction,
} = membersListMembersSlice.actions;
