import { CountryCode } from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateRangeMode } from 'components/DateFilter';

interface States {
  acTxnsDateRangeMode: DateRangeMode;
  acTxnsEndTime: string | null;
  acTxnsStartTime: string | null;
  bookingsEndTime: string | null;
  bookingsStartTime: string | null;
  coachCommissionCoachId: string;
  coachCommissionCoachName: string;
  coachCommissionEndTime: string | null;
  coachCommissionStartTime: string | null;
  memberBDCreditsEndTime: string | null;
  memberMembershipTierChangesEndTime: string | null;
  memberMembershipTierChangesStartTime: string | null;
  memberProfileCountryCode: CountryCode | string;
  memberServicesEndTime: string | null;
  memberServicesStartTime: string | null;
  memberSubscriptionsEndTime: string | null;
  memberSubscriptionsStartTime: string | null;
  saleInvoicesEndTime: string | null;
  saleInvoicesStartTime: string | null;
  saleLineItemsEndTime: string | null;
  saleLineItemsStartTime: string | null;
  saleMembershipsDateRangeMode: DateRangeMode;
  saleMembershipsEndTime: string | null;
  saleMembershipsStartTime: string | null;
  serviceTxnsEndTime: string | null;
  serviceTxnsStartTime: string | null;
  // soaEndTime: string | null;
  // soaStartTime: string | null;
  tabIndex: number;
}

const initialState: States = {
  acTxnsDateRangeMode: 'today',
  acTxnsEndTime: null,
  acTxnsStartTime: null,
  bookingsEndTime: null,
  bookingsStartTime: null,
  coachCommissionCoachId: '',
  coachCommissionCoachName: '',
  coachCommissionEndTime: null,
  coachCommissionStartTime: null,
  memberBDCreditsEndTime: null,
  memberMembershipTierChangesEndTime: null,
  memberMembershipTierChangesStartTime: null,
  memberProfileCountryCode: '',
  memberServicesEndTime: null,
  memberServicesStartTime: null,
  memberSubscriptionsEndTime: null,
  memberSubscriptionsStartTime: null,
  saleInvoicesEndTime: null,
  saleInvoicesStartTime: null,
  saleLineItemsEndTime: null,
  saleLineItemsStartTime: null,
  saleMembershipsDateRangeMode: 'today',
  saleMembershipsEndTime: null,
  saleMembershipsStartTime: null,
  serviceTxnsEndTime: null,
  serviceTxnsStartTime: null,
  // soaEndTime: null,
  // soaStartTime: null,
  tabIndex: 0,
};

export const dataExportSlice = createSlice({
  initialState,
  name: 'dataExport',
  reducers: {
    setAcTxnsDateRangeMode: (
      state,
      { payload }: PayloadAction<DateRangeMode>,
    ) => {
      state.acTxnsDateRangeMode = payload;
    },
    setAcTxnsEndTime: (state, { payload }: PayloadAction<string>) => {
      state.acTxnsEndTime = payload;
    },
    setAcTxnsStartTime: (state, { payload }: PayloadAction<string>) => {
      state.acTxnsStartTime = payload;
    },
    setBookingsEndTime: (state, { payload }: PayloadAction<string>) => {
      state.bookingsEndTime = payload;
    },
    setBookingsStartTime: (state, { payload }: PayloadAction<string>) => {
      state.bookingsStartTime = payload;
    },
    setCoachCommissionCoachId: (state, { payload }: PayloadAction<string>) => {
      state.coachCommissionCoachId = payload;
    },
    setCoachCommissionCoachName: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.coachCommissionCoachName = payload;
    },
    setCoachCommissionEndTime: (state, { payload }: PayloadAction<string>) => {
      state.coachCommissionEndTime = payload;
    },
    setCoachCommissionStartTime: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.coachCommissionStartTime = payload;
    },
    setMemberBDCreditsEndTime: (state, { payload }: PayloadAction<string>) => {
      state.memberBDCreditsEndTime = payload;
    },
    setMemberMembershipTierChangesEndTime: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.memberMembershipTierChangesEndTime = payload;
    },
    setMemberMembershipTierChangesStartTime: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.memberMembershipTierChangesStartTime = payload;
    },
    setMemberProfileCountryCode: (
      state,
      { payload }: PayloadAction<CountryCode | string>,
    ) => {
      state.memberProfileCountryCode = payload;
    },
    setMemberServicesEndTime: (state, { payload }: PayloadAction<string>) => {
      state.memberServicesEndTime = payload;
    },
    setMemberServicesStartTime: (state, { payload }: PayloadAction<string>) => {
      state.memberServicesStartTime = payload;
    },
    setMemberSubscriptionsEndTime: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.memberSubscriptionsEndTime = payload;
    },
    setMemberSubscriptionsStartTime: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.memberSubscriptionsStartTime = payload;
    },
    setSaleInvoicesEndTime: (state, { payload }: PayloadAction<string>) => {
      state.saleInvoicesEndTime = payload;
    },
    setSaleInvoicesStartTime: (state, { payload }: PayloadAction<string>) => {
      state.saleInvoicesStartTime = payload;
    },
    setSaleLineItemsEndTime: (state, { payload }: PayloadAction<string>) => {
      state.saleLineItemsEndTime = payload;
    },
    setSaleLineItemsStartTime: (state, { payload }: PayloadAction<string>) => {
      state.saleLineItemsStartTime = payload;
    },
    setSaleMembershipsDateRangeMode: (
      state,
      { payload }: PayloadAction<DateRangeMode>,
    ) => {
      state.saleMembershipsDateRangeMode = payload;
    },
    setSaleMembershipsEndTime: (state, { payload }: PayloadAction<string>) => {
      state.saleMembershipsEndTime = payload;
    },
    setSaleMembershipsStartTime: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.saleMembershipsStartTime = payload;
    },
    setServiceTxnsEndTime: (state, { payload }: PayloadAction<string>) => {
      state.serviceTxnsEndTime = payload;
    },
    setServiceTxnsStartTime: (state, { payload }: PayloadAction<string>) => {
      state.serviceTxnsStartTime = payload;
    },
    // setSoaEndTime: (state, { payload }: PayloadAction<string>) => {
    //   state.soaEndTime = payload;
    // },
    // setSoaStartTime: (
    //   state,
    //   { payload }: PayloadAction<string>,
    // ) => {
    //   state.soaStartTime = payload;
    // },
    setTabIndex: (state, { payload }: PayloadAction<number>) => {
      state.tabIndex = payload;
    },
  },
});

export default dataExportSlice.reducer;

export const {
  setAcTxnsDateRangeMode: setAcTxnsDateRangeModeAction,
  setAcTxnsEndTime: setAcTxnsEndTimeAction,
  setAcTxnsStartTime: setAcTxnsStartTimeAction,
  setBookingsEndTime: setBookingsEndTimeAction,
  setBookingsStartTime: setBookingsStartTimeAction,
  setCoachCommissionCoachId: setCoachCommissionCoachIdAction,
  setCoachCommissionCoachName: setCoachCommissionCoachNameAction,
  setCoachCommissionEndTime: setCoachCommissionEndTimeAction,
  setCoachCommissionStartTime: setCoachCommissionStartTimeAction,
  setMemberBDCreditsEndTime: setMemberBDCreditsEndTimeAction,
  setMemberMembershipTierChangesEndTime:
    setMemberMembershipTierChangesEndTimeAction,
  setMemberMembershipTierChangesStartTime:
    setMemberMembershipTierChangesStartTimeAction,
  setMemberProfileCountryCode: setMemberProfileCountryCodeAction,
  setMemberServicesEndTime: setMemberServicesEndTimeAction,
  setMemberServicesStartTime: setMemberServicesStartTimeAction,
  setMemberSubscriptionsEndTime: setMemberSubscriptionsEndTimeAction,
  setMemberSubscriptionsStartTime: setMemberSubscriptionsStartTimeAction,
  setSaleInvoicesEndTime: setSaleInvoicesEndTimeAction,
  // setSoaEndTime: setSoaEndTimeAction,
  // setSoaStartTime: setSoaStartTimeAction,
  setSaleInvoicesStartTime: setSaleInvoicesStartTimeAction,
  setSaleLineItemsEndTime: setSaleLineItemsEndTimeAction,
  setSaleLineItemsStartTime: setSaleLineItemsStartTimeAction,
  setSaleMembershipsDateRangeMode: setSaleMembershipsDateRangeModeAction,
  setSaleMembershipsEndTime: setSaleMembershipsEndTimeAction,
  setSaleMembershipsStartTime: setSaleMembershipsStartTimeAction,
  setServiceTxnsEndTime: setServiceTxnsEndTimeAction,
  setServiceTxnsStartTime: setServiceTxnsStartTimeAction,
  setTabIndex: setTabIndexAction,
} = dataExportSlice.actions;
