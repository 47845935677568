import {
  AdminGroupBooking,
  BookingStatus,
  BookingType,
  Direction,
  OrderBy,
  PageInfo,
  SlotItem,
} from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateRangeMode } from 'components/DateFilter';

export interface BookingQuickLook {
  bookings: AdminGroupBooking[];
  isShowModal: boolean;
}

export type FacilityMode =
  | 'hour_1'
  | 'hour_2'
  | 'hour_3'
  | 'hour_4'
  | 'hour_5'
  | 'putting'
  | 'putting_30'
  | 'putting_60'
  | 'trial';

export interface States {
  bookingId: string;
  bookingQuickLook: BookingQuickLook | null;
  dateRangeMode: DateRangeMode;
  facilityCode: string | null;
  facilityMode: FacilityMode;
  filterBookingId: string;
  filterBookingStatus: BookingStatus;
  filterBookingType: BookingType | string;
  filterCoach: string;
  filterMemberName: string;
  filterVenue: string;
  firstRecordDate: string | null;
  groupBookingStyles: Record<string, string>;
  hasStudios: boolean;
  hr1: SlotItem | null;
  hr2: SlotItem | null;
  hr3: SlotItem | null;
  hr4: SlotItem | null;
  hr5: SlotItem | null;
  isGridReady: boolean;
  isShowModalFacilitySelector: boolean;
  isShowUtilityDrawer: boolean;
  lastRecordDate: string | null;
  listBookingsActiveEndCursor: string;
  listBookingsActiveEndTime: string | null;
  listBookingsActiveFirst: number | null;
  listBookingsActiveOrderBy: OrderBy;
  listBookingsActivePageNumber: number;
  listBookingsActivePageSize: number;
  listBookingsActiveRowIndex: number;
  listBookingsActiveStartTime: string | null;
  pageInfo: PageInfo | null | undefined;
  recordLoaded: number;
  resourceName: string;
}

const initialState: States = {
  bookingId: '',
  bookingQuickLook: null,
  dateRangeMode: 'today',
  facilityCode: null,
  facilityMode: 'hour_1',
  filterBookingId: '',
  filterBookingStatus: BookingStatus.Active,
  filterBookingType: '',
  filterCoach: '',
  filterMemberName: '',
  filterVenue: '',
  firstRecordDate: null,
  groupBookingStyles: {},
  hasStudios: false,
  hr1: null,
  hr2: null,
  hr3: null,
  hr4: null,
  hr5: null,
  isGridReady: false,
  isShowModalFacilitySelector: false,
  isShowUtilityDrawer: false,
  lastRecordDate: null,
  listBookingsActiveEndCursor: '',
  listBookingsActiveEndTime: null,
  listBookingsActiveFirst: 1000,
  listBookingsActiveOrderBy: {
    direction: Direction.Asc,
    field: 'startTime',
  },
  listBookingsActivePageNumber: 0,
  listBookingsActivePageSize: 100,
  listBookingsActiveRowIndex: -1,
  listBookingsActiveStartTime: null,
  pageInfo: null,
  recordLoaded: 0,
  resourceName: '',
};

export const bookingsListBookingsActiveSlice = createSlice({
  initialState,
  name: 'bookingsListBookingsActive',
  reducers: {
    addGroupBookingStyle: (
      state,
      { payload }: PayloadAction<Record<string, string>>,
    ) => {
      state.groupBookingStyles = {
        ...state.groupBookingStyles,
        ...payload,
      };
    },
    clearFacilities: (state) => {
      state.facilityCode = null;
      state.facilityMode = 'hour_1';
      state.hasStudios = false;
      state.hr1 = null;
      state.hr2 = null;
      state.hr3 = null;
      state.hr4 = null;
      state.hr5 = null;
    },
    clearGroupBookingStyles: (state) => {
      state.groupBookingStyles = {};
    },
    setBookingId: (state, { payload }: PayloadAction<string>) => {
      state.bookingId = payload;
    },
    setBookingQuickLook: (
      state,
      { payload }: PayloadAction<BookingQuickLook | null>,
    ) => {
      state.bookingQuickLook = payload;
    },
    setDateRangeMode: (state, { payload }: PayloadAction<DateRangeMode>) => {
      state.dateRangeMode = payload;
    },
    setFacilityCode: (state, { payload }: PayloadAction<string | null>) => {
      state.facilityCode = payload;
    },
    setFacilityMode: (state, { payload }: PayloadAction<FacilityMode>) => {
      state.facilityMode = payload;
    },
    setFilterBookingId: (state, { payload }: PayloadAction<string>) => {
      state.filterBookingId = payload;
    },
    setFilterBookingStatus: (
      state,
      { payload }: PayloadAction<BookingStatus>,
    ) => {
      state.filterBookingStatus = payload;
    },
    setFilterBookingType: (
      state,
      { payload }: PayloadAction<BookingType | string>,
    ) => {
      state.filterBookingType = payload;
    },
    setFilterCoach: (state, { payload }: PayloadAction<string>) => {
      state.filterCoach = payload;
    },
    setFilterMemberName: (state, { payload }: PayloadAction<string>) => {
      state.filterMemberName = payload;
    },
    setFilterVenue: (state, { payload }: PayloadAction<string>) => {
      state.filterVenue = payload;
    },
    setFirstRecordDate: (state, { payload }: PayloadAction<string>) => {
      state.firstRecordDate = payload;
    },
    setHasStudios: (state, { payload }: PayloadAction<boolean>) => {
      state.hasStudios = payload;
    },
    setHr1: (state, { payload }: PayloadAction<SlotItem | null>) => {
      state.hr1 = payload;
    },
    setHr2: (state, { payload }: PayloadAction<SlotItem | null>) => {
      state.hr2 = payload;
    },
    setHr3: (state, { payload }: PayloadAction<SlotItem | null>) => {
      state.hr3 = payload;
    },
    setHr4: (state, { payload }: PayloadAction<SlotItem | null>) => {
      state.hr4 = payload;
    },
    setHr5: (state, { payload }: PayloadAction<SlotItem | null>) => {
      state.hr5 = payload;
    },
    setIsGridReady: (state, { payload }: PayloadAction<boolean>) => {
      state.isGridReady = payload;
    },
    setIsShowModalFacilitySelector: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isShowModalFacilitySelector = payload;
    },
    setIsShowUtilityDrawer: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowUtilityDrawer = payload;
    },
    setLastRecordDate: (state, { payload }: PayloadAction<string>) => {
      state.lastRecordDate = payload;
    },
    setListBookingsActiveEndCursor: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listBookingsActiveEndCursor = payload;
    },
    setListBookingsActiveEndTime: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listBookingsActiveEndTime = payload;
    },
    setListBookingsActiveFirst: (
      state,
      { payload }: PayloadAction<number | null>,
    ) => {
      state.listBookingsActiveFirst = payload;
    },
    setListBookingsActiveOrderBy: (
      state,
      { payload }: PayloadAction<OrderBy>,
    ) => {
      state.listBookingsActiveOrderBy = payload;
    },
    setListBookingsActivePageNumber: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listBookingsActivePageNumber = payload;
    },
    setListBookingsActivePageSize: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listBookingsActivePageSize = payload;
    },
    setListBookingsActiveRowIndex: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listBookingsActiveRowIndex = payload;
    },
    setListBookingsActiveStartTime: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listBookingsActiveStartTime = payload;
    },
    setPageInfo: (state, { payload }: PayloadAction<PageInfo>) => {
      state.pageInfo = payload;
    },
    setRecordLoaded: (state, { payload }: PayloadAction<number>) => {
      state.recordLoaded = payload;
    },
    setResourceName: (state, { payload }: PayloadAction<string>) => {
      state.resourceName = payload;
    },
  },
});

export default bookingsListBookingsActiveSlice.reducer;

export const {
  addGroupBookingStyle: addGroupBookingStyleAction,
  clearFacilities: clearFacilitiesAction,
  clearGroupBookingStyles: clearGroupBookingStylesAction,
  setBookingId: setBookingIdAction,
  setBookingQuickLook: setBookingQuickLookAction,
  setDateRangeMode: setDateRangeModeAction,
  setFacilityCode: setFacilityCodeAction,
  setFacilityMode: setFacilityModeAction,
  setFilterBookingId: setFilterBookingIdAction,
  setFilterBookingStatus: setFilterBookingStatusAction,
  setFilterBookingType: setFilterBookingTypeAction,
  setFilterCoach: setFilterCoachAction,
  setFilterMemberName: setFilterMemberNameAction,
  setFilterVenue: setFilterVenueAction,
  setFirstRecordDate: setFirstRecordDateAction,
  setHasStudios: setHasStudiosAction,
  setHr1: setHr1Action,
  setHr2: setHr2Action,
  setHr3: setHr3Action,
  setHr4: setHr4Action,
  setHr5: setHr5Action,
  setIsGridReady: setIsGridReadyAction,
  setIsShowModalFacilitySelector: setIsShowModalFacilitySelectorAction,
  setIsShowUtilityDrawer: setIsShowUtilityDrawerAction,
  setLastRecordDate: setLastRecordDateAction,
  setListBookingsActiveEndCursor: setListBookingsActiveEndCursorAction,
  setListBookingsActiveEndTime: setListBookingsActiveEndTimeAction,
  setListBookingsActiveFirst: setListBookingsActiveFirstAction,
  setListBookingsActiveOrderBy: setListBookingsActiveOrderByAction,
  setListBookingsActivePageNumber: setListBookingsActivePageNumberAction,
  setListBookingsActivePageSize: setListBookingsActivePageSizeAction,
  setListBookingsActiveRowIndex: setListBookingsActiveRowIndexAction,
  setListBookingsActiveStartTime: setListBookingsActiveStartTimeAction,
  setPageInfo: setPageInfoAction,
  setRecordLoaded: setRecordLoadedAction,
  setResourceName: setResourceNameAction,
} = bookingsListBookingsActiveSlice.actions;
