import { Direction, OrderBy, PageInfo } from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const getDate = (date: string | undefined) => {
  if (date) {
    return new Date(date).toISOString();
  }

  return new Date().toISOString();
};

export interface States {
  filterMemberName: string;
  filterPaymentMethod: string;
  isGridReady: boolean;
  isShowUtilityDrawer: boolean;
  listHitPayChargesEndCursor: string;
  listHitPayChargesEndDate: string | null;
  listHitPayChargesFirst: number | null;
  listHitPayChargesOrderBy: OrderBy;
  listHitPayChargesPageInfo: PageInfo | null | undefined;
  listHitPayChargesPageNumber: number;
  listHitPayChargesPageSize: number;
  listHitPayChargesRecordLoaded: number;
  listHitPayChargesRowIndex: number;
  listHitPayChargesStartDate: string | null;
}

const initialState: States = {
  filterMemberName: '',
  filterPaymentMethod: '',
  isGridReady: false,
  isShowUtilityDrawer: false,
  listHitPayChargesEndCursor: '',
  listHitPayChargesEndDate: getDate('2022-12-01'),
  listHitPayChargesFirst: 1000,
  listHitPayChargesOrderBy: {
    direction: Direction.Desc,
    field: 'date',
  },
  listHitPayChargesPageInfo: null,
  listHitPayChargesPageNumber: 0,
  listHitPayChargesPageSize: 1000,
  listHitPayChargesRecordLoaded: 0,
  listHitPayChargesRowIndex: -1,
  listHitPayChargesStartDate: getDate('2022-09-01'),
};

export const reportListHitPayChargesSlice = createSlice({
  initialState,
  name: 'reportListHitPayCharges',
  reducers: {
    setFilterMemberName: (state, { payload }: PayloadAction<string>) => {
      state.filterMemberName = payload;
    },
    setFilterPaymentMethod: (state, { payload }: PayloadAction<string>) => {
      state.filterPaymentMethod = payload;
    },
    setIsGridReady: (state, { payload }: PayloadAction<boolean>) => {
      state.isGridReady = payload;
    },
    setIsShowUtilityDrawer: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowUtilityDrawer = payload;
    },
    setListHitPayChargesEndCursor: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listHitPayChargesEndCursor = payload;
    },
    setListHitPayChargesFirst: (
      state,
      { payload }: PayloadAction<number | null>,
    ) => {
      state.listHitPayChargesFirst = payload;
    },
    setListHitPayChargesOrderBy: (
      state,
      { payload }: PayloadAction<OrderBy>,
    ) => {
      state.listHitPayChargesOrderBy = payload;
    },
    setListHitPayChargesPageInfo: (
      state,
      { payload }: PayloadAction<PageInfo>,
    ) => {
      state.listHitPayChargesPageInfo = payload;
    },
    setListHitPayChargesPageNumber: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listHitPayChargesPageNumber = payload;
    },
    setListHitPayChargesPageSize: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listHitPayChargesPageSize = payload;
    },
    setListHitPayChargesRecordLoaded: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listHitPayChargesRecordLoaded = payload;
    },
    setListHitPayChargesRowIndex: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listHitPayChargesRowIndex = payload;
    },
  },
});

export default reportListHitPayChargesSlice.reducer;

export const {
  setFilterMemberName: setFilterMemberNameAction,
  setFilterPaymentMethod: setFilterPaymentMethodAction,
  setIsGridReady: setIsGridReadyAction,
  setIsShowUtilityDrawer: setIsShowUtilityDrawerAction,
  setListHitPayChargesEndCursor: setListHitPayChargesEndCursorAction,
  setListHitPayChargesFirst: setListHitPayChargesFirstAction,
  setListHitPayChargesOrderBy: setListHitPayChargesOrderByAction,
  setListHitPayChargesPageInfo: setListHitPayChargesPageInfoAction,
  setListHitPayChargesPageNumber: setListHitPayChargesPageNumberAction,
  setListHitPayChargesPageSize: setListHitPayChargesPageSizeAction,
  setListHitPayChargesRecordLoaded: setListHitPayChargesRecordLoadedAction,
  setListHitPayChargesRowIndex: setListHitPayChargesRowIndexAction,
} = reportListHitPayChargesSlice.actions;
