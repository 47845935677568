import {
  AdminMembershipServicesExpiryMode,
  AdminMembershipServicesStatus,
  CountryCode,
  Direction,
  OrderBy,
  PageInfo,
} from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateRangeMode } from 'components/DateRange';

export interface States {
  dateRangeMode: DateRangeMode;
  filterCountry: CountryCode | string;
  filterExpiry: AdminMembershipServicesExpiryMode | string;
  filterMemberName: string;
  filterPackageName: string;
  filterPackageStatus: AdminMembershipServicesStatus;
  filterServiceId: string;
  isGridReady: boolean;
  isShowUtilityDrawer: boolean;
  listPackagesDatesSort: string;
  listPackagesEndCursor: string;
  listPackagesEndTime: string | null;
  listPackagesFirst: number | null;
  listPackagesOrderBy: OrderBy;
  listPackagesPageNumber: number;
  listPackagesPageSize: number;
  listPackagesRowIndex: number;
  listPackagesStartTime: string | null;
  pageInfo: PageInfo | null | undefined;
  recordLoaded: number;
}

const initialState: States = {
  dateRangeMode: 'today',
  filterCountry: '',
  filterExpiry: '',
  filterMemberName: '',
  filterPackageName: '',
  filterPackageStatus: AdminMembershipServicesStatus.Active,
  filterServiceId: '',
  isGridReady: false,
  isShowUtilityDrawer: false,
  listPackagesDatesSort: 'expiry-asc',
  listPackagesEndCursor: '',
  listPackagesEndTime: null,
  listPackagesFirst: 1000,
  listPackagesOrderBy: {
    direction: Direction.Asc,
    field: 'expiryDate',
  },
  listPackagesPageNumber: 0,
  listPackagesPageSize: 100,
  listPackagesRowIndex: -1,
  listPackagesStartTime: null,
  pageInfo: null,
  recordLoaded: 0,
};

export const membersListPackagesSlice = createSlice({
  initialState,
  name: 'membersListPackages',
  reducers: {
    setDateRangeMode: (state, { payload }: PayloadAction<DateRangeMode>) => {
      state.dateRangeMode = payload;
    },
    setFilterCountry: (
      state,
      { payload }: PayloadAction<CountryCode | string>,
    ) => {
      state.filterCountry = payload;
    },
    setFilterExpiry: (
      state,
      { payload }: PayloadAction<AdminMembershipServicesExpiryMode | string>,
    ) => {
      state.filterExpiry = payload;
    },
    setFilterMemberName: (state, { payload }: PayloadAction<string>) => {
      state.filterMemberName = payload;
    },
    setFilterPackageName: (state, { payload }: PayloadAction<string>) => {
      state.filterPackageName = payload;
    },
    setFilterPackageStatus: (
      state,
      { payload }: PayloadAction<AdminMembershipServicesStatus>,
    ) => {
      state.filterPackageStatus = payload;
    },
    setFilterServiceId: (state, { payload }: PayloadAction<string>) => {
      state.filterServiceId = payload;
    },
    setIsGridReady: (state, { payload }: PayloadAction<boolean>) => {
      state.isGridReady = payload;
    },
    setIsShowUtilityDrawer: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowUtilityDrawer = payload;
    },
    setListPackagesDatesSort: (state, { payload }: PayloadAction<string>) => {
      state.listPackagesDatesSort = payload;
    },
    setListPackagesEndCursor: (state, { payload }: PayloadAction<string>) => {
      state.listPackagesEndCursor = payload;
    },
    setListPackagesEndTime: (state, { payload }: PayloadAction<string>) => {
      state.listPackagesEndTime = payload;
    },
    setListPackagesFirst: (
      state,
      { payload }: PayloadAction<number | null>,
    ) => {
      state.listPackagesFirst = payload;
    },
    setListPackagesOrderBy: (state, { payload }: PayloadAction<OrderBy>) => {
      state.listPackagesOrderBy = payload;
    },
    setListPackagesPageNumber: (state, { payload }: PayloadAction<number>) => {
      state.listPackagesPageNumber = payload;
    },
    setListPackagesPageSize: (state, { payload }: PayloadAction<number>) => {
      state.listPackagesPageSize = payload;
    },
    setListPackagesRowIndex: (state, { payload }: PayloadAction<number>) => {
      state.listPackagesRowIndex = payload;
    },
    setListPackagesStartTime: (state, { payload }: PayloadAction<string>) => {
      state.listPackagesStartTime = payload;
    },
    setPageInfo: (state, { payload }: PayloadAction<PageInfo>) => {
      state.pageInfo = payload;
    },
    setRecordLoaded: (state, { payload }: PayloadAction<number>) => {
      state.recordLoaded = payload;
    },
  },
});

export default membersListPackagesSlice.reducer;

export const {
  setDateRangeMode: setDateRangeModeAction,
  setFilterCountry: setFilterCountryAction,
  setFilterExpiry: setFilterExpiryAction,
  setFilterMemberName: setFilterMemberNameAction,
  setFilterPackageName: setFilterPackageNameAction,
  setFilterPackageStatus: setFilterPackageStatusAction,
  setFilterServiceId: setFilterServiceIdAction,
  setIsGridReady: setIsGridReadyAction,
  setIsShowUtilityDrawer: setIsShowUtilityDrawerAction,
  setListPackagesDatesSort: setListPackagesDatesSortAction,
  setListPackagesEndCursor: setListPackagesEndCursorAction,
  setListPackagesEndTime: setListPackagesEndTimeAction,
  setListPackagesFirst: setListPackagesFirstAction,
  setListPackagesOrderBy: setListPackagesOrderByAction,
  setListPackagesPageNumber: setListPackagesPageNumberAction,
  setListPackagesPageSize: setListPackagesPageSizeAction,
  setListPackagesRowIndex: setListPackagesRowIndexAction,
  setListPackagesStartTime: setListPackagesStartTimeAction,
  setPageInfo: setPageInfoAction,
  setRecordLoaded: setRecordLoadedAction,
} = membersListPackagesSlice.actions;
