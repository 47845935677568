import { CountryCode, Direction, OrderBy, PageInfo } from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface States {
  filterCoachName: string;
  filterCountry: CountryCode | string;
  isGridReady: boolean;
  listCoachesEndCursor: string;
  listCoachesFirst: number | null;
  listCoachesOrderBy: OrderBy;
  listCoachesPageNumber: number;
  listCoachesPageSize: number;
  listCoachesRowIndex: number;
  pageInfo: PageInfo | null | undefined;
  recordLoaded: number;
}

const initialState: States = {
  filterCoachName: '',
  filterCountry: '',
  isGridReady: false,
  listCoachesEndCursor: '',
  listCoachesFirst: 1000,
  listCoachesOrderBy: {
    direction: Direction.Desc,
    field: 'lastAccessTokenDate',
  },
  listCoachesPageNumber: 0,
  listCoachesPageSize: 25,
  listCoachesRowIndex: -1,
  pageInfo: null,
  recordLoaded: 0,
};

export const coachesListCoachesSlice = createSlice({
  initialState,
  name: 'coachesListCoaches',
  reducers: {
    setFilterCoachName: (state, { payload }: PayloadAction<string>) => {
      state.filterCoachName = payload;
    },
    setFilterCountry: (
      state,
      { payload }: PayloadAction<CountryCode | string>,
    ) => {
      state.filterCountry = payload;
    },
    setIsGridReady: (state, { payload }: PayloadAction<boolean>) => {
      state.isGridReady = payload;
    },
    setListCoachesEndCursor: (state, { payload }: PayloadAction<string>) => {
      state.listCoachesEndCursor = payload;
    },
    setListCoachesFirst: (state, { payload }: PayloadAction<number | null>) => {
      state.listCoachesFirst = payload;
    },
    setListCoachesOrderBy: (state, { payload }: PayloadAction<OrderBy>) => {
      state.listCoachesOrderBy = payload;
    },
    setListCoachesPageNumber: (state, { payload }: PayloadAction<number>) => {
      state.listCoachesPageNumber = payload;
    },
    setListCoachesPageSize: (state, { payload }: PayloadAction<number>) => {
      state.listCoachesPageSize = payload;
    },
    setListCoachesRowIndex: (state, { payload }: PayloadAction<number>) => {
      state.listCoachesRowIndex = payload;
    },
    setPageInfo: (state, { payload }: PayloadAction<PageInfo>) => {
      state.pageInfo = payload;
    },
    setRecordLoaded: (state, { payload }: PayloadAction<number>) => {
      state.recordLoaded = payload;
    },
  },
});

export default coachesListCoachesSlice.reducer;

export const {
  setFilterCoachName: setFilterCoachNameAction,
  setFilterCountry: setFilterCountryAction,
  setIsGridReady: setIsGridReadyAction,
  setListCoachesEndCursor: setListCoachesEndCursorAction,
  setListCoachesFirst: setListCoachesFirstAction,
  setListCoachesOrderBy: setListCoachesOrderByAction,
  setListCoachesPageNumber: setListCoachesPageNumberAction,
  setListCoachesPageSize: setListCoachesPageSizeAction,
  setListCoachesRowIndex: setListCoachesRowIndexAction,
  setPageInfo: setPageInfoAction,
  setRecordLoaded: setRecordLoadedAction,
} = coachesListCoachesSlice.actions;
