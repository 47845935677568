import {
  AdminGroupBooking,
  BookingStatus,
  BookingType,
  CoachAvailabilityCalendarItems,
  CoachClubhouse,
  Direction,
  OrderBy,
  PageInfo,
  SlotItem,
} from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DrawerTempLinkButtonValue } from 'components/_basic/DrawerComponents';
import { DateRangeMode } from 'components/DateFilter';

export interface BookingQuickLook {
  bookings: AdminGroupBooking[];
  isShowModal: boolean;
}

export type FacilityMode =
  | 'hour_1'
  | 'hour_2'
  | 'hour_3'
  | 'hour_4'
  | 'hour_5'
  | 'putting'
  | 'putting_30'
  | 'putting_60'
  | 'trial';

export interface AvailabiltyState {
  bi08: string;
  bi09: string;
  bi10: string;
  bi11: string;
  bi12: string;
  bi13: string;
  bi14: string;
  bi15: string;
  bi16: string;
  bi17: string;
  bi18: string;
  bi19: string;
  bi20: string;
  bi21: string;
  bs08: string;
  bs09: string;
  bs10: string;
  bs11: string;
  bs12: string;
  bs13: string;
  bs14: string;
  bs15: string;
  bs16: string;
  bs17: string;
  bs18: string;
  bs19: string;
  bs20: string;
  bs21: string;
  date: string;
  l08: string;
  l09: string;
  l10: string;
  l11: string;
  l12: string;
  l13: string;
  l14: string;
  l15: string;
  l16: string;
  l17: string;
  l18: string;
  l19: string;
  l20: string;
  l21: string;
}

interface InitialLocations {
  l08: string;
  l09: string;
  l10: string;
  l11: string;
  l12: string;
  l13: string;
  l14: string;
  l15: string;
  l16: string;
  l17: string;
  l18: string;
  l19: string;
  l20: string;
  l21: string;
}

type CoachIDs = {
  coachDisplayName: string;
  coachId: string;
  coachSlug: string;
};

interface SetBookingPayload {
  bookingDescription: string;
  bookingId: string;
  time: string;
}

interface SetClubhousePayload {
  clubhouseCode: string;
  time: string;
}

interface SetOutdoorBookingPayload {
  biOutdoor: string;
  hasOutdoorBooking: boolean;
}

export interface States extends AvailabiltyState {
  availableItems: CoachAvailabilityCalendarItems[];
  availableItemsCurrentDate: string;
  biOutdoor: string;
  bookingId: string;
  bookingQuickLook: BookingQuickLook | null;
  clubhouses: CoachClubhouse[];
  coachDisplayName: string;
  coachId: string;
  coachSlug: string;
  facilityCode: string | null;
  facilityMode: FacilityMode;
  groupBookingStyles: Record<string, string>;
  hasOutdoorBooking: boolean;
  hasStudios: boolean;
  hr1: SlotItem | null;
  hr2: SlotItem | null;
  hr3: SlotItem | null;
  hr4: SlotItem | null;
  hr5: SlotItem | null;
  initialLocations: InitialLocations;
  isGridReady: boolean;
  isShowModalFacilitySelector: boolean;
  listBookingsDateRangeMode: DateRangeMode;
  listBookingsEndCursor: string;
  listBookingsEndTime: string | null;
  listBookingsFilterBookingId: string;
  listBookingsFilterBookingStatus: BookingStatus;
  listBookingsFilterBookingType: BookingType | string;
  listBookingsFilterVenue: string;
  listBookingsFirst: number | null;
  listBookingsFirstDate: string | null;
  listBookingsLastRecordDate: string | null;
  listBookingsListBookingsActiveFirst: number | null;
  listBookingsOrderBy: OrderBy;
  listBookingsPageInfo: PageInfo | null | undefined;
  listBookingsPageNumber: number;
  listBookingsPageSize: number;
  listBookingsRecordLoaded: number;
  listBookingsRowIndex: number;
  listBookingsStartTime: string | null;
  locationsHaveChanged: boolean;
  resourceName: string;
  selectedCoach: DrawerTempLinkButtonValue;
  tabIndex: number;
}

const initialState: States = {
  availableItems: [],
  availableItemsCurrentDate: new Date().toDateString(),
  bi08: '',
  bi09: '',
  bi10: '',
  bi11: '',
  bi12: '',
  bi13: '',
  bi14: '',
  bi15: '',
  bi16: '',
  bi17: '',
  bi18: '',
  bi19: '',
  bi20: '',
  bi21: '',
  biOutdoor: '',
  bookingId: '',
  bookingQuickLook: null,
  bs08: '',
  bs09: '',
  bs10: '',
  bs11: '',
  bs12: '',
  bs13: '',
  bs14: '',
  bs15: '',
  bs16: '',
  bs17: '',
  bs18: '',
  bs19: '',
  bs20: '',
  bs21: '',
  clubhouses: [],
  coachDisplayName: '',
  coachId: '',
  coachSlug: '',
  date: '',
  facilityCode: null,
  facilityMode: 'hour_1',
  groupBookingStyles: {},
  hasOutdoorBooking: false,
  hasStudios: false,
  hr1: null,
  hr2: null,
  hr3: null,
  hr4: null,
  hr5: null,
  initialLocations: {
    l08: '',
    l09: '',
    l10: '',
    l11: '',
    l12: '',
    l13: '',
    l14: '',
    l15: '',
    l16: '',
    l17: '',
    l18: '',
    l19: '',
    l20: '',
    l21: '',
  },
  isGridReady: false,
  isShowModalFacilitySelector: false,
  l08: '',
  l09: '',
  l10: '',
  l11: '',
  l12: '',
  l13: '',
  l14: '',
  l15: '',
  l16: '',
  l17: '',
  l18: '',
  l19: '',
  l20: '',
  l21: '',
  listBookingsDateRangeMode: 'from-today-asc',

  listBookingsEndCursor: '',
  listBookingsEndTime: null,
  listBookingsFilterBookingId: '',
  listBookingsFilterBookingStatus: BookingStatus.Active,
  listBookingsFilterBookingType: '',
  listBookingsFilterVenue: '',
  listBookingsFirst: 1000,
  listBookingsFirstDate: null,
  listBookingsLastRecordDate: null,
  listBookingsListBookingsActiveFirst: 1000,
  listBookingsOrderBy: {
    direction: Direction.Asc,
    field: 'startTime',
  },
  listBookingsPageInfo: null,
  listBookingsPageNumber: 0,
  listBookingsPageSize: 100,
  listBookingsRecordLoaded: 0,
  listBookingsRowIndex: -1,
  listBookingsStartTime: null,
  locationsHaveChanged: false,

  resourceName: '',
  selectedCoach: {
    id: '',
    label: '',
    meta: '',
  },
  tabIndex: 0,
};

export const coachesEditCoachSlice = createSlice({
  initialState,
  name: 'coachesEditCoach',
  reducers: {
    addGroupBookingStyle: (
      state,
      { payload }: PayloadAction<Record<string, string>>,
    ) => {
      state.groupBookingStyles = {
        ...state.groupBookingStyles,
        ...payload,
      };
    },
    clearFacilities: (state) => {
      state.facilityCode = null;
      state.facilityMode = 'hour_1';
      state.hasStudios = false;
      state.hr1 = null;
      state.hr2 = null;
      state.hr3 = null;
      state.hr4 = null;
      state.hr5 = null;
    },
    clearGroupBookingStyles: (state) => {
      state.groupBookingStyles = {};
    },
    setAvailableItems: (
      state,
      { payload }: PayloadAction<CoachAvailabilityCalendarItems[]>,
    ) => {
      state.availableItems = payload;
    },
    setAvailableItemsCurrentDate: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.availableItemsCurrentDate = payload;
    },
    setBooking: (state, { payload }: PayloadAction<SetBookingPayload>) => {
      const { bookingDescription, bookingId } = payload;
      const dAccessor = `bd${payload.time}` as keyof AvailabiltyState;
      state[dAccessor] = bookingDescription;
      const iAccessor = `bi${payload.time}` as keyof AvailabiltyState;
      state[iAccessor] = bookingId;
    },
    setBookingId: (state, { payload }: PayloadAction<string>) => {
      state.bookingId = payload;
    },
    setBookingQuickLook: (
      state,
      { payload }: PayloadAction<BookingQuickLook | null>,
    ) => {
      state.bookingQuickLook = payload;
    },
    setClubhouse: (state, { payload }: PayloadAction<SetClubhousePayload>) => {
      const { clubhouseCode, time } = payload;
      const accessor = `l${time}` as keyof AvailabiltyState;
      state[accessor] = clubhouseCode;
    },
    setClubhouses: (state, { payload }: PayloadAction<CoachClubhouse[]>) => {
      state.clubhouses = payload;
    },
    setCoach: (state, { payload }: PayloadAction<CoachIDs>) => {
      state.coachDisplayName = payload.coachDisplayName;
      state.coachId = payload.coachId;
      state.coachSlug = payload.coachSlug;
    },
    setDate: (state, { payload }: PayloadAction<string>) => {
      state.date = payload;
    },
    setFacilityCode: (state, { payload }: PayloadAction<string | null>) => {
      state.facilityCode = payload;
    },
    setFacilityMode: (state, { payload }: PayloadAction<FacilityMode>) => {
      state.facilityMode = payload;
    },
    setHasStudios: (state, { payload }: PayloadAction<boolean>) => {
      state.hasStudios = payload;
    },
    setHr1: (state, { payload }: PayloadAction<SlotItem | null>) => {
      state.hr1 = payload;
    },
    setHr2: (state, { payload }: PayloadAction<SlotItem | null>) => {
      state.hr2 = payload;
    },
    setHr3: (state, { payload }: PayloadAction<SlotItem | null>) => {
      state.hr3 = payload;
    },
    setHr4: (state, { payload }: PayloadAction<SlotItem | null>) => {
      state.hr4 = payload;
    },
    setHr5: (state, { payload }: PayloadAction<SlotItem | null>) => {
      state.hr5 = payload;
    },
    setInitialLocations: (
      state,
      { payload }: PayloadAction<InitialLocations>,
    ) => {
      state.initialLocations = payload;
    },
    setIsGridReady: (state, { payload }: PayloadAction<boolean>) => {
      state.isGridReady = payload;
    },
    setIsShowModalFacilitySelector: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isShowModalFacilitySelector = payload;
    },
    setListBookingsDateRangeMode: (
      state,
      { payload }: PayloadAction<DateRangeMode>,
    ) => {
      state.listBookingsDateRangeMode = payload;
    },
    setListBookingsEndCursor: (state, { payload }: PayloadAction<string>) => {
      state.listBookingsEndCursor = payload;
    },
    setListBookingsEndTime: (state, { payload }: PayloadAction<string>) => {
      state.listBookingsEndTime = payload;
    },
    setListBookingsFilterBookingId: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listBookingsFilterBookingId = payload;
    },
    setListBookingsFilterBookingStatus: (
      state,
      { payload }: PayloadAction<BookingStatus>,
    ) => {
      state.listBookingsFilterBookingStatus = payload;
    },
    setListBookingsFilterBookingType: (
      state,
      { payload }: PayloadAction<BookingType | string>,
    ) => {
      state.listBookingsFilterBookingType = payload;
    },
    setListBookingsFilterVenue: (state, { payload }: PayloadAction<string>) => {
      state.listBookingsFilterVenue = payload;
    },
    setListBookingsFirst: (
      state,
      { payload }: PayloadAction<number | null>,
    ) => {
      state.listBookingsFirst = payload;
    },
    setListBookingsFirstDate: (state, { payload }: PayloadAction<string>) => {
      state.listBookingsFirstDate = payload;
    },
    setListBookingsLastRecordDate: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listBookingsLastRecordDate = payload;
    },
    setListBookingsListBookingsActiveFirst: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listBookingsListBookingsActiveFirst = payload;
    },
    setListBookingsOrderBy: (state, { payload }: PayloadAction<OrderBy>) => {
      state.listBookingsOrderBy = payload;
    },
    setListBookingsPageInfo: (
      state,
      { payload }: PayloadAction<PageInfo | null>,
    ) => {
      state.listBookingsPageInfo = payload;
    },
    setListBookingsPageNumber: (state, { payload }: PayloadAction<number>) => {
      state.listBookingsPageNumber = payload;
    },
    setListBookingsPageSize: (state, { payload }: PayloadAction<number>) => {
      state.listBookingsPageSize = payload;
    },
    setListBookingsRecordLoaded: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listBookingsRecordLoaded = payload;
    },
    setListBookingsRowIndex: (state, { payload }: PayloadAction<number>) => {
      state.listBookingsRowIndex = payload;
    },
    setListBookingsStartTime: (state, { payload }: PayloadAction<string>) => {
      state.listBookingsStartTime = payload;
    },
    setLocationsHaveChaged: (state, { payload }: PayloadAction<boolean>) => {
      state.locationsHaveChanged = payload;
    },
    setOutdoorBooking: (
      state,
      { payload }: PayloadAction<SetOutdoorBookingPayload>,
    ) => {
      const { biOutdoor, hasOutdoorBooking } = payload;
      state.hasOutdoorBooking = hasOutdoorBooking;
      state.biOutdoor = biOutdoor;
    },
    setResourceName: (state, { payload }: PayloadAction<string>) => {
      state.resourceName = payload;
    },
    setSelectedCoach: (
      state,
      { payload }: PayloadAction<DrawerTempLinkButtonValue>,
    ) => {
      state.selectedCoach = payload;
    },
    setTabIndex: (state, { payload }: PayloadAction<number>) => {
      state.tabIndex = payload;
    },
  },
});

export default coachesEditCoachSlice.reducer;

export const {
  addGroupBookingStyle: addGroupBookingStyleAction,
  clearFacilities: clearFacilitiesAction,
  clearGroupBookingStyles: clearGroupBookingStylesAction,
  setAvailableItems: setAvailableItemsAction,
  setAvailableItemsCurrentDate: setAvailableItemsCurrentDateAction,
  setBooking: setBookingAction,
  setBookingId: setBookingIdAction,
  setBookingQuickLook: setBookingQuickLookAction,
  setClubhouse: setClubhouseAction,
  setClubhouses: setClubhousesAction,
  setCoach: setCoachAction,
  setDate: setDateAction,
  setFacilityCode: setFacilityCodeAction,
  setFacilityMode: setFacilityModeAction,
  setHasStudios: setHasStudiosAction,
  setHr1: setHr1Action,
  setHr2: setHr2Action,
  setHr3: setHr3Action,
  setHr4: setHr4Action,
  setHr5: setHr5Action,
  setInitialLocations: setInitialLocationsAction,
  setIsGridReady: setIsGridReadyAction,
  setIsShowModalFacilitySelector: setIsShowModalFacilitySelectorAction,
  setListBookingsDateRangeMode: setListBookingsDateRangeModeAction,
  setListBookingsEndCursor: setListBookingsEndCursorAction,
  setListBookingsEndTime: setListBookingsEndTimeAction,
  setListBookingsFilterBookingId: setListBookingsFilterBookingIdAction,
  setListBookingsFilterBookingStatus: setListBookingsFilterBookingStatusAction,
  setListBookingsFilterBookingType: setListBookingsFilterBookingTypeAction,
  setListBookingsFilterVenue: setListBookingsFilterVenueAction,
  setListBookingsFirst: setListBookingsFirstAction,
  setListBookingsFirstDate: setListBookingsFirstDateAction,
  setListBookingsLastRecordDate: setListBookingsLastRecordDateAction,
  setListBookingsListBookingsActiveFirst:
    setListBookingsListBookingsActiveFirstAction,
  setListBookingsOrderBy: setListBookingsOrderByAction,
  setListBookingsPageInfo: setListBookingsPageInfoAction,
  setListBookingsPageNumber: setListBookingsPageNumberAction,
  setListBookingsPageSize: setListBookingsPageSizeAction,
  setListBookingsRecordLoaded: setListBookingsRecordLoadedAction,
  setListBookingsRowIndex: setListBookingsRowIndexAction,
  setListBookingsStartTime: setListBookingsStartTimeAction,
  setLocationsHaveChaged: setLocationsHaveChagedAction,
  setOutdoorBooking: setOutdoorBookingAction,
  setResourceName: setResourceNameAction,
  setSelectedCoach: setSelectedCoachAction,
  setTabIndex: setTabIndexAction,
} = coachesEditCoachSlice.actions;
