import { Direction, OrderBy, PageInfo } from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface States {
  isGridReady: boolean;
  listNotificationsEndCursor: string;
  listNotificationsEndDate: string | null;
  listNotificationsFilterSearchTerm: string;
  listNotificationsFirst: number | null;
  listNotificationsOrderBy: OrderBy;
  listNotificationsPageInfo: PageInfo | null | undefined;
  listNotificationsPageNumber: number;
  listNotificationsPageSize: number;
  listNotificationsRecordLoaded: number;
  listNotificationsRowIndex: number;
  listNotificationsStartDate: string | null;
}

const initialState: States = {
  isGridReady: false,
  listNotificationsEndCursor: '',
  listNotificationsEndDate: null,
  listNotificationsFilterSearchTerm: '',
  listNotificationsFirst: 1000,
  listNotificationsOrderBy: {
    direction: Direction.Desc,
    field: 'createdAt',
  },
  listNotificationsPageInfo: null,
  listNotificationsPageNumber: 0,
  listNotificationsPageSize: 10,
  listNotificationsRecordLoaded: 0,
  listNotificationsRowIndex: -1,
  listNotificationsStartDate: null,
};

export const notificationsListNotificationsSlice = createSlice({
  initialState,
  name: 'notificationsListNotifications',
  reducers: {
    setIsGridReady: (state, { payload }: PayloadAction<boolean>) => {
      state.isGridReady = payload;
    },
    setListNotificationsEndCursor: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listNotificationsEndCursor = payload;
    },
    setListNotificationsFilterSearchTerm: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listNotificationsFilterSearchTerm = payload;
    },
    setListNotificationsFirst: (
      state,
      { payload }: PayloadAction<number | null>,
    ) => {
      state.listNotificationsFirst = payload;
    },
    setListNotificationsOrderBy: (
      state,
      { payload }: PayloadAction<OrderBy>,
    ) => {
      state.listNotificationsOrderBy = payload;
    },
    setListNotificationsPageInfo: (
      state,
      { payload }: PayloadAction<PageInfo>,
    ) => {
      state.listNotificationsPageInfo = payload;
    },
    setListNotificationsPageNumber: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listNotificationsPageNumber = payload;
    },
    setListNotificationsPageSize: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listNotificationsPageSize = payload;
    },
    setListNotificationsRecordLoaded: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listNotificationsRecordLoaded = payload;
    },
    setListNotificationsRowIndex: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listNotificationsRowIndex = payload;
    },
  },
});

export default notificationsListNotificationsSlice.reducer;

export const {
  setIsGridReady: setIsGridReadyAction,
  setListNotificationsEndCursor: setListNotificationsEndCursorAction,
  setListNotificationsFilterSearchTerm:
    setListNotificationsFilterSearchTermAction,
  setListNotificationsFirst: setListNotificationsFirstAction,
  setListNotificationsOrderBy: setListNotificationsOrderByAction,
  setListNotificationsPageInfo: setListNotificationsPageInfoAction,
  setListNotificationsPageNumber: setListNotificationsPageNumberAction,
  setListNotificationsPageSize: setListNotificationsPageSizeAction,
  setListNotificationsRecordLoaded: setListNotificationsRecordLoadedAction,
  setListNotificationsRowIndex: setListNotificationsRowIndexAction,
} = notificationsListNotificationsSlice.actions;
