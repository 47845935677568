import {
  AcTransactionType,
  Direction,
  OrderBy,
  PageInfo,
} from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateRangeMode } from 'components/DateFilter';

export interface States {
  dateRangeMode: DateRangeMode;
  isGridReady: boolean;
  isShowUtilityDrawer: boolean;
  listAcTransactionsEndCursor: string;
  listAcTransactionsEndDate: string | null;
  listAcTransactionsFilterTransactionType: AcTransactionType | string;
  listAcTransactionsFirst: number | null;
  listAcTransactionsFirstDate: string | null;
  listAcTransactionsOrderBy: OrderBy;
  listAcTransactionsPageInfo: PageInfo | null | undefined;
  listAcTransactionsPageNumber: number;
  listAcTransactionsPageSize: number;
  listAcTransactionsRecordLoaded: number;
  listAcTransactionsRowIndex: number;
  listAcTransactionsSearchTerm: string;
  listAcTransactionsStartDate: string | null;
}

const initialState: States = {
  dateRangeMode: 'today',
  isGridReady: false,
  isShowUtilityDrawer: false,
  listAcTransactionsEndCursor: '',
  listAcTransactionsEndDate: null,
  listAcTransactionsFilterTransactionType: '',
  listAcTransactionsFirst: 1000,
  listAcTransactionsFirstDate: null,
  listAcTransactionsOrderBy: {
    direction: Direction.Desc,
    field: 'transactionDateTime',
  },
  listAcTransactionsPageInfo: null,
  listAcTransactionsPageNumber: 0,
  listAcTransactionsPageSize: 100,
  listAcTransactionsRecordLoaded: 0,
  listAcTransactionsRowIndex: -1,
  listAcTransactionsSearchTerm: '',
  listAcTransactionsStartDate: null,
};

export const reportListAcTransactionsSlice = createSlice({
  initialState,
  name: 'reportListAcTransactions',
  reducers: {
    setDateRangeMode: (state, { payload }: PayloadAction<DateRangeMode>) => {
      state.dateRangeMode = payload;
    },
    setIsGridReady: (state, { payload }: PayloadAction<boolean>) => {
      state.isGridReady = payload;
    },
    setIsShowUtilityDrawer: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowUtilityDrawer = payload;
    },
    setListAcTransactionsEndCursor: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listAcTransactionsEndCursor = payload;
    },
    setListAcTransactionsEndDate: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listAcTransactionsEndDate = payload;
    },
    setListAcTransactionsFilterTransactionType: (
      state,
      { payload }: PayloadAction<AcTransactionType | string>,
    ) => {
      state.listAcTransactionsFilterTransactionType = payload;
    },
    setListAcTransactionsFirst: (
      state,
      { payload }: PayloadAction<number | null>,
    ) => {
      state.listAcTransactionsFirst = payload;
    },
    setListAcTransactionsFirstDate: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listAcTransactionsFirstDate = payload;
    },
    setListAcTransactionsOrderBy: (
      state,
      { payload }: PayloadAction<OrderBy>,
    ) => {
      state.listAcTransactionsOrderBy = payload;
    },
    setListAcTransactionsPageInfo: (
      state,
      { payload }: PayloadAction<PageInfo>,
    ) => {
      state.listAcTransactionsPageInfo = payload;
    },
    setListAcTransactionsPageNumber: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listAcTransactionsPageNumber = payload;
    },
    setListAcTransactionsPageSize: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listAcTransactionsPageSize = payload;
    },
    setListAcTransactionsRecordLoaded: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listAcTransactionsRecordLoaded = payload;
    },
    setListAcTransactionsRowIndex: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listAcTransactionsRowIndex = payload;
    },
    setListAcTransactionsSearchTerm: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listAcTransactionsSearchTerm = payload;
    },
    setListAcTransactionsStartDate: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listAcTransactionsStartDate = payload;
    },
  },
});

export default reportListAcTransactionsSlice.reducer;

export const {
  setDateRangeMode: setDateRangeModeAction,
  setIsGridReady: setIsGridReadyAction,
  setIsShowUtilityDrawer: setIsShowUtilityDrawerAction,
  setListAcTransactionsEndCursor: setListAcTransactionsEndCursorAction,
  setListAcTransactionsEndDate: setListAcTransactionsEndDateAction,
  setListAcTransactionsFilterTransactionType:
    setListAcTransactionsFilterTransactionTypeAction,
  setListAcTransactionsFirst: setListAcTransactionsFirstAction,
  setListAcTransactionsFirstDate: setListAcTransactionsFirstDateAction,
  setListAcTransactionsOrderBy: setListAcTransactionsOrderByAction,
  setListAcTransactionsPageInfo: setListAcTransactionsPageInfoAction,
  setListAcTransactionsPageNumber: setListAcTransactionsPageNumberAction,
  setListAcTransactionsPageSize: setListAcTransactionsPageSizeAction,
  setListAcTransactionsRecordLoaded: setListAcTransactionsRecordLoadedAction,
  setListAcTransactionsRowIndex: setListAcTransactionsRowIndexAction,
  setListAcTransactionsSearchTerm: setListAcTransactionsSearchTermAction,
  setListAcTransactionsStartDate: setListAcTransactionsStartDateAction,
} = reportListAcTransactionsSlice.actions;
