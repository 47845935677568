import { CountryCode, Direction, OrderBy, PageInfo } from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type DeleteCountryPayload = {
  countryCode: CountryCode;
  date: string;
};

export interface States {
  deleteCountryPayload: DeleteCountryPayload | null;
  filterCoachName: string;
  isGridReady: boolean;
  isShowCountryModal: boolean;
  isShowUtilityDrawer: boolean;
  listBlackoutsFirst: number | null;
  listBlackoutsOrderBy: OrderBy;
  listBlackoutsPageInfo: PageInfo | null | undefined;
  listBlackoutsPageNumber: number;
  listBlackoutsPageSize: number;
  listBlackoutsRecordLoaded: number;
  listBlackoutsRowIndex: number;
}

const initialState: States = {
  deleteCountryPayload: null,
  filterCoachName: '',
  isGridReady: false,
  isShowCountryModal: false,
  isShowUtilityDrawer: false,
  listBlackoutsFirst: 1000,
  listBlackoutsOrderBy: {
    direction: Direction.Asc,
    field: 'date',
  },
  listBlackoutsPageInfo: null,
  listBlackoutsPageNumber: 0,
  listBlackoutsPageSize: 100,
  listBlackoutsRecordLoaded: 0,
  listBlackoutsRowIndex: -1,
};

export const bookingsListBlackoutsSlice = createSlice({
  initialState,
  name: 'bookingsListBlackouts',
  reducers: {
    setDeleteCountryPayload: (
      state,
      { payload }: PayloadAction<DeleteCountryPayload | null>,
    ) => {
      state.deleteCountryPayload = payload;
    },
    setFilterCoachName: (state, { payload }: PayloadAction<string>) => {
      state.filterCoachName = payload;
    },
    setIsGridReady: (state, { payload }: PayloadAction<boolean>) => {
      state.isGridReady = payload;
    },
    setIsShowCountryModal: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowCountryModal = payload;
    },
    setIsShowUtilityDrawer: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowUtilityDrawer = payload;
    },
    setListBlackoutsFirst: (
      state,
      { payload }: PayloadAction<number | null>,
    ) => {
      state.listBlackoutsFirst = payload;
    },
    setListBlackoutsOrderBy: (state, { payload }: PayloadAction<OrderBy>) => {
      state.listBlackoutsOrderBy = payload;
    },
    setListBlackoutsPageInfo: (state, { payload }: PayloadAction<PageInfo>) => {
      state.listBlackoutsPageInfo = payload;
    },
    setListBlackoutsPageNumber: (state, { payload }: PayloadAction<number>) => {
      state.listBlackoutsPageNumber = payload;
    },
    setListBlackoutsPageSize: (state, { payload }: PayloadAction<number>) => {
      state.listBlackoutsPageSize = payload;
    },
    setListBlackoutsRecordLoaded: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listBlackoutsRecordLoaded = payload;
    },
    setListBlackoutsRowIndex: (state, { payload }: PayloadAction<number>) => {
      state.listBlackoutsRowIndex = payload;
    },
  },
});

export default bookingsListBlackoutsSlice.reducer;

export const {
  setDeleteCountryPayload: setDeleteCountryPayloadAction,
  setFilterCoachName: setFilterCoachNameAction,
  setIsGridReady: setIsGridReadyAction,
  setIsShowCountryModal: setIsShowCountryModalAction,
  setIsShowUtilityDrawer: setIsShowUtilityDrawerAction,
  setListBlackoutsFirst: setListBlackoutsFirstAction,
  setListBlackoutsOrderBy: setListBlackoutsOrderByAction,
  setListBlackoutsPageInfo: setListBlackoutsPageInfoAction,
  setListBlackoutsPageNumber: setListBlackoutsPageNumberAction,
  setListBlackoutsPageSize: setListBlackoutsPageSizeAction,
  setListBlackoutsRecordLoaded: setListBlackoutsRecordLoadedAction,
  setListBlackoutsRowIndex: setListBlackoutsRowIndexAction,
} = bookingsListBlackoutsSlice.actions;
