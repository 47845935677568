import { Direction, OrderBy, PageInfo, PaymentMethod } from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateRangeMode } from 'components/DateFilter';

export interface States {
  dateRangeMode: DateRangeMode;
  isGridReady: boolean;
  isShowUtilityDrawer: boolean;
  listInvoicesEndCursor: string;
  listInvoicesEndDate: string | null;
  listInvoicesFilterInvoiceNumber: string;
  listInvoicesFilterPaymentMethod: PaymentMethod | string;
  listInvoicesFirst: number | null;
  listInvoicesFirstDate: string | null;
  listInvoicesOrderBy: OrderBy;
  listInvoicesPageInfo: PageInfo | null | undefined;
  listInvoicesPageNumber: number;
  listInvoicesPageSize: number;
  listInvoicesRecordLoaded: number;
  listInvoicesRowIndex: number;
  listInvoicesStartDate: string | null;
}

const initialState: States = {
  dateRangeMode: 'today',
  isGridReady: false,
  isShowUtilityDrawer: false,
  listInvoicesEndCursor: '',
  listInvoicesEndDate: null,
  listInvoicesFilterInvoiceNumber: '',
  listInvoicesFilterPaymentMethod: '',
  listInvoicesFirst: 1000,
  listInvoicesFirstDate: null,
  listInvoicesOrderBy: {
    direction: Direction.Desc,
    field: 'transactionDateTime',
  },
  listInvoicesPageInfo: null,
  listInvoicesPageNumber: 0,
  listInvoicesPageSize: 100,
  listInvoicesRecordLoaded: 0,
  listInvoicesRowIndex: -1,
  listInvoicesStartDate: null,
};

export const reportListInvoicesSlice = createSlice({
  initialState,
  name: 'reportListInvoices',
  reducers: {
    setDateRangeMode: (state, { payload }: PayloadAction<DateRangeMode>) => {
      state.dateRangeMode = payload;
    },
    setIsGridReady: (state, { payload }: PayloadAction<boolean>) => {
      state.isGridReady = payload;
    },
    setIsShowUtilityDrawer: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowUtilityDrawer = payload;
    },

    setListInvoicesEndCursor: (state, { payload }: PayloadAction<string>) => {
      state.listInvoicesEndCursor = payload;
    },
    setListInvoicesEndDate: (state, { payload }: PayloadAction<string>) => {
      state.listInvoicesEndDate = payload;
    },
    setListInvoicesFilterInvoiceNumber: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listInvoicesFilterInvoiceNumber = payload;
    },
    setListInvoicesFilterPaymentMethod: (
      state,
      { payload }: PayloadAction<PaymentMethod | string>,
    ) => {
      state.listInvoicesFilterPaymentMethod = payload;
    },
    setListInvoicesFirst: (
      state,
      { payload }: PayloadAction<number | null>,
    ) => {
      state.listInvoicesFirst = payload;
    },
    setListInvoicesFirstDate: (state, { payload }: PayloadAction<string>) => {
      state.listInvoicesFirstDate = payload;
    },

    setListInvoicesOrderBy: (state, { payload }: PayloadAction<OrderBy>) => {
      state.listInvoicesOrderBy = payload;
    },
    setListInvoicesPageInfo: (
      state,
      { payload }: PayloadAction<PageInfo | null>,
    ) => {
      state.listInvoicesPageInfo = payload;
    },
    setListInvoicesPageNumber: (state, { payload }: PayloadAction<number>) => {
      state.listInvoicesPageNumber = payload;
    },
    setListInvoicesPageSize: (state, { payload }: PayloadAction<number>) => {
      state.listInvoicesPageSize = payload;
    },
    setListInvoicesRecordLoaded: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listInvoicesRecordLoaded = payload;
    },
    setListInvoicesRowIndex: (state, { payload }: PayloadAction<number>) => {
      state.listInvoicesRowIndex = payload;
    },
    setListInvoicesStartDate: (state, { payload }: PayloadAction<string>) => {
      state.listInvoicesStartDate = payload;
    },
  },
});

export default reportListInvoicesSlice.reducer;

export const {
  setDateRangeMode: setDateRangeModeAction,
  setIsGridReady: setIsGridReadyAction,
  setIsShowUtilityDrawer: setIsShowUtilityDrawerAction,
  setListInvoicesEndCursor: setListInvoicesEndCursorAction,
  setListInvoicesEndDate: setListInvoicesEndDateAction,
  setListInvoicesFilterInvoiceNumber: setListInvoicesFilterInvoiceNumberAction,
  setListInvoicesFilterPaymentMethod: setListInvoicesFilterPaymentMethodAction,
  setListInvoicesFirst: setListInvoicesFirstAction,
  setListInvoicesFirstDate: setListInvoicesFirstDateAction,
  setListInvoicesOrderBy: setListInvoicesOrderByAction,
  setListInvoicesPageInfo: setListInvoicesPageInfoAction,
  setListInvoicesPageNumber: setListInvoicesPageNumberAction,
  setListInvoicesPageSize: setListInvoicesPageSizeAction,
  setListInvoicesRecordLoaded: setListInvoicesRecordLoadedAction,
  setListInvoicesRowIndex: setListInvoicesRowIndexAction,
  setListInvoicesStartDate: setListInvoicesStartDateAction,
} = reportListInvoicesSlice.actions;
