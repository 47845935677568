import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface States {
  isGridReady: boolean;
  listMemberAceCoinIssuesPageNumber: number;
  listMemberAceCoinIssuesPageSize: number;
  listMemberAceCoinIssuesRowIndex: number;
  recordLoaded: number;
}

const initialState: States = {
  isGridReady: false,
  listMemberAceCoinIssuesPageNumber: 0,
  listMemberAceCoinIssuesPageSize: 100,
  listMemberAceCoinIssuesRowIndex: -1,
  recordLoaded: 0,
};

export const devMemberAceCoinIssueSlice = createSlice({
  initialState,
  name: 'devMemberAceCoinIssue',
  reducers: {
    setIsGridReady: (state, { payload }: PayloadAction<boolean>) => {
      state.isGridReady = payload;
    },
    setListMemberAceCoinIssuesPageNumber: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listMemberAceCoinIssuesPageNumber = payload;
    },
    setListMemberAceCoinIssuesPageSize: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listMemberAceCoinIssuesPageSize = payload;
    },
    setListMemberAceCoinIssuesRowIndex: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listMemberAceCoinIssuesRowIndex = payload;
    },
    setRecordLoaded: (state, { payload }: PayloadAction<number>) => {
      state.recordLoaded = payload;
    },
  },
});

export default devMemberAceCoinIssueSlice.reducer;

export const {
  setIsGridReady: setIsGridReadyAction,
  setListMemberAceCoinIssuesPageNumber:
    setListMemberAceCoinIssuesPageNumberAction,
  setListMemberAceCoinIssuesPageSize: setListMemberAceCoinIssuesPageSizeAction,
  setListMemberAceCoinIssuesRowIndex: setListMemberAceCoinIssuesRowIndexAction,
  setRecordLoaded: setRecordLoadedAction,
} = devMemberAceCoinIssueSlice.actions;
