import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface States {
  isGridReady: boolean;
  recordLoaded: number;
  transactionsEndCursor: string;
  transactionsPageNumber: number;
  transactionsPageSize: number;
  transactionsRowIndex: number;
}

const initialState: States = {
  isGridReady: false,
  recordLoaded: 0,
  transactionsEndCursor: '',
  transactionsPageNumber: 0,
  transactionsPageSize: 100,
  transactionsRowIndex: -1,
};

export const devAceCoinsEditUnbalancedMembershipSlice = createSlice({
  initialState,
  name: 'devAceCoinsEditUnbalancedMembership',
  reducers: {
    setIsGridReady: (state, { payload }: PayloadAction<boolean>) => {
      state.isGridReady = payload;
    },
    setRecordLoaded: (state, { payload }: PayloadAction<number>) => {
      state.recordLoaded = payload;
    },
    setTransactionsEndCursor: (state, { payload }: PayloadAction<string>) => {
      state.transactionsEndCursor = payload;
    },
    setTransactionsPageNumber: (state, { payload }: PayloadAction<number>) => {
      state.transactionsPageNumber = payload;
    },
    setTransactionsPageSize: (state, { payload }: PayloadAction<number>) => {
      state.transactionsPageSize = payload;
    },
    setTransactionsRowIndex: (state, { payload }: PayloadAction<number>) => {
      state.transactionsRowIndex = payload;
    },
  },
});

export default devAceCoinsEditUnbalancedMembershipSlice.reducer;

export const {
  setIsGridReady: setIsGridReadyAction,
  setRecordLoaded: setRecordLoadedAction,
  setTransactionsEndCursor: setTransactionsEndCursorAction,
  setTransactionsPageNumber: setTransactionsPageNumberAction,
  setTransactionsPageSize: setTransactionsPageSizeAction,
  setTransactionsRowIndex: setTransactionsRowIndexAction,
} = devAceCoinsEditUnbalancedMembershipSlice.actions;
