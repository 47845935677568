import {
  CountryCode,
  Direction,
  OrderBy,
  PageInfo,
  UserType,
} from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface States {
  filterAdminName: string;
  filterAdminType: UserType | string;
  filterCountry: CountryCode | string;
  isGridReady: boolean;
  listAdminsEndCursor: string;
  listAdminsFirst: number | null;
  listAdminsOrderBy: OrderBy;
  listAdminsPageNumber: number;
  listAdminsPageSize: number;
  listAdminsRowIndex: number;
  pageInfo: PageInfo | null | undefined;
  recordLoaded: number;
}

const initialState: States = {
  filterAdminName: '',
  filterAdminType: '',
  filterCountry: '',
  isGridReady: false,
  listAdminsEndCursor: '',
  listAdminsFirst: 1000,
  listAdminsOrderBy: {
    direction: Direction.Desc,
    field: 'lastAccessTokenDate',
  },
  listAdminsPageNumber: 0,
  listAdminsPageSize: 50,
  listAdminsRowIndex: -1,
  pageInfo: null,
  recordLoaded: 0,
};

export const adminsLisAdminsSlice = createSlice({
  initialState,
  name: 'adminsListAdmins',
  reducers: {
    setFilterAdminName: (state, { payload }: PayloadAction<string>) => {
      state.filterAdminName = payload;
    },
    setFilterAdminType: (
      state,
      { payload }: PayloadAction<UserType | string>,
    ) => {
      state.filterAdminType = payload;
    },
    setFilterCountry: (
      state,
      { payload }: PayloadAction<CountryCode | string>,
    ) => {
      state.filterCountry = payload;
    },
    setIsGridReady: (state, { payload }: PayloadAction<boolean>) => {
      state.isGridReady = payload;
    },
    setListAdminsEndCursor: (state, { payload }: PayloadAction<string>) => {
      state.listAdminsEndCursor = payload;
    },
    setListAdminsFirst: (state, { payload }: PayloadAction<number | null>) => {
      state.listAdminsFirst = payload;
    },
    setListAdminsOrderBy: (state, { payload }: PayloadAction<OrderBy>) => {
      state.listAdminsOrderBy = payload;
    },
    setListAdminsPageNumber: (state, { payload }: PayloadAction<number>) => {
      state.listAdminsPageNumber = payload;
    },
    setListAdminsPageSize: (state, { payload }: PayloadAction<number>) => {
      state.listAdminsPageSize = payload;
    },
    setListAdminsRowIndex: (state, { payload }: PayloadAction<number>) => {
      state.listAdminsRowIndex = payload;
    },
    setPageInfo: (state, { payload }: PayloadAction<PageInfo>) => {
      state.pageInfo = payload;
    },
    setRecordLoaded: (state, { payload }: PayloadAction<number>) => {
      state.recordLoaded = payload;
    },
  },
});

export default adminsLisAdminsSlice.reducer;

export const {
  setFilterAdminName: setFilterAdminNameAction,
  setFilterAdminType: setFilterAdminTypeAction,
  setFilterCountry: setFilterCountryAction,
  setIsGridReady: setIsGridReadyAction,
  setListAdminsEndCursor: setListAdminsEndCursorAction,
  setListAdminsFirst: setListAdminsFirstAction,
  setListAdminsOrderBy: setListAdminsOrderByAction,
  setListAdminsPageNumber: setListAdminsPageNumberAction,
  setListAdminsPageSize: setListAdminsPageSizeAction,
  setListAdminsRowIndex: setListAdminsRowIndexAction,
  setPageInfo: setPageInfoAction,
  setRecordLoaded: setRecordLoadedAction,
} = adminsLisAdminsSlice.actions;
