import {
  AdminBooking,
  AdminBookingCheckout,
  AdminBookingCoach,
  CoachAvailabilityCalendarItems,
  CoachClubhouse,
  FacilityAvailabilityCalendarItems,
  FacilityType,
  GameType,
  NonPuttingFacilityDayItem,
  PuttingFacilityDayItem,
  SlotItem,
} from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SelectedMember = {
  isGroupBookingAllowed: boolean;
  memberIndividualId: string;
  memberName: string;
  memberUuid: string;
  membershipUuid: string;
};

export type IndoorPartner = {
  memberIndividualId: string;
  memberName: string;
  memberUuid: string;
};

type Mode = 'lessonIndoor' | 'lessonOutdoor' | 'game' | 'practise';

export type FacilityMode =
  | 'hour_1'
  | 'hour_2'
  | 'hour_3'
  | 'hour_4'
  | 'hour_5'
  | 'putting'
  | 'putting_30'
  | 'putting_60'
  | 'trial';

type Clubhouse = {
  code: string;
  displayName: string;
};

export interface BookingQuickLook extends AdminBooking {
  isShowModal: boolean;
}

export const initialIndoorCoachDayItem = {
  bi08: '',
  bi09: '',
  bi10: '',
  bi11: '',
  bi12: '',
  bi13: '',
  bi14: '',
  bi15: '',
  bi16: '',
  bi17: '',
  bi18: '',
  bi19: '',
  bi20: '',
  bi21: '',
  bs08: '',
  bs09: '',
  bs10: '',
  bs11: '',
  bs12: '',
  bs13: '',
  bs14: '',
  bs15: '',
  bs16: '',
  bs17: '',
  bs18: '',
  bs19: '',
  bs20: '',
  bs21: '',
  l08: '',
  l09: '',
  l10: '',
  l11: '',
  l12: '',
  l13: '',
  l14: '',
  l15: '',
  l16: '',
  l17: '',
  l18: '',
  l19: '',
  l20: '',
  l21: '',
};

export const initialPuttingDayItem: PuttingFacilityDayItem = {
  na0800: false,
  na0830: false,
  na0900: false,
  na0930: false,
  na1000: false,
  na1030: false,
  na1100: false,
  na1130: false,
  na1200: false,
  na1230: false,
  na1300: false,
  na1330: false,
  na1400: false,
  na1430: false,
  na1500: false,
  na1530: false,
  na1600: false,
  na1630: false,
  na1700: false,
  na1730: false,
  na1800: false,
  na1830: false,
  na1900: false,
  na1930: false,
  na2000: false,
  na2030: false,
};

export const initialNonPuttingDayItem: NonPuttingFacilityDayItem = {
  na0800: false,
  na0900: false,
  na1000: false,
  na1100: false,
  na1200: false,
  na1300: false,
  na1400: false,
  na1500: false,
  na1600: false,
  na1700: false,
  na1800: false,
  na1900: false,
  na2000: false,
};

export interface IndoorCoachDayItem {
  bi08: string;
  bi09: string;
  bi10: string;
  bi11: string;
  bi12: string;
  bi13: string;
  bi14: string;
  bi15: string;
  bi16: string;
  bi17: string;
  bi18: string;
  bi19: string;
  bi20: string;
  bi21: string;
  bs08: string;
  bs09: string;
  bs10: string;
  bs11: string;
  bs12: string;
  bs13: string;
  bs14: string;
  bs15: string;
  bs16: string;
  bs17: string;
  bs18: string;
  bs19: string;
  bs20: string;
  bs21: string;
  l08: string;
  l09: string;
  l10: string;
  l11: string;
  l12: string;
  l13: string;
  l14: string;
  l15: string;
  l16: string;
  l17: string;
  l18: string;
  l19: string;
  l20: string;
  l21: string;
}

export interface SelectedFacility {
  code: string;
  name: string;
  type: FacilityType;
}

type PractiseDayItem = {
  nonPutting: NonPuttingFacilityDayItem;
  putting: PuttingFacilityDayItem;
};

interface States {
  blockedClubhousesDay: string[];
  blockedClubhousesHours: string[];
  bookingQuickLook: BookingQuickLook | null;
  checkout: AdminBookingCheckout | null;
  clubhouses: CoachClubhouse[];
  coaches: AdminBookingCoach[];
  gameClubhouse: Clubhouse | null;
  gameCommentAdminToMember: string;
  gameCommentInternal: string;
  gameCurrentDate: string;
  gameDate: string;
  gameDayItem: NonPuttingFacilityDayItem;
  gameDuration: string;
  gameFacilityAvailabilityCalendarItems: FacilityAvailabilityCalendarItems[];
  gameFacilityMode: FacilityMode;
  gameHr1: SlotItem | null;
  gameHr2: SlotItem | null;
  gameHr3: SlotItem | null;
  gameHr4: SlotItem | null;
  gameHr5: SlotItem | null;
  gameNoOfPlayers: number;
  gameNotificationMessage: string;
  gameNotifyByApp: boolean;
  gameNotifyByEmail: boolean;
  gameNotifyBySms: boolean;
  gameSelectedFacility: SelectedFacility | null;
  gameTime: string;
  gameType: GameType | null;
  indoorClubhouse: Clubhouse | null;
  indoorCoach: AdminBookingCoach | null;
  indoorCoachAvailabilityCalendarItems: CoachAvailabilityCalendarItems[];
  indoorCoachDayItem: IndoorCoachDayItem;
  indoorCommentAdminToCoach: string;
  indoorCommentAdminToMember: string;
  indoorCommentInternal: string;
  indoorCurrentDate: string;
  indoorDate: string;
  indoorDuration: string;
  indoorFacilityMode: FacilityMode;
  indoorHr1: SlotItem | null;
  indoorHr2: SlotItem | null;
  indoorNotificationMessage: string;
  indoorNotifyByApp: boolean;
  indoorNotifyByEmail: boolean;
  indoorNotifyBySms: boolean;
  indoorPartner: IndoorPartner | null;
  indoorPartnerFacilityCode: string | null;
  indoorSelectedFacility: SelectedFacility | null;
  indoorTime: string;
  indoorTimeForced: string;
  isShowCoachListIndoor: boolean;
  isShowCoachListOutdoor: boolean;
  isShowGameCalendarSlotPanel: boolean;
  isShowGameCommentAndNotification: boolean;
  isShowGameFacilityList: boolean;
  isShowIndoorCalendarSlotPanel: boolean;
  isShowIndoorCommentAndNotification: boolean;
  isShowIndoorFacilityList: boolean;
  isShowModalGame: boolean;
  isShowModalIndoor: boolean;
  isShowModalOutdoor: boolean;
  isShowModalPractise: boolean;
  isShowModalSelectGroupMember: boolean;
  isShowModalSelectGuestMember: boolean;
  isShowOutdoorCalendarVenueDetailPanel: boolean;
  isShowOutdoorCommentAndNotification: boolean;
  isShowPractiseCalendarSlotPanel: boolean;
  isShowPractiseCommentAndNotification: boolean;
  isShowPractiseFacilityList: boolean;
  mode: Mode;
  outdoorCoach: AdminBookingCoach | null;
  outdoorCoachAvailabilityCalendarItems: CoachAvailabilityCalendarItems[];
  outdoorCommentAdminToCoach: string;
  outdoorCommentAdminToMember: string;
  outdoorCommentInternal: string;
  outdoorCurrentDate: string;
  outdoorDate: string;
  outdoorLessonDetails: string;
  outdoorLessonVenue: string;
  outdoorNotificationMessage: string;
  outdoorNotifyByApp: boolean;
  outdoorNotifyByEmail: boolean;
  outdoorNotifyBySms: boolean;
  practiseClubhouse: Clubhouse | null;
  practiseCommentAdminToMember: string;
  practiseCommentInternal: string;
  practiseCurrentDate: string;
  practiseDate: string;
  practiseDayItem: PractiseDayItem;
  practiseDuration: string;
  practiseFacilityAvailabilityCalendarItems: FacilityAvailabilityCalendarItems[];
  practiseFacilityMode: FacilityMode;
  practiseHr1: SlotItem | null;
  practiseHr2: SlotItem | null;
  practiseHr3: SlotItem | null;
  practiseHr4: SlotItem | null;
  practiseHr5: SlotItem | null;
  practiseIsPutting: boolean;
  practiseNotificationMessage: string;
  practiseNotifyByApp: boolean;
  practiseNotifyByEmail: boolean;
  practiseNotifyBySms: boolean;
  practisePutting30: SlotItem | null;
  practisePutting60: SlotItem | null;
  practiseSelectedFacility: SelectedFacility | null;
  practiseTime: string;
  refetchCalendarItemsCoachId: string;
  refetchDayItemsDateId: string;
  selectedMember: SelectedMember;
  tabIndex: number;
  teeTime: string;
}

const initialState: States = {
  blockedClubhousesDay: [],
  blockedClubhousesHours: [],
  bookingQuickLook: null,
  checkout: null,
  clubhouses: [],
  coaches: [],
  gameClubhouse: null,
  gameCommentAdminToMember: '',
  gameCommentInternal: '',
  gameCurrentDate: new Date().toDateString(),
  gameDate: '',
  gameDayItem: initialNonPuttingDayItem,
  gameDuration: '1 hour',
  gameFacilityAvailabilityCalendarItems: [],
  gameFacilityMode: 'hour_1',
  gameHr1: null,
  gameHr2: null,
  gameHr3: null,
  gameHr4: null,
  gameHr5: null,
  gameNoOfPlayers: 1,
  gameNotificationMessage: '',
  gameNotifyByApp: false,
  gameNotifyByEmail: false,
  gameNotifyBySms: false,
  gameSelectedFacility: null,
  gameTime: '',
  gameType: GameType.Hole_9,
  indoorClubhouse: null,
  indoorCoach: null,
  indoorCoachAvailabilityCalendarItems: [],
  indoorCoachDayItem: initialIndoorCoachDayItem,
  indoorCommentAdminToCoach: '',
  indoorCommentAdminToMember: '',
  indoorCommentInternal: '',
  indoorCurrentDate: new Date().toDateString(),
  indoorDate: '',
  indoorDuration: '1 hour',
  indoorFacilityMode: 'hour_1',
  indoorHr1: null,
  indoorHr2: null,
  indoorNotificationMessage: '',
  indoorNotifyByApp: false,
  indoorNotifyByEmail: false,
  indoorNotifyBySms: false,
  indoorPartner: null,
  indoorPartnerFacilityCode: null,
  indoorSelectedFacility: null,
  indoorTime: '',
  indoorTimeForced: '',
  isShowCoachListIndoor: false,
  isShowCoachListOutdoor: false,
  isShowGameCalendarSlotPanel: false,
  isShowGameCommentAndNotification: false,
  isShowGameFacilityList: false,
  isShowIndoorCalendarSlotPanel: false,
  isShowIndoorCommentAndNotification: false,
  isShowIndoorFacilityList: false,
  isShowModalGame: false,
  isShowModalIndoor: false,
  isShowModalOutdoor: false,
  isShowModalPractise: false,
  isShowModalSelectGroupMember: false,
  isShowModalSelectGuestMember: false,
  isShowOutdoorCalendarVenueDetailPanel: true,
  isShowOutdoorCommentAndNotification: false,
  isShowPractiseCalendarSlotPanel: false,
  isShowPractiseCommentAndNotification: false,
  isShowPractiseFacilityList: false,
  mode: 'lessonIndoor',
  outdoorCoach: null,
  outdoorCoachAvailabilityCalendarItems: [],
  outdoorCommentAdminToCoach: '',
  outdoorCommentAdminToMember: '',
  outdoorCommentInternal: '',
  outdoorCurrentDate: new Date().toDateString(),
  outdoorDate: '',
  outdoorLessonDetails: '',
  outdoorLessonVenue: '',
  outdoorNotificationMessage: '',
  outdoorNotifyByApp: false,
  outdoorNotifyByEmail: false,
  outdoorNotifyBySms: false,
  practiseClubhouse: null,
  practiseCommentAdminToMember: '',
  practiseCommentInternal: '',
  practiseCurrentDate: new Date().toDateString(),
  practiseDate: '',
  practiseDayItem: {
    nonPutting: initialNonPuttingDayItem,
    putting: initialPuttingDayItem,
  },
  practiseDuration: '1 hour',
  practiseFacilityAvailabilityCalendarItems: [],
  practiseFacilityMode: 'hour_1',
  practiseHr1: null,
  practiseHr2: null,
  practiseHr3: null,
  practiseHr4: null,
  practiseHr5: null,
  practiseIsPutting: false,
  practiseNotificationMessage: '',
  practiseNotifyByApp: false,
  practiseNotifyByEmail: false,
  practiseNotifyBySms: false,
  practisePutting30: null,
  practisePutting60: null,
  practiseSelectedFacility: null,
  practiseTime: '',
  refetchCalendarItemsCoachId: '',
  refetchDayItemsDateId: '',
  selectedMember: {
    isGroupBookingAllowed: false,
    memberIndividualId: '',
    memberName: '',
    memberUuid: '',
    membershipUuid: '',
  },
  tabIndex: 0,
  teeTime: '',
};

interface SetClubhousePayload {
  clubhouseCode: string;
  time: string;
}

interface SetCoachSlotBookingPayload {
  bookingDescription: string;
  bookingId: string;
  time: string;
}

export const bookingsNewBookingSlice = createSlice({
  initialState,
  name: 'bookingsNewBooking',
  reducers: {
    deleteIndoorPartner: (state) => {
      state.indoorPartner = null;
      state.indoorPartnerFacilityCode = null;
    },
    resetBooking: (state) => {
      state.selectedMember = {
        isGroupBookingAllowed: false,
        memberIndividualId: '',
        memberName: '',
        memberUuid: '',
        membershipUuid: '',
      };
      state.checkout = null;

      state.indoorClubhouse = null;
      state.indoorCoach = null;
      state.indoorCommentAdminToCoach = '';
      state.indoorCommentAdminToMember = '';
      state.indoorCommentInternal = '';
      state.indoorDate = '';
      state.indoorFacilityMode = 'hour_1';
      state.indoorHr1 = null;
      state.indoorHr2 = null;
      state.indoorNotificationMessage = '';
      state.indoorNotifyByApp = false;
      state.indoorNotifyByEmail = false;
      state.indoorNotifyBySms = false;
      state.indoorPartner = null;
      state.indoorPartnerFacilityCode = null;
      state.indoorSelectedFacility = null;
      state.indoorTime = '';
      state.isShowCoachListIndoor = false;
      state.isShowIndoorCalendarSlotPanel = false;
      state.isShowIndoorCommentAndNotification = false;
      state.isShowIndoorFacilityList = false;
      state.isShowModalIndoor = false;

      state.isShowModalOutdoor = false;
      state.isShowOutdoorCalendarVenueDetailPanel = false;
      state.isShowOutdoorCommentAndNotification = false;
      state.outdoorCoach = null;
      state.outdoorCommentAdminToCoach = '';
      state.outdoorCommentAdminToMember = '';
      state.outdoorCommentInternal = '';
      state.outdoorDate = '';
      state.outdoorLessonDetails = '';
      state.outdoorLessonVenue = '';
      state.outdoorNotificationMessage = '';
      state.outdoorNotifyByApp = false;
      state.outdoorNotifyByEmail = false;
      state.outdoorNotifyBySms = false;

      state.gameClubhouse = null;
      state.gameCommentAdminToMember = '';
      state.gameCommentInternal = '';
      state.gameDate = '';
      state.gameFacilityMode = 'hour_1';
      state.gameHr1 = null;
      state.gameHr2 = null;
      state.gameHr3 = null;
      state.gameHr4 = null;
      state.gameHr5 = null;
      state.gameNoOfPlayers = 1;
      state.gameNotificationMessage = '';
      state.gameNotifyByApp = false;
      state.gameNotifyByEmail = false;
      state.gameNotifyBySms = false;
      state.gameSelectedFacility = null;
      state.gameTime = '';
      state.gameType = null;
      state.isShowGameCalendarSlotPanel = false;
      state.isShowGameCommentAndNotification = false;
      state.isShowGameFacilityList = false;
      state.isShowModalGame = false;

      state.isShowModalPractise = false;
      state.isShowPractiseCalendarSlotPanel = false;
      state.isShowPractiseCommentAndNotification = false;
      state.isShowPractiseFacilityList = false;
      state.practiseClubhouse = null;
      state.practiseCommentAdminToMember = '';
      state.practiseCommentInternal = '';
      state.practiseDate = '';
      state.practiseFacilityMode = 'hour_1';
      state.practiseHr1 = null;
      state.practiseHr2 = null;
      state.practiseHr3 = null;
      state.practiseHr4 = null;
      state.practiseHr5 = null;
      state.practiseNotificationMessage = '';
      state.practiseNotifyByApp = false;
      state.practiseNotifyByEmail = false;
      state.practiseNotifyBySms = false;
      state.practisePutting30 = null;
      state.practisePutting60 = null;
      state.practiseSelectedFacility = null;
      state.practiseTime = '';
    },
    resetBookingGame: (state) => {
      state.checkout = null;
      state.gameClubhouse = null;
      state.gameCommentAdminToMember = '';
      state.gameCommentInternal = '';
      state.gameDate = '';
      state.gameFacilityMode = 'hour_1';
      state.gameHr1 = null;
      state.gameHr2 = null;
      state.gameHr3 = null;
      state.gameHr4 = null;
      state.gameHr5 = null;
      state.gameNoOfPlayers = 1;
      state.gameNotificationMessage = '';
      state.gameNotifyByApp = false;
      state.gameNotifyByEmail = false;
      state.gameNotifyBySms = false;
      state.gameSelectedFacility = null;
      state.gameTime = '';
      state.gameType = null;
      state.isShowGameCalendarSlotPanel = false;
      state.isShowGameCommentAndNotification = false;
      state.isShowGameFacilityList = false;
      state.isShowModalGame = false;
      window.scrollTo(0, 0);
    },
    resetBookingIndoor: (state) => {
      state.checkout = null;
      state.indoorClubhouse = null;
      state.indoorCoach = null;
      state.indoorCommentAdminToCoach = '';
      state.indoorCommentAdminToMember = '';
      state.indoorCommentInternal = '';
      state.indoorDate = '';
      state.indoorFacilityMode = 'hour_1';
      state.indoorHr1 = null;
      state.indoorHr2 = null;
      state.indoorNotificationMessage = '';
      state.indoorNotifyByApp = false;
      state.indoorNotifyByEmail = false;
      state.indoorNotifyBySms = false;
      state.indoorPartner = null;
      state.indoorPartnerFacilityCode = null;
      state.indoorSelectedFacility = null;
      state.indoorTime = '';
      state.isShowCoachListIndoor = false;
      state.isShowIndoorCalendarSlotPanel = false;
      state.isShowIndoorCommentAndNotification = false;
      state.isShowIndoorFacilityList = false;
      state.isShowModalIndoor = false;
      window.scrollTo(0, 0);
    },
    resetBookingOutdoor: (state) => {
      state.checkout = null;
      state.isShowCoachListIndoor = false;
      state.isShowModalOutdoor = false;
      state.isShowOutdoorCalendarVenueDetailPanel = false;
      state.isShowOutdoorCommentAndNotification = false;
      state.outdoorCoach = null;
      state.outdoorCommentAdminToCoach = '';
      state.outdoorCommentAdminToMember = '';
      state.outdoorCommentInternal = '';
      state.outdoorDate = '';
      state.outdoorLessonDetails = '';
      state.outdoorLessonVenue = '';
      state.outdoorNotificationMessage = '';
      state.outdoorNotifyByApp = false;
      state.outdoorNotifyByEmail = false;
      state.outdoorNotifyBySms = false;
      state.teeTime = '';
      window.scrollTo(0, 0);
    },
    resetBookingPractise: (state) => {
      state.checkout = null;
      state.isShowModalPractise = false;
      state.isShowPractiseCalendarSlotPanel = false;
      state.isShowPractiseCommentAndNotification = false;
      state.isShowPractiseFacilityList = false;
      state.practiseClubhouse = null;
      state.practiseCommentAdminToMember = '';
      state.practiseCommentInternal = '';
      state.practiseDate = '';
      state.practiseFacilityMode = 'hour_1';
      state.practiseHr1 = null;
      state.practiseHr2 = null;
      state.practiseHr3 = null;
      state.practiseHr4 = null;
      state.practiseHr5 = null;
      state.practiseNotificationMessage = '';
      state.practiseNotifyByApp = false;
      state.practiseNotifyByEmail = false;
      state.practiseNotifyBySms = false;
      state.practisePutting30 = null;
      state.practisePutting60 = null;
      state.practiseSelectedFacility = null;
      state.practiseTime = '';
      window.scrollTo(0, 0);
    },
    setBlockedClubhousesDay: (state, { payload }: PayloadAction<string[]>) => {
      state.blockedClubhousesDay = payload;
    },
    setBlockedClubhousesHours: (
      state,
      { payload }: PayloadAction<string[]>,
    ) => {
      state.blockedClubhousesHours = payload;
    },
    setBookingQuickLook: (
      state,
      { payload }: PayloadAction<BookingQuickLook | null>,
    ) => {
      state.bookingQuickLook = payload;
    },
    setCheckout: (
      state,
      { payload }: PayloadAction<AdminBookingCheckout | null>,
    ) => {
      state.checkout = payload;
    },
    setClubhouses: (state, { payload }: PayloadAction<CoachClubhouse[]>) => {
      state.clubhouses = payload;
    },
    setCoachSlotItemBooking: (
      state,
      { payload }: PayloadAction<SetCoachSlotBookingPayload>,
    ) => {
      const { bookingDescription, bookingId, time } = payload;
      const dAccessor = `bs${time}` as keyof IndoorCoachDayItem;
      state.indoorCoachDayItem[dAccessor] = bookingDescription;
      const iAccessor = `bi${time}` as keyof IndoorCoachDayItem;
      state.indoorCoachDayItem[iAccessor] = bookingId;
    },
    setCoachSlotItemClubhouse: (
      state,
      { payload }: PayloadAction<SetClubhousePayload>,
    ) => {
      const { clubhouseCode, time } = payload;
      const accessor = `l${time}` as keyof IndoorCoachDayItem;
      state.indoorCoachDayItem[accessor] = clubhouseCode;
    },
    setCoaches: (state, { payload }: PayloadAction<AdminBookingCoach[]>) => {
      state.coaches = payload;
    },
    setGameClubhouse: (state, { payload }: PayloadAction<Clubhouse>) => {
      state.gameClubhouse = payload;
    },
    setGameCommentAdminToMember: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.gameCommentAdminToMember = payload;
    },
    setGameCommentInternal: (state, { payload }: PayloadAction<string>) => {
      state.gameCommentInternal = payload;
    },
    setGameCurrentDate: (state, { payload }: PayloadAction<string>) => {
      state.gameCurrentDate = payload;
    },
    setGameDate: (state, { payload }: PayloadAction<string>) => {
      state.gameDate = payload;
    },
    setGameDayItem: (
      state,
      { payload }: PayloadAction<NonPuttingFacilityDayItem>,
    ) => {
      state.gameDayItem = payload;
    },
    setGameDuration: (state, { payload }: PayloadAction<string>) => {
      state.gameDuration = payload;
    },
    setGameFacilityAvailabilityCalendarItems: (
      state,
      { payload }: PayloadAction<FacilityAvailabilityCalendarItems[]>,
    ) => {
      state.gameFacilityAvailabilityCalendarItems = payload;
    },
    setGameFacilityMode: (state, { payload }: PayloadAction<FacilityMode>) => {
      state.gameFacilityMode = payload;
    },
    setGameHr1: (state, { payload }: PayloadAction<SlotItem | null>) => {
      state.gameHr1 = payload;
    },
    setGameHr2: (state, { payload }: PayloadAction<SlotItem | null>) => {
      state.gameHr2 = payload;
    },
    setGameHr3: (state, { payload }: PayloadAction<SlotItem | null>) => {
      state.gameHr3 = payload;
    },
    setGameHr4: (state, { payload }: PayloadAction<SlotItem | null>) => {
      state.gameHr4 = payload;
    },
    setGameHr5: (state, { payload }: PayloadAction<SlotItem | null>) => {
      state.gameHr5 = payload;
    },
    setGameNoOfPlayers: (state, { payload }: PayloadAction<number>) => {
      state.gameNoOfPlayers = payload;
    },
    setGameNotificationMessage: (state, { payload }: PayloadAction<string>) => {
      state.gameNotificationMessage = payload;
    },
    setGameNotifyByApp: (state, { payload }: PayloadAction<boolean>) => {
      state.gameNotifyByApp = payload;
    },
    setGameNotifyByEmail: (state, { payload }: PayloadAction<boolean>) => {
      state.gameNotifyByEmail = payload;
    },
    setGameNotifyBySms: (state, { payload }: PayloadAction<boolean>) => {
      state.gameNotifyBySms = payload;
    },
    setGameSelectedFacility: (
      state,
      { payload }: PayloadAction<SelectedFacility | null>,
    ) => {
      state.gameSelectedFacility = payload;
    },
    setGameTime: (state, { payload }: PayloadAction<string>) => {
      state.gameTime = payload;
    },
    setGameType: (state, { payload }: PayloadAction<GameType>) => {
      state.gameType = payload;
    },
    setIndoorClubhouse: (
      state,
      { payload }: PayloadAction<Clubhouse | null>,
    ) => {
      state.indoorClubhouse = payload;
    },
    setIndoorCoach: (
      state,
      { payload }: PayloadAction<AdminBookingCoach | null>,
    ) => {
      state.indoorCoach = payload;
    },
    setIndoorCoachAvailabilityCalendarItems: (
      state,
      { payload }: PayloadAction<CoachAvailabilityCalendarItems[]>,
    ) => {
      state.indoorCoachAvailabilityCalendarItems = payload;
    },
    setIndoorCoachDayItem: (
      state,
      { payload }: PayloadAction<IndoorCoachDayItem>,
    ) => {
      state.indoorCoachDayItem = payload;
    },
    setIndoorCommentAdminToCoach: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.indoorCommentAdminToCoach = payload;
    },
    setIndoorCommentAdminToMember: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.indoorCommentAdminToMember = payload;
    },
    setIndoorCommentInternal: (state, { payload }: PayloadAction<string>) => {
      state.indoorCommentInternal = payload;
    },
    setIndoorCurrentDate: (state, { payload }: PayloadAction<string>) => {
      state.indoorCurrentDate = payload;
    },
    setIndoorDate: (state, { payload }: PayloadAction<string>) => {
      state.indoorDate = payload;
    },
    setIndoorDuration: (state, { payload }: PayloadAction<string>) => {
      state.indoorDuration = payload;
    },
    setIndoorFacilityMode: (
      state,
      { payload }: PayloadAction<FacilityMode>,
    ) => {
      state.indoorFacilityMode = payload;
    },
    setIndoorHr1: (state, { payload }: PayloadAction<SlotItem | null>) => {
      state.indoorHr1 = payload;
    },
    setIndoorHr2: (state, { payload }: PayloadAction<SlotItem | null>) => {
      state.indoorHr2 = payload;
    },
    setIndoorNotificationMessage: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.indoorNotificationMessage = payload;
    },
    setIndoorNotifyByApp: (state, { payload }: PayloadAction<boolean>) => {
      state.indoorNotifyByApp = payload;
    },
    setIndoorNotifyByEmail: (state, { payload }: PayloadAction<boolean>) => {
      state.indoorNotifyByEmail = payload;
    },
    setIndoorNotifyBySms: (state, { payload }: PayloadAction<boolean>) => {
      state.indoorNotifyBySms = payload;
    },
    setIndoorPartner: (state, { payload }: PayloadAction<IndoorPartner>) => {
      state.indoorPartner = payload;
    },
    setIndoorPartnerFacilityCode: (
      state,
      { payload }: PayloadAction<string | null>,
    ) => {
      state.indoorPartnerFacilityCode = payload;
    },
    setIndoorSelectedFacility: (
      state,
      { payload }: PayloadAction<SelectedFacility | null>,
    ) => {
      state.indoorSelectedFacility = payload;
    },
    setIndoorTime: (state, { payload }: PayloadAction<string>) => {
      state.indoorTime = payload;
    },
    setIndoorTimeForced: (state, { payload }: PayloadAction<string>) => {
      state.indoorTimeForced = payload;
    },
    setIsShowCoachListIndoor: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowCoachListIndoor = payload;
    },
    setIsShowCoachListOutdoor: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowCoachListOutdoor = payload;
    },
    setIsShowGameCalendarSlotPanel: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isShowGameCalendarSlotPanel = payload;
    },
    setIsShowGameCommentAndNotification: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isShowGameCommentAndNotification = payload;
    },
    setIsShowGameFacilityList: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowGameFacilityList = payload;
    },
    setIsShowIndoorCalendarSlotPanel: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isShowIndoorCalendarSlotPanel = payload;
    },
    setIsShowIndoorCommentAndNotification: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isShowIndoorCommentAndNotification = payload;
    },
    setIsShowIndoorFacilityList: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isShowIndoorFacilityList = payload;
    },
    setIsShowModalGame: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowModalGame = payload;
    },
    setIsShowModalIndoor: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowModalIndoor = payload;
    },
    setIsShowModalOutdoor: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowModalOutdoor = payload;
    },
    setIsShowModalPractise: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowModalPractise = payload;
    },
    setIsShowModalSelectGroupMember: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isShowModalSelectGroupMember = payload;
    },
    setIsShowModalSelectGuestMember: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isShowModalSelectGuestMember = payload;
    },
    setIsShowOutdoorCalendarVenueDetailPanel: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isShowOutdoorCalendarVenueDetailPanel = payload;
    },
    setIsShowOutdoorCommentAndNotification: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isShowOutdoorCommentAndNotification = payload;
    },
    setIsShowPractiseCalendarSlotPanel: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isShowPractiseCalendarSlotPanel = payload;
    },
    setIsShowPractiseCommentAndNotification: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isShowPractiseCommentAndNotification = payload;
    },
    setIsShowPractiseFacilityList: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isShowPractiseFacilityList = payload;
    },
    setMode: (state, { payload }: PayloadAction<Mode>) => {
      state.mode = payload;
    },
    setOutdoorCoach: (
      state,
      { payload }: PayloadAction<AdminBookingCoach | null>,
    ) => {
      state.outdoorCoach = payload;
    },
    setOutdoorCoachAvailabilityCalendarItems: (
      state,
      { payload }: PayloadAction<CoachAvailabilityCalendarItems[]>,
    ) => {
      state.outdoorCoachAvailabilityCalendarItems = payload;
    },
    setOutdoorCommentAdminToCoach: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.outdoorCommentAdminToCoach = payload;
    },
    setOutdoorCommentAdminToMember: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.outdoorCommentAdminToMember = payload;
    },
    setOutdoorCommentInternal: (state, { payload }: PayloadAction<string>) => {
      state.outdoorCommentInternal = payload;
    },
    setOutdoorCurrentDate: (state, { payload }: PayloadAction<string>) => {
      state.outdoorCurrentDate = payload;
    },
    setOutdoorDate: (state, { payload }: PayloadAction<string>) => {
      state.outdoorDate = payload;
    },
    setOutdoorLessonDetails: (state, { payload }: PayloadAction<string>) => {
      state.outdoorLessonDetails = payload;
    },
    setOutdoorLessonVenue: (state, { payload }: PayloadAction<string>) => {
      state.outdoorLessonVenue = payload;
    },
    setOutdoorNotificationMessage: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.outdoorNotificationMessage = payload;
    },
    setOutdoorNotifyByApp: (state, { payload }: PayloadAction<boolean>) => {
      state.outdoorNotifyByApp = payload;
    },
    setOutdoorNotifyByEmail: (state, { payload }: PayloadAction<boolean>) => {
      state.outdoorNotifyByEmail = payload;
    },
    setOutdoorNotifyBySms: (state, { payload }: PayloadAction<boolean>) => {
      state.outdoorNotifyBySms = payload;
    },
    setPractiseClubhouse: (state, { payload }: PayloadAction<Clubhouse>) => {
      state.practiseClubhouse = payload;
    },
    setPractiseCommentAdminToMember: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.practiseCommentAdminToMember = payload;
    },
    setPractiseCommentInternal: (state, { payload }: PayloadAction<string>) => {
      state.practiseCommentInternal = payload;
    },
    setPractiseCurrentDate: (state, { payload }: PayloadAction<string>) => {
      state.practiseCurrentDate = payload;
    },
    setPractiseDate: (state, { payload }: PayloadAction<string>) => {
      state.practiseDate = payload;
    },
    setPractiseDayItem: (
      state,
      { payload }: PayloadAction<PractiseDayItem>,
    ) => {
      state.practiseDayItem = payload;
    },
    setPractiseDuration: (state, { payload }: PayloadAction<string>) => {
      state.practiseDuration = payload;
    },
    setPractiseFacilityAvailabilityCalendarItems: (
      state,
      { payload }: PayloadAction<FacilityAvailabilityCalendarItems[]>,
    ) => {
      state.practiseFacilityAvailabilityCalendarItems = payload;
    },
    setPractiseFacilityMode: (
      state,
      { payload }: PayloadAction<FacilityMode>,
    ) => {
      state.practiseFacilityMode = payload;
    },
    setPractiseHr1: (state, { payload }: PayloadAction<SlotItem | null>) => {
      state.practiseHr1 = payload;
    },
    setPractiseHr2: (state, { payload }: PayloadAction<SlotItem | null>) => {
      state.practiseHr2 = payload;
    },
    setPractiseHr3: (state, { payload }: PayloadAction<SlotItem | null>) => {
      state.practiseHr3 = payload;
    },
    setPractiseHr4: (state, { payload }: PayloadAction<SlotItem | null>) => {
      state.practiseHr4 = payload;
    },
    setPractiseHr5: (state, { payload }: PayloadAction<SlotItem | null>) => {
      state.practiseHr5 = payload;
    },
    setPractiseIsPutting: (state, { payload }: PayloadAction<boolean>) => {
      state.practiseIsPutting = payload;
    },
    setPractiseNotificationMessage: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.practiseNotificationMessage = payload;
    },
    setPractiseNotifyByApp: (state, { payload }: PayloadAction<boolean>) => {
      state.practiseNotifyByApp = payload;
    },
    setPractiseNotifyByEmail: (state, { payload }: PayloadAction<boolean>) => {
      state.practiseNotifyByEmail = payload;
    },
    setPractiseNotifyBySms: (state, { payload }: PayloadAction<boolean>) => {
      state.practiseNotifyBySms = payload;
    },
    setPractisePutting30: (
      state,
      { payload }: PayloadAction<SlotItem | null>,
    ) => {
      state.practisePutting30 = payload;
    },
    setPractisePutting60: (
      state,
      { payload }: PayloadAction<SlotItem | null>,
    ) => {
      state.practisePutting60 = payload;
    },
    setPractiseSelectedFacility: (
      state,
      { payload }: PayloadAction<SelectedFacility | null>,
    ) => {
      state.practiseSelectedFacility = payload;
    },
    setPractiseTime: (state, { payload }: PayloadAction<string>) => {
      state.practiseTime = payload;
    },
    setRefetchCalendarItemsCoachId: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.refetchCalendarItemsCoachId = payload;
    },
    setRefetchDayItemsDateId: (state, { payload }: PayloadAction<string>) => {
      state.refetchDayItemsDateId = payload;
    },
    setSelectedMember: (state, { payload }: PayloadAction<SelectedMember>) => {
      state.selectedMember = payload;
    },
    setTabIndex: (state, { payload }: PayloadAction<number>) => {
      state.tabIndex = payload;
    },
    setTeeTime: (state, { payload }: PayloadAction<string>) => {
      state.teeTime = payload;
    },
  },
});

export default bookingsNewBookingSlice.reducer;

export const {
  deleteIndoorPartner: deleteIndoorPartnerAction,
  resetBooking: resetBookingAction,
  resetBookingGame: resetBookingGameAction,
  resetBookingIndoor: resetBookingIndoorAction,
  resetBookingOutdoor: resetBookingOutdoorAction,
  resetBookingPractise: resetBookingPractiseAction,
  setBlockedClubhousesDay: setBlockedClubhousesDayAction,
  setBlockedClubhousesHours: setBlockedClubhousesHoursAction,
  setBookingQuickLook: setBookingQuickLookAction,
  setCheckout: setCheckoutAction,
  setClubhouses: setClubhousesAction,
  setCoaches: setCoachesAction,
  setCoachSlotItemBooking: setCoachSlotItemBookingAction,
  setCoachSlotItemClubhouse: setCoachSlotItemClubhouseAction,
  setGameClubhouse: setGameClubhouseAction,
  setGameCommentAdminToMember: setGameCommentAdminToMemberAction,
  setGameCommentInternal: setGameCommentInternalAction,
  setGameCurrentDate: setGameCurrentDateAction,
  setGameDate: setGameDateAction,
  setGameDayItem: setGameDayItemAction,
  setGameDuration: setGameDurationAction,
  setGameFacilityAvailabilityCalendarItems:
    setGameFacilityAvailabilityCalendarItemsAction,
  setGameFacilityMode: setGameFacilityModeAction,
  setGameHr1: setGameHr1Action,
  setGameHr2: setGameHr2Action,
  setGameHr3: setGameHr3Action,
  setGameHr4: setGameHr4Action,
  setGameHr5: setGameHr5Action,
  setGameNoOfPlayers: setGameNoOfPlayersAction,
  setGameNotificationMessage: setGameNotificationMessageAction,
  setGameNotifyByApp: setGameNotifyByAppAction,
  setGameNotifyByEmail: setGameNotifyByEmailAction,
  setGameNotifyBySms: setGameNotifyBySmsAction,
  setGameSelectedFacility: setGameSelectedFacilityAction,
  setGameTime: setGameTimeAction,
  setGameType: setGameTypeAction,
  setIndoorClubhouse: setIndoorClubhouseAction,
  setIndoorCoach: setIndoorCoachAction,
  setIndoorCoachAvailabilityCalendarItems:
    setIndoorCoachAvailabilityCalendarItemsAction,
  setIndoorCoachDayItem: setIndoorCoachDayItemAction,
  setIndoorCommentAdminToCoach: setIndoorCommentAdminToCoachAction,
  setIndoorCommentAdminToMember: setIndoorCommentAdminToMemberAction,
  setIndoorCommentInternal: setIndoorCommentInternalAction,
  setIndoorCurrentDate: setIndoorCurrentDateAction,
  setIndoorDate: setIndoorDateAction,
  setIndoorDuration: setIndoorDurationAction,
  setIndoorFacilityMode: setIndoorFacilityModeAction,
  setIndoorHr1: setIndoorHr1Action,
  setIndoorHr2: setIndoorHr2Action,
  setIndoorNotificationMessage: setIndoorNotificationMessageAction,
  setIndoorNotifyByApp: setIndoorNotifyByAppAction,
  setIndoorNotifyByEmail: setIndoorNotifyByEmailAction,
  setIndoorNotifyBySms: setIndoorNotifyBySmsAction,
  setIndoorPartner: setIndoorPartnerAction,
  setIndoorPartnerFacilityCode: setIndoorPartnerFacilityCodeAction,
  setIndoorSelectedFacility: setIndoorSelectedFacilityAction,
  setIndoorTime: setIndoorTimeAction,
  setIndoorTimeForced: setIndoorTimeForcedAction,
  setIsShowCoachListIndoor: setIsShowCoachListIndoorAction,
  setIsShowCoachListOutdoor: setIsShowCoachListOutdoorAction,
  setIsShowGameCalendarSlotPanel: setIsShowGameCalendarSlotPanelAction,
  setIsShowGameCommentAndNotification:
    setIsShowGameCommentAndNotificationAction,
  setIsShowGameFacilityList: setIsShowGameFacilityListAction,
  setIsShowIndoorCalendarSlotPanel: setIsShowIndoorCalendarSlotPanelAction,
  setIsShowIndoorCommentAndNotification:
    setIsShowIndoorCommentAndNotificationAction,
  setIsShowIndoorFacilityList: setIsShowIndoorFacilityListAction,
  setIsShowModalGame: setIsShowModalGameAction,
  setIsShowModalIndoor: setIsShowModalIndoorAction,
  setIsShowModalOutdoor: setIsShowModalOutdoorAction,
  setIsShowModalPractise: setIsShowModalPractiseAction,
  setIsShowModalSelectGroupMember: setIsShowModalSelectGroupMemberAction,
  setIsShowModalSelectGuestMember: setIsShowModalSelectGuestMemberAction,
  setIsShowOutdoorCalendarVenueDetailPanel:
    setIsShowOutdoorCalendarVenueDetailPanelAction,
  setIsShowOutdoorCommentAndNotification:
    setIsShowOutdoorCommentAndNotificationAction,
  setIsShowPractiseCalendarSlotPanel: setIsShowPractiseCalendarSlotPanelAction,
  setIsShowPractiseCommentAndNotification:
    setIsShowPractiseCommentAndNotificationAction,
  setIsShowPractiseFacilityList: setIsShowPractiseFacilityListAction,
  setMode: setModeAction,
  setOutdoorCoach: setOutdoorCoachAction,
  setOutdoorCoachAvailabilityCalendarItems:
    setOutdoorCoachAvailabilityCalendarItemsAction,
  setOutdoorCommentAdminToCoach: setOutdoorCommentAdminToCoachAction,
  setOutdoorCommentAdminToMember: setOutdoorCommentAdminToMemberAction,
  setOutdoorCommentInternal: setOutdoorCommentInternalAction,
  setOutdoorCurrentDate: setOutdoorCurrentDateAction,
  setOutdoorDate: setOutdoorDateAction,
  setOutdoorLessonDetails: setOutdoorLessonDetailsActions,
  setOutdoorLessonVenue: setOutdoorLessonVenueActions,
  setOutdoorNotificationMessage: setOutdoorNotificationMessageAction,
  setOutdoorNotifyByApp: setOutdoorNotifyByAppAction,
  setOutdoorNotifyByEmail: setOutdoorNotifyByEmailAction,
  setOutdoorNotifyBySms: setOutdoorNotifyBySmsAction,
  setPractiseClubhouse: setPractiseClubhouseAction,
  setPractiseCommentAdminToMember: setPractiseCommentAdminToMemberAction,
  setPractiseCommentInternal: setPractiseCommentInternalAction,
  setPractiseCurrentDate: setPractiseCurrentDateAction,
  setPractiseDate: setPractiseDateAction,
  setPractiseDayItem: setPractiseDayItemAction,
  setPractiseDuration: setPractiseDurationAction,
  setPractiseFacilityAvailabilityCalendarItems:
    setPractiseFacilityAvailabilityCalendarItemsAction,
  setPractiseFacilityMode: setPractiseFacilityModeAction,
  setPractiseHr1: setPractiseHr1Action,
  setPractiseHr2: setPractiseHr2Action,
  setPractiseHr3: setPractiseHr3Action,
  setPractiseHr4: setPractiseHr4Action,
  setPractiseHr5: setPractiseHr5Action,
  setPractiseIsPutting: setPractiseIsPuttingAction,
  setPractiseNotificationMessage: setPractiseNotificationMessageAction,
  setPractiseNotifyByApp: setPractiseNotifyByAppAction,
  setPractiseNotifyByEmail: setPractiseNotifyByEmailAction,
  setPractiseNotifyBySms: setPractiseNotifyBySmsAction,
  setPractisePutting30: setPractisePutting30Action,
  setPractisePutting60: setPractisePutting60Action,
  setPractiseSelectedFacility: setPractiseSelectedFacilityAction,
  setPractiseTime: setPractiseTimeAction,
  setRefetchCalendarItemsCoachId: setRefetchCalendarItemsCoachIdAction,
  setRefetchDayItemsDateId: setRefetchDayItemsDateIdAction,
  setSelectedMember: setSelectedMemberAction,
  setTabIndex: setTabIndexAction,
  setTeeTime: setTeeTimeAction,
} = bookingsNewBookingSlice.actions;
