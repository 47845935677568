import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  from,
  fromPromise,
  InMemoryCache,
  split,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import { store } from 'store';
import { v4 as uuidv4 } from 'uuid';
import { fetchRefreshToken } from './fetchRefreshToken';

type Headers = {
  authorization?: string;
};

const cache = new InMemoryCache({
  addTypename: false,
});

let isRefreshing = false;
// eslint-disable-next-line @typescript-eslint/ban-types
let pendingRequests: Function[] = [];

const setIsRefreshing = (value: boolean) => {
  isRefreshing = value;
};

const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback());
  pendingRequests = [];
};

const addPendingRequest = (pendingRequest: any): void => {
  pendingRequests.push(pendingRequest);
};

const errorLink = onError(({ forward, graphQLErrors, operation }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      switch (err?.message) {
        case 'Unauthorized':
          if (!isRefreshing) {
            setIsRefreshing(true);

            return fromPromise(
              fetchRefreshToken().catch((e) => {
                resolvePendingRequests();
                setIsRefreshing(false);

                return forward(operation);
              }),
            ).flatMap(() => {
              resolvePendingRequests();
              setIsRefreshing(false);

              return forward(operation);
            });
          } else {
            return fromPromise(
              new Promise<void>((resolve) => {
                addPendingRequest(() => resolve());
              }),
            ).flatMap(() => forward(operation));
          }
      }
    }
  }
});

const authLink = new ApolloLink((operation, forward) => {
  const token = store.getState().auth.accessToken;

  operation.setContext(({ headers }: { headers: Headers }) => ({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : null,
    },
  }));

  return forward(operation);
});

const httpLink = createHttpLink({
  credentials: 'include',
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
});

const wsLink = new GraphQLWsLink(
  createClient({
    connectionParams: () => {
      const admin = store.getState().auth.authenticatedAdmin;

      return {
        callerId: admin?.userId,
        callerName: `${admin?.firstName} ${admin?.lastName}`,
        callerType: 'ADMIN',
        sessionId: uuidv4(),
      };
    },
    keepAlive: 60000,
    on: {
      connected: (socket) => {
        // console.log(socket);
      },
      error: (error) => {
        // console.log(error);
      },
      ping: (received) => {
        // console.log('ping');
      },
      pong: (received) => {
        // console.log('pong');
      },
    },
    retryAttempts: Infinity,
    shouldRetry: () => true,
    url: process.env.REACT_APP_SUBSCRIPTION_ENDPOINT,
  }),
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);

    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const client = new ApolloClient({
  cache,
  link: from([errorLink, authLink, splitLink]),
});

export default client;
