import {
  AdminClubhouseFacilityAvailability,
  AdminGroupBooking,
  SlotItem,
} from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type FacilityMode =
  | 'hour_1'
  | 'hour_2'
  | 'hour_3'
  | 'hour_4'
  | 'hour_5'
  | 'putting'
  | 'putting_30'
  | 'putting_60'
  | 'trial';

export interface BookingQuickLook {
  bookings: AdminGroupBooking[];
  isShowModal: boolean;
}

export interface States {
  availability: AdminClubhouseFacilityAvailability | null;
  bookingId: string;
  bookingQuickLook: BookingQuickLook | null;
  clubhouseCode: string;
  currentDate: string | null;
  facilityCode: string | null;
  facilityMode: FacilityMode;
  hasStudios: boolean;
  hr1: SlotItem | null;
  hr2: SlotItem | null;
  hr3: SlotItem | null;
  hr4: SlotItem | null;
  hr5: SlotItem | null;
  isShowModalFacilitySelector: boolean;
  resourceName: string;
}

const initialState: States = {
  availability: null,
  bookingId: '',
  bookingQuickLook: null,
  clubhouseCode: '',
  currentDate: null,
  facilityCode: null,
  facilityMode: 'hour_1',
  hasStudios: false,
  hr1: null,
  hr2: null,
  hr3: null,
  hr4: null,
  hr5: null,
  isShowModalFacilitySelector: false,
  resourceName: '',
};

export const facilityUsageSlice = createSlice({
  initialState,
  name: 'facilityUsage',
  reducers: {
    clearFacilities: (state) => {
      state.facilityCode = null;
      state.facilityMode = 'hour_1';
      state.hasStudios = false;
      state.hr1 = null;
      state.hr2 = null;
      state.hr3 = null;
      state.hr4 = null;
      state.hr5 = null;
    },
    setAvailability: (
      state,
      { payload }: PayloadAction<AdminClubhouseFacilityAvailability | null>,
    ) => {
      state.availability = payload;
    },
    setBookingId: (state, { payload }: PayloadAction<string>) => {
      state.bookingId = payload;
    },
    setBookingQuickLook: (
      state,
      { payload }: PayloadAction<BookingQuickLook | null>,
    ) => {
      state.bookingQuickLook = payload;
    },
    setClubhouseCode: (state, { payload }: PayloadAction<string>) => {
      state.clubhouseCode = payload;
    },
    setCurrentDate: (state, { payload }: PayloadAction<string>) => {
      state.currentDate = payload;
    },
    setFacilityCode: (state, { payload }: PayloadAction<string | null>) => {
      state.facilityCode = payload;
    },
    setFacilityMode: (state, { payload }: PayloadAction<FacilityMode>) => {
      state.facilityMode = payload;
    },
    setHasStudios: (state, { payload }: PayloadAction<boolean>) => {
      state.hasStudios = payload;
    },
    setHr1: (state, { payload }: PayloadAction<SlotItem | null>) => {
      state.hr1 = payload;
    },
    setHr2: (state, { payload }: PayloadAction<SlotItem | null>) => {
      state.hr2 = payload;
    },
    setHr3: (state, { payload }: PayloadAction<SlotItem | null>) => {
      state.hr3 = payload;
    },
    setHr4: (state, { payload }: PayloadAction<SlotItem | null>) => {
      state.hr4 = payload;
    },
    setHr5: (state, { payload }: PayloadAction<SlotItem | null>) => {
      state.hr5 = payload;
    },
    setIsShowModalFacilitySelector: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isShowModalFacilitySelector = payload;
    },
    setResourceName: (state, { payload }: PayloadAction<string>) => {
      state.resourceName = payload;
    },
  },
});

export default facilityUsageSlice.reducer;

export const {
  clearFacilities: clearFacilitiesAction,
  setAvailability: setAvailabilityAction,
  setBookingId: setBookingIdAction,
  setBookingQuickLook: setBookingQuickLookAction,
  setClubhouseCode: setClubhouseCodeAction,
  setCurrentDate: setCurrentDateAction,
  setFacilityCode: setFacilityCodeAction,
  setFacilityMode: setFacilityModeAction,
  setHasStudios: setHasStudiosAction,
  setHr1: setHr1Action,
  setHr2: setHr2Action,
  setHr3: setHr3Action,
  setHr4: setHr4Action,
  setHr5: setHr5Action,
  setIsShowModalFacilitySelector: setIsShowModalFacilitySelectorAction,
  setResourceName: setResourceNameAction,
} = facilityUsageSlice.actions;
