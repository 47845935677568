import { CountryCode, Direction, OrderBy, PageInfo } from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface States {
  filterCountry: CountryCode | string;
  isGridReady: boolean;
  listClubhousesEndCursor: string;
  listClubhousesFirst: number | null;
  listClubhousesOrderBy: OrderBy;
  listClubhousesPageNumber: number;
  listClubhousesPageSize: number;
  listClubhousesRowIndex: number;
  pageInfo: PageInfo | null | undefined;
  recordLoaded: number;
}

const initialState: States = {
  filterCountry: '',
  isGridReady: false,
  listClubhousesEndCursor: '',
  listClubhousesFirst: 1000,
  listClubhousesOrderBy: {
    direction: Direction.Asc,
    field: 'addressCountryIso',
  },
  listClubhousesPageNumber: 0,
  listClubhousesPageSize: 10,
  listClubhousesRowIndex: -1,
  pageInfo: null,
  recordLoaded: 0,
};

export const clubhousesListClubhousesSlice = createSlice({
  initialState,
  name: 'clubhousesListClubhouses',
  reducers: {
    setFilterCountry: (
      state,
      { payload }: PayloadAction<CountryCode | string>,
    ) => {
      state.filterCountry = payload;
    },
    setIsGridReady: (state, { payload }: PayloadAction<boolean>) => {
      state.isGridReady = payload;
    },
    setListClubhousesEndCursor: (state, { payload }: PayloadAction<string>) => {
      state.listClubhousesEndCursor = payload;
    },
    setListClubhousesFirst: (
      state,
      { payload }: PayloadAction<number | null>,
    ) => {
      state.listClubhousesFirst = payload;
    },
    setListClubhousesOrderBy: (state, { payload }: PayloadAction<OrderBy>) => {
      state.listClubhousesOrderBy = payload;
    },
    setListClubhousesPageNumber: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listClubhousesPageNumber = payload;
    },
    setListClubhousesPageSize: (state, { payload }: PayloadAction<number>) => {
      state.listClubhousesPageSize = payload;
    },
    setListClubhousesRowIndex: (state, { payload }: PayloadAction<number>) => {
      state.listClubhousesRowIndex = payload;
    },
    setPageInfo: (state, { payload }: PayloadAction<PageInfo>) => {
      state.pageInfo = payload;
    },
    setRecordLoaded: (state, { payload }: PayloadAction<number>) => {
      state.recordLoaded = payload;
    },
  },
});

export default clubhousesListClubhousesSlice.reducer;

export const {
  setFilterCountry: setFilterCountryAction,
  setIsGridReady: setIsGridReadyAction,
  setListClubhousesEndCursor: setListClubhousesEndCursorAction,
  setListClubhousesFirst: setListClubhousesFirstAction,
  setListClubhousesOrderBy: setListClubhousesOrderByAction,
  setListClubhousesPageNumber: setListClubhousesPageNumberAction,
  setListClubhousesPageSize: setListClubhousesPageSizeAction,
  setListClubhousesRowIndex: setListClubhousesRowIndexAction,
  setPageInfo: setPageInfoAction,
  setRecordLoaded: setRecordLoadedAction,
} = clubhousesListClubhousesSlice.actions;
