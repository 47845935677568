import {
  Direction,
  OrderBy,
  PageInfo,
  SubscriptionStatus,
} from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface States {
  filterSearchTerm: string;
  filterSubscriptionStatus: SubscriptionStatus;
  isGridReady: boolean;
  isShowUtilityDrawer: boolean;
  listSubscriptionsEndCursor: string;
  listSubscriptionsFirst: number | null;
  listSubscriptionsOrderBy: OrderBy;
  listSubscriptionsPageNumber: number;
  listSubscriptionsPageSize: number;
  listSubscriptionsRowIndex: number;
  pageInfo: PageInfo | null | undefined;
  recordLoaded: number;
}

const initialState: States = {
  filterSearchTerm: '',
  filterSubscriptionStatus: SubscriptionStatus.Na,
  isGridReady: false,
  isShowUtilityDrawer: false,
  listSubscriptionsEndCursor: '',
  listSubscriptionsFirst: 1000,
  listSubscriptionsOrderBy: {
    direction: Direction.Desc,
    field: 'createdAt',
  },
  listSubscriptionsPageNumber: 0,
  listSubscriptionsPageSize: 100,
  listSubscriptionsRowIndex: -1,
  pageInfo: null,
  recordLoaded: 0,
};

export const membersListSubscriptionsSlice = createSlice({
  initialState,
  name: 'membersListSubscriptions',
  reducers: {
    setFilterSearchTerm: (state, { payload }: PayloadAction<string>) => {
      state.filterSearchTerm = payload;
    },
    setFilterSubscriptionStatus: (
      state,
      { payload }: PayloadAction<SubscriptionStatus>,
    ) => {
      state.filterSubscriptionStatus = payload;
    },
    setIsGridReady: (state, { payload }: PayloadAction<boolean>) => {
      state.isGridReady = payload;
    },
    setIsShowUtilityDrawer: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowUtilityDrawer = payload;
    },
    setListSubscriptionsEndCursor: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listSubscriptionsEndCursor = payload;
    },
    setListSubscriptionsFirst: (
      state,
      { payload }: PayloadAction<number | null>,
    ) => {
      state.listSubscriptionsFirst = payload;
    },
    setListSubscriptionsOrderBy: (
      state,
      { payload }: PayloadAction<OrderBy>,
    ) => {
      state.listSubscriptionsOrderBy = payload;
    },
    setListSubscriptionsPageNumber: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listSubscriptionsPageNumber = payload;
    },
    setListSubscriptionsPageSize: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listSubscriptionsPageSize = payload;
    },
    setListSubscriptionsRowIndex: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listSubscriptionsRowIndex = payload;
    },
    setPageInfo: (state, { payload }: PayloadAction<PageInfo>) => {
      state.pageInfo = payload;
    },
    setRecordLoaded: (state, { payload }: PayloadAction<number>) => {
      state.recordLoaded = payload;
    },
  },
});

export default membersListSubscriptionsSlice.reducer;

export const {
  setFilterSearchTerm: setFilterSearchTermAction,
  setFilterSubscriptionStatus: setFilterSubscriptionStatusAction,
  setIsGridReady: setIsGridReadyAction,
  setIsShowUtilityDrawer: setIsShowUtilityDrawerAction,
  setListSubscriptionsEndCursor: setListSubscriptionsEndCursorAction,
  setListSubscriptionsFirst: setListSubscriptionsFirstAction,
  setListSubscriptionsOrderBy: setListSubscriptionsOrderByAction,
  setListSubscriptionsPageNumber: setListSubscriptionsPageNumberAction,
  setListSubscriptionsPageSize: setListSubscriptionsPageSizeAction,
  setListSubscriptionsRowIndex: setListSubscriptionsRowIndexAction,
  setPageInfo: setPageInfoAction,
  setRecordLoaded: setRecordLoadedAction,
} = membersListSubscriptionsSlice.actions;
