import apolloClient from '_lib/clients/apolloClient';
import { reactQueryClient } from '_lib/clients/reactQueryClient';
import { ApolloProvider } from '@apollo/client';
import { ChakraProvider } from '@chakra-ui/react';
import { ErrorFallback } from 'components/ErrorFallback';
import { ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClientProvider } from 'react-query';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from 'store';
import { theme } from 'styles/chakra';
import { ability, AbilityContext } from './can';
import { handleError } from './helpers/handleError';
import { handleErrorReset } from './helpers/handleErrorReset';
import '../styles/base.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import '../styles/ag-theme-custom.css';
import 'draft-js/dist/Draft.css';
import 'contenido/dist/styles.css';

interface RootProps {
  children: ReactNode;
}

export const Root = (props: RootProps) => {
  const { children } = props;

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ChakraProvider portalZIndex={1000} theme={theme}>
        <ReduxProvider store={store}>
          <AbilityContext.Provider value={ability}>
            <ApolloProvider client={apolloClient}>
              <QueryClientProvider client={reactQueryClient}>
                {children}
              </QueryClientProvider>
            </ApolloProvider>
          </AbilityContext.Provider>
        </ReduxProvider>
      </ChakraProvider>
    </ErrorBoundary>
  );
};
