import { BookingType, FacilityType, SlotItem } from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DrawerTempLinkButtonValue } from 'components/_basic/DrawerComponents';

export type FacilityMode =
  | 'hour_1'
  | 'hour_2'
  | 'hour_3'
  | 'hour_4'
  | 'hour_5'
  | 'putting'
  | 'putting_30'
  | 'putting_60'
  | 'trial';

export type IndoorPartner = {
  memberIndividualId: string;
  memberName: string;
  memberUuid: string;
};

export interface GetSlotItemArgs {
  clubhouseCode: string;
  date: string;
  dateId: string;
  time: string;
}

export interface States {
  bookingId: string;
  bookingType: BookingType | null;
  facilityCode: string | null;
  facilityMode: FacilityMode;
  facilityType: FacilityType | null;
  getSlotItemArgs: GetSlotItemArgs | null;
  hr1: SlotItem | null;
  hr2: SlotItem | null;
  hr3: SlotItem | null;
  hr4: SlotItem | null;
  hr5: SlotItem | null;
  indoorFacilityMode: FacilityMode;
  indoorHr1: SlotItem | null;
  indoorHr2: SlotItem | null;
  indoorPartner: IndoorPartner | null;
  indoorPartnerFacilityCode: string | null;
  isShowModalFacilitySelector: boolean;
  isShowModalOptionalName: boolean;
  isShowModalSelectGroupMember: boolean;
  isShowModalSelectGuestMember: boolean;
  optionalPlayerName: string;
  optionalPlayerNameBookingId: string;
  optionalPlayerNameInitial: string;
  partnerNotificationMessage: string;
  partnerNotifyByApp: boolean;
  partnerNotifyByEmail: boolean;
  partnerNotifyBySms: boolean;
  resourceName: string;
  selectedBooking: DrawerTempLinkButtonValue;
  tabIndex: number;
}

const initialState: States = {
  bookingId: '',
  bookingType: null,
  facilityCode: null,
  facilityMode: 'hour_1',
  facilityType: null,
  getSlotItemArgs: null,
  hr1: null,
  hr2: null,
  hr3: null,
  hr4: null,
  hr5: null,
  indoorFacilityMode: 'hour_1',
  indoorHr1: null,
  indoorHr2: null,
  indoorPartner: null,
  indoorPartnerFacilityCode: null,
  isShowModalFacilitySelector: false,
  isShowModalOptionalName: false,
  isShowModalSelectGroupMember: false,
  isShowModalSelectGuestMember: false,
  optionalPlayerName: '',
  optionalPlayerNameBookingId: '',
  optionalPlayerNameInitial: '',
  partnerNotificationMessage: '',
  partnerNotifyByApp: false,
  partnerNotifyByEmail: false,
  partnerNotifyBySms: false,
  resourceName: '',
  selectedBooking: {
    id: '',
    label: '',
    meta: '',
  },
  tabIndex: 0,
};

export const bookingsEditBookingSlice = createSlice({
  initialState,
  name: 'bookingsEditBooking',
  reducers: {
    clearFacilities: (state) => {
      state.facilityCode = null;
      state.facilityMode = 'hour_1';
      state.hr1 = null;
      state.hr2 = null;
      state.hr3 = null;
      state.hr4 = null;
      state.hr5 = null;
    },
    deleteIndoorPartner: (state) => {
      state.indoorPartner = null;
      state.indoorPartnerFacilityCode = null;
    },
    setBookingId: (state, { payload }: PayloadAction<string>) => {
      state.bookingId = payload;
    },
    setBookingType: (state, { payload }: PayloadAction<BookingType | null>) => {
      state.bookingType = payload;
    },
    setFacilityCode: (state, { payload }: PayloadAction<string | null>) => {
      state.facilityCode = payload;
    },
    setFacilityMode: (state, { payload }: PayloadAction<FacilityMode>) => {
      state.facilityMode = payload;
    },
    setFacilityType: (
      state,
      { payload }: PayloadAction<FacilityType | null>,
    ) => {
      state.facilityType = payload;
    },
    setGetSlotItemArgs: (
      state,
      { payload }: PayloadAction<GetSlotItemArgs>,
    ) => {
      state.getSlotItemArgs = payload;
    },
    setHr1: (state, { payload }: PayloadAction<SlotItem | null>) => {
      state.hr1 = payload;
    },
    setHr2: (state, { payload }: PayloadAction<SlotItem | null>) => {
      state.hr2 = payload;
    },
    setHr3: (state, { payload }: PayloadAction<SlotItem | null>) => {
      state.hr3 = payload;
    },
    setHr4: (state, { payload }: PayloadAction<SlotItem | null>) => {
      state.hr4 = payload;
    },
    setHr5: (state, { payload }: PayloadAction<SlotItem | null>) => {
      state.hr5 = payload;
    },
    setIndoorFacilityMode: (
      state,
      { payload }: PayloadAction<FacilityMode>,
    ) => {
      state.indoorFacilityMode = payload;
    },
    setIndoorHr1: (state, { payload }: PayloadAction<SlotItem | null>) => {
      state.indoorHr1 = payload;
    },
    setIndoorHr2: (state, { payload }: PayloadAction<SlotItem | null>) => {
      state.indoorHr2 = payload;
    },
    setIndoorPartner: (state, { payload }: PayloadAction<IndoorPartner>) => {
      state.indoorPartner = payload;
    },
    setIndoorPartnerFacilityCode: (
      state,
      { payload }: PayloadAction<string | null>,
    ) => {
      state.indoorPartnerFacilityCode = payload;
    },
    setIsShowModalFacilitySelector: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isShowModalFacilitySelector = payload;
    },
    setIsShowModalOptionalName: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isShowModalOptionalName = payload;
    },
    setIsShowModalSelectGroupMember: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isShowModalSelectGroupMember = payload;
    },
    setIsShowModalSelectGuestMember: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isShowModalSelectGuestMember = payload;
    },
    setOptionalPlayerName: (state, { payload }: PayloadAction<string>) => {
      state.optionalPlayerName = payload;
    },
    setOptionalPlayerNameBookingId: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.optionalPlayerNameBookingId = payload;
    },
    setOptionalPlayerNameInitial: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.optionalPlayerNameInitial = payload;
    },
    setPartnerNotificationMessage: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.partnerNotificationMessage = payload;
    },
    setPartnerNotifyByApp: (state, { payload }: PayloadAction<boolean>) => {
      state.partnerNotifyByApp = payload;
    },
    setPartnerNotifyByEmail: (state, { payload }: PayloadAction<boolean>) => {
      state.partnerNotifyByEmail = payload;
    },
    setPartnerNotifyBySms: (state, { payload }: PayloadAction<boolean>) => {
      state.partnerNotifyBySms = payload;
    },
    setResourceName: (state, { payload }: PayloadAction<string>) => {
      state.resourceName = payload;
    },
    setSelectedBooking: (
      state,
      { payload }: PayloadAction<DrawerTempLinkButtonValue>,
    ) => {
      state.selectedBooking = payload;
    },
    setTabIndex: (state, { payload }: PayloadAction<number>) => {
      state.tabIndex = payload;
    },
  },
});

export default bookingsEditBookingSlice.reducer;

export const {
  clearFacilities: clearFacilitiesAction,
  deleteIndoorPartner: deleteIndoorPartnerAction,
  setBookingId: setBookingIdAction,
  setBookingType: setBookingTypeAction,
  setFacilityCode: setFacilityCodeAction,
  setFacilityMode: setFacilityModeAction,
  setFacilityType: setFacilityTypeAction,
  setGetSlotItemArgs: setGetSlotItemArgsAction,
  setHr1: setHr1Action,
  setHr2: setHr2Action,
  setHr3: setHr3Action,
  setHr4: setHr4Action,
  setHr5: setHr5Action,
  setIndoorFacilityMode: setIndoorFacilityModeAction,
  setIndoorHr1: setIndoorHr1Action,
  setIndoorHr2: setIndoorHr2Action,
  setIndoorPartner: setIndoorPartnerAction,
  setIndoorPartnerFacilityCode: setIndoorPartnerFacilityCodeAction,
  setIsShowModalFacilitySelector: setIsShowModalFacilitySelectorAction,
  setIsShowModalOptionalName: setIsShowModalOptionalNameAction,
  setIsShowModalSelectGroupMember: setIsShowModalSelectGroupMemberAction,
  setIsShowModalSelectGuestMember: setIsShowModalSelectGuestMemberAction,
  setOptionalPlayerName: setOptionalPlayerNameAction,
  setOptionalPlayerNameBookingId: setOptionalPlayerNameBookingIdAction,
  setOptionalPlayerNameInitial: setOptionalPlayerNameInitialAction,
  setPartnerNotificationMessage: setPartnerNotificationMessageAction,
  setPartnerNotifyByApp: setPartnerNotifyByAppAction,
  setPartnerNotifyByEmail: setPartnerNotifyByEmailAction,
  setPartnerNotifyBySms: setPartnerNotifyBySmsAction,
  setResourceName: setResourceNameAction,
  setSelectedBooking: setSelectedBookingAction,
  setTabIndex: setTabIndexAction,
} = bookingsEditBookingSlice.actions;
