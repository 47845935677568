import {
  AcTransactionType,
  AdminGroupBooking,
  AdminMemberActivationLineItem,
  AdminMembershipServicesExpiryMode,
  AdminMembershipServicesStatus,
  BookingStatus,
  BookingType,
  Direction,
  OrderBy,
  PageInfo,
  PaymentMethod,
  ServiceTransactionBasic,
  ServiceTransactionType,
  SlotItem,
} from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DrawerTempLinkButtonValue } from 'components/_basic/DrawerComponents';
import { DateRangeMode } from 'components/DateFilter';

export interface BookingQuickLook {
  bookings: AdminGroupBooking[];
  isShowModal: boolean;
}

export type TransferRecipient = {
  memberIndividualId: string;
  memberName: string;
  memberUuid: string;
};

export type FacilityMode =
  | 'hour_1'
  | 'hour_2'
  | 'hour_3'
  | 'hour_4'
  | 'hour_5'
  | 'putting'
  | 'putting_30'
  | 'putting_60'
  | 'trial';

export interface States {
  acTxnsEndTime: string | null;
  acTxnsStartTime: string | null;
  activationLineItems: AdminMemberActivationLineItem[];
  activationOrderNo: string;
  activationPrice: number;
  bookingId: string;
  bookingQuickLook: BookingQuickLook | null;
  checkStatus: boolean;
  credit: number;
  facilityCode: string | null;
  facilityMode: FacilityMode;
  hasStudios: boolean;
  hr1: SlotItem | null;
  hr2: SlotItem | null;
  hr3: SlotItem | null;
  hr4: SlotItem | null;
  hr5: SlotItem | null;

  isGridReady: boolean;
  isGroupBookingAllowed: boolean;
  isOpenAceCoinTransferModal: boolean;
  isOpenAceCoinTransferSelectMemberModal: boolean;
  isRefreshing: boolean;
  isShowBDAddDrawer: boolean;
  isShowModalFacilitySelector: boolean;
  isShowNotificationDrawer: boolean;
  isShowPaymentDrawer: boolean;
  isShowTierUpdateDrawer: boolean;
  isShowUtilityDrawer: boolean;

  listAcTransactionsDateRangeMode: DateRangeMode;
  listAcTransactionsEndCursor: string;
  listAcTransactionsEndDate: string | null;
  listAcTransactionsFilterTransactionType: AcTransactionType | string;
  listAcTransactionsFirst: number | null;
  listAcTransactionsFirstDate: string | null;
  listAcTransactionsOrderBy: OrderBy;
  listAcTransactionsPageInfo: PageInfo | null | undefined;
  listAcTransactionsPageNumber: number;
  listAcTransactionsPageSize: number;
  listAcTransactionsRecordLoaded: number;
  listAcTransactionsRowIndex: number;
  listAcTransactionsStartDate: string | null;

  listBookingsDateRangeMode: DateRangeMode;
  listBookingsEndCursor: string;
  listBookingsEndTime: string | null;
  listBookingsFilterBookingId: string;
  listBookingsFilterBookingStatus: BookingStatus;
  listBookingsFilterBookingType: BookingType | string;
  listBookingsFilterCoach: string;
  listBookingsFilterVenue: string;
  listBookingsFirst: number | null;
  listBookingsFirstDate: string | null;
  listBookingsLastRecordDate: string | null;
  listBookingsListBookingsActiveFirst: number | null;
  listBookingsOrderBy: OrderBy;
  listBookingsPageInfo: PageInfo | null | undefined;
  listBookingsPageNumber: number;
  listBookingsPageSize: number;
  listBookingsRecordLoaded: number;
  listBookingsRowIndex: number;
  listBookingsStartTime: string | null;

  listInvoicesDateRangeMode: DateRangeMode;
  listInvoicesEndCursor: string;
  listInvoicesEndDate: string | null;
  listInvoicesFilterPaymentMethod: PaymentMethod | string;
  listInvoicesFirst: number | null;
  listInvoicesFirstDate: string | null;
  listInvoicesOrderBy: OrderBy;
  listInvoicesPageInfo: PageInfo | null | undefined;
  listInvoicesPageNumber: number;
  listInvoicesPageSize: number;
  listInvoicesRecordLoaded: number;
  listInvoicesRowIndex: number;
  listInvoicesStartDate: string | null;

  listNotificationsEndCursor: string;
  listNotificationsEndDate: string | null;
  listNotificationsFilterSearchTerm: string;
  listNotificationsFirst: number | null;
  listNotificationsOrderBy: OrderBy;
  listNotificationsPageInfo: PageInfo | null | undefined;
  listNotificationsPageNumber: number;
  listNotificationsPageSize: number;
  listNotificationsRecordLoaded: number;
  listNotificationsRowIndex: number;
  listNotificationsStartDate: string | null;

  listPackagesDatesSort: string;
  listPackagesEndCursor: string;
  listPackagesEndDate: string | null;
  listPackagesFilterExpiry: AdminMembershipServicesExpiryMode | string;
  listPackagesFilterPackageName: string;
  listPackagesFilterPackageStatus: AdminMembershipServicesStatus | string;
  listPackagesFirst: number | null;
  listPackagesOrderBy: OrderBy;
  listPackagesPageInfo: PageInfo | null | undefined;
  listPackagesPageNumber: number;
  listPackagesPageSize: number;
  listPackagesRecordLoaded: number;
  listPackagesRowIndex: number;
  listPackagesStartDate: string | null;

  listServiceTransactionsDateRangeMode: DateRangeMode;
  listServiceTransactionsEndCursor: string;
  listServiceTransactionsEndDate: string | null;
  listServiceTransactionsFilterServiceType: string;
  listServiceTransactionsFilterTransactionType: ServiceTransactionType | string;
  listServiceTransactionsFirst: number | null;
  listServiceTransactionsOrderBy: OrderBy;
  listServiceTransactionsPageInfo: PageInfo | null | undefined;
  listServiceTransactionsPageNumber: number;
  listServiceTransactionsPageSize: number;
  listServiceTransactionsRecordLoaded: number;
  listServiceTransactionsRowIndex: number;
  listServiceTransactionsStartDate: string | null;
  listServicesFirstDate: string | null;
  listServicesIsShowModal: boolean;
  listServicesPackageNo: string | null;
  listServicesTransactionDetails: ServiceTransactionBasic | null;

  listSubscriptionsEndCursor: string;
  listSubscriptionsEndDate: string | null;
  listSubscriptionsFirst: number | null;
  listSubscriptionsOrderBy: OrderBy;
  listSubscriptionsPageInfo: PageInfo | null | undefined;
  listSubscriptionsPageNumber: number;
  listSubscriptionsPageSize: number;
  listSubscriptionsRecordLoaded: number;
  listSubscriptionsRowIndex: number;
  listSubscriptionsSearchTerm: string;
  listSubscriptionsStartDate: string | null;

  memberIndividualId: string;
  memberName: string;
  memberUuid: string;
  membershipUuid: string;
  resourceName: string;
  selectedMember: DrawerTempLinkButtonValue;
  serviceTxnsEndTime: string | null;
  serviceTxnsStartTime: string | null;
  soaEndTime: string | null;
  soaStartTime: string | null;
  tabIndex: number;
  transferAmount: string;
  transferRecipient: TransferRecipient | null;
}

const initialState: States = {
  acTxnsEndTime: null,
  acTxnsStartTime: null,
  activationLineItems: [],
  activationOrderNo: '',
  activationPrice: 0,
  bookingId: '',
  bookingQuickLook: null,
  checkStatus: false,
  credit: 0,
  facilityCode: null,
  facilityMode: 'hour_1',
  hasStudios: false,
  hr1: null,
  hr2: null,
  hr3: null,
  hr4: null,
  hr5: null,

  isGridReady: false,
  isGroupBookingAllowed: false,
  isOpenAceCoinTransferModal: false,
  isOpenAceCoinTransferSelectMemberModal: false,
  isRefreshing: false,
  isShowBDAddDrawer: false,
  isShowModalFacilitySelector: false,
  isShowNotificationDrawer: false,
  isShowPaymentDrawer: false,
  isShowTierUpdateDrawer: false,

  isShowUtilityDrawer: false,
  listAcTransactionsDateRangeMode: 'last-1y-desc',
  listAcTransactionsEndCursor: '',
  listAcTransactionsEndDate: null,
  listAcTransactionsFilterTransactionType: '',
  listAcTransactionsFirst: 1000,
  listAcTransactionsFirstDate: null,
  listAcTransactionsOrderBy: {
    direction: Direction.Desc,
    field: 'transactionDateTime',
  },
  listAcTransactionsPageInfo: null,
  listAcTransactionsPageNumber: 0,
  listAcTransactionsPageSize: 100,
  listAcTransactionsRecordLoaded: 0,
  listAcTransactionsRowIndex: -1,
  listAcTransactionsStartDate: null,

  listBookingsDateRangeMode: 'from-today-asc',
  listBookingsEndCursor: '',
  listBookingsEndTime: null,
  listBookingsFilterBookingId: '',
  listBookingsFilterBookingStatus: BookingStatus.Active,
  listBookingsFilterBookingType: '',
  listBookingsFilterCoach: '',
  listBookingsFilterVenue: '',
  listBookingsFirst: 1000,
  listBookingsFirstDate: null,
  listBookingsLastRecordDate: null,
  listBookingsListBookingsActiveFirst: 1000,
  listBookingsOrderBy: {
    direction: Direction.Asc,
    field: 'startTime',
  },
  listBookingsPageInfo: null,
  listBookingsPageNumber: 0,
  listBookingsPageSize: 100,
  listBookingsRecordLoaded: 0,
  listBookingsRowIndex: -1,
  listBookingsStartTime: null,

  listInvoicesDateRangeMode: 'last-30-desc',
  listInvoicesEndCursor: '',
  listInvoicesEndDate: null,
  listInvoicesFilterPaymentMethod: '',
  listInvoicesFirst: 1000,
  listInvoicesFirstDate: null,
  listInvoicesOrderBy: {
    direction: Direction.Desc,
    field: 'transactionDateTime',
  },
  listInvoicesPageInfo: null,
  listInvoicesPageNumber: 0,
  listInvoicesPageSize: 100,
  listInvoicesRecordLoaded: 0,
  listInvoicesRowIndex: -1,
  listInvoicesStartDate: null,

  listNotificationsEndCursor: '',
  listNotificationsEndDate: null,
  listNotificationsFilterSearchTerm: '',
  listNotificationsFirst: 1000,
  listNotificationsOrderBy: {
    direction: Direction.Desc,
    field: 'createdAt',
  },
  listNotificationsPageInfo: null,
  listNotificationsPageNumber: 0,
  listNotificationsPageSize: 10,
  listNotificationsRecordLoaded: 0,
  listNotificationsRowIndex: -1,
  listNotificationsStartDate: null,

  listPackagesDatesSort: 'issued-desc',
  listPackagesEndCursor: '',
  listPackagesEndDate: null,
  listPackagesFilterExpiry: '',
  listPackagesFilterPackageName: '',
  listPackagesFilterPackageStatus: '',
  listPackagesFirst: 1000,
  listPackagesOrderBy: {
    direction: Direction.Desc,
    field: 'dateAdded',
  },
  listPackagesPageInfo: null,
  listPackagesPageNumber: 0,
  listPackagesPageSize: 100,
  listPackagesRecordLoaded: 0,
  listPackagesRowIndex: -1,
  listPackagesStartDate: null,

  listServiceTransactionsDateRangeMode: 'last-1y-desc',
  listServiceTransactionsEndCursor: '',
  listServiceTransactionsEndDate: null,
  listServiceTransactionsFilterServiceType: '',
  listServiceTransactionsFilterTransactionType: '',
  listServiceTransactionsFirst: 1000,
  listServiceTransactionsOrderBy: {
    direction: Direction.Desc,
    field: 'date',
  },
  listServiceTransactionsPageInfo: null,
  listServiceTransactionsPageNumber: 0,
  listServiceTransactionsPageSize: 100,
  listServiceTransactionsRecordLoaded: 0,
  listServiceTransactionsRowIndex: -1,
  listServiceTransactionsStartDate: null,
  listServicesFirstDate: null,
  listServicesIsShowModal: false,
  listServicesPackageNo: null,
  listServicesTransactionDetails: null,

  listSubscriptionsEndCursor: '',
  listSubscriptionsEndDate: null,
  listSubscriptionsFirst: 1000,
  listSubscriptionsOrderBy: {
    direction: Direction.Desc,
    field: 'startDate',
  },
  listSubscriptionsPageInfo: null,
  listSubscriptionsPageNumber: 0,
  listSubscriptionsPageSize: 100,
  listSubscriptionsRecordLoaded: 0,
  listSubscriptionsRowIndex: -1,
  listSubscriptionsSearchTerm: '',

  listSubscriptionsStartDate: null,
  memberIndividualId: '',
  memberName: '',
  memberUuid: '',
  membershipUuid: '',
  resourceName: '',
  selectedMember: {
    id: '',
    label: '',
    meta: '',
  },
  serviceTxnsEndTime: null,
  serviceTxnsStartTime: null,
  soaEndTime: null,
  soaStartTime: null,
  tabIndex: 0,
  transferAmount: '',
  transferRecipient: null,
};

type MemberIDs = {
  isGroupBookingAllowed: boolean;
  memberIndividualId: string;
  memberName: string;
  memberUuid: string;
  membershipUuid: string;
};

export const membersEditMemberSlice = createSlice({
  initialState,
  name: 'membersEditMember',
  reducers: {
    clearFacilities: (state) => {
      state.facilityCode = null;
      state.facilityMode = 'hour_1';
      state.hasStudios = false;
      state.hr1 = null;
      state.hr2 = null;
      state.hr3 = null;
      state.hr4 = null;
      state.hr5 = null;
    },
    setAcTxnsEndTime: (state, { payload }: PayloadAction<string>) => {
      state.acTxnsEndTime = payload;
    },
    setAcTxnsStartTime: (state, { payload }: PayloadAction<string>) => {
      state.acTxnsStartTime = payload;
    },
    setActivationLineItems: (
      state,
      { payload }: PayloadAction<AdminMemberActivationLineItem[]>,
    ) => {
      state.activationLineItems = payload;
    },
    setActivationOrderNo: (state, { payload }: PayloadAction<string>) => {
      state.activationOrderNo = payload;
    },
    setActivationPrice: (state, { payload }: PayloadAction<number>) => {
      state.activationPrice = payload;
    },
    setBookingId: (state, { payload }: PayloadAction<string>) => {
      state.bookingId = payload;
    },
    setBookingQuickLook: (
      state,
      { payload }: PayloadAction<BookingQuickLook | null>,
    ) => {
      state.bookingQuickLook = payload;
    },
    setCheckStatus: (state, { payload }: PayloadAction<boolean>) => {
      state.checkStatus = payload;
    },
    setCredit: (state, { payload }: PayloadAction<number>) => {
      state.credit = payload;
    },
    setFacilityCode: (state, { payload }: PayloadAction<string | null>) => {
      state.facilityCode = payload;
    },
    setFacilityMode: (state, { payload }: PayloadAction<FacilityMode>) => {
      state.facilityMode = payload;
    },
    setHasStudios: (state, { payload }: PayloadAction<boolean>) => {
      state.hasStudios = payload;
    },
    setHr1: (state, { payload }: PayloadAction<SlotItem | null>) => {
      state.hr1 = payload;
    },
    setHr2: (state, { payload }: PayloadAction<SlotItem | null>) => {
      state.hr2 = payload;
    },
    setHr3: (state, { payload }: PayloadAction<SlotItem | null>) => {
      state.hr3 = payload;
    },
    setHr4: (state, { payload }: PayloadAction<SlotItem | null>) => {
      state.hr4 = payload;
    },
    setHr5: (state, { payload }: PayloadAction<SlotItem | null>) => {
      state.hr5 = payload;
    },
    setIsGridReady: (state, { payload }: PayloadAction<boolean>) => {
      state.isGridReady = payload;
    },
    setIsOpenAceCoinTransferModal: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isOpenAceCoinTransferModal = payload;
    },
    setIsOpenAceCoinTransferSelectMemberModal: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isOpenAceCoinTransferSelectMemberModal = payload;
    },
    setIsRefreshing: (state, { payload }: PayloadAction<boolean>) => {
      state.isRefreshing = payload;
    },
    setIsShowBDAddDrawer: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowBDAddDrawer = payload;
    },
    setIsShowModalFacilitySelector: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isShowModalFacilitySelector = payload;
    },
    setIsShowNotificationDrawer: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isShowNotificationDrawer = payload;
    },
    setIsShowPaymentDrawer: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowPaymentDrawer = payload;
    },
    setIsShowTierUpdateDrawer: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowTierUpdateDrawer = payload;
    },

    setIsShowUtilityDrawer: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowUtilityDrawer = payload;
    },
    setListAcTransactionsDateRangeMode: (
      state,
      { payload }: PayloadAction<DateRangeMode>,
    ) => {
      state.listAcTransactionsDateRangeMode = payload;
    },
    setListAcTransactionsEndCursor: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listAcTransactionsEndCursor = payload;
    },
    setListAcTransactionsEndDate: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listAcTransactionsEndDate = payload;
    },
    setListAcTransactionsFilterTransactionType: (
      state,
      { payload }: PayloadAction<AcTransactionType | string>,
    ) => {
      state.listAcTransactionsFilterTransactionType = payload;
    },
    setListAcTransactionsFirst: (
      state,
      { payload }: PayloadAction<number | null>,
    ) => {
      state.listAcTransactionsFirst = payload;
    },
    setListAcTransactionsFirstDate: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listAcTransactionsFirstDate = payload;
    },
    setListAcTransactionsOrderBy: (
      state,
      { payload }: PayloadAction<OrderBy>,
    ) => {
      state.listAcTransactionsOrderBy = payload;
    },
    setListAcTransactionsPageInfo: (
      state,
      { payload }: PayloadAction<PageInfo>,
    ) => {
      state.listAcTransactionsPageInfo = payload;
    },
    setListAcTransactionsPageNumber: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listAcTransactionsPageNumber = payload;
    },
    setListAcTransactionsPageSize: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listAcTransactionsPageSize = payload;
    },
    setListAcTransactionsRecordLoaded: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listAcTransactionsRecordLoaded = payload;
    },
    setListAcTransactionsRowIndex: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listAcTransactionsRowIndex = payload;
    },
    setListAcTransactionsStartDate: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listAcTransactionsStartDate = payload;
    },

    setListBookingsDateRangeMode: (
      state,
      { payload }: PayloadAction<DateRangeMode>,
    ) => {
      state.listBookingsDateRangeMode = payload;
    },
    setListBookingsEndCursor: (state, { payload }: PayloadAction<string>) => {
      state.listBookingsEndCursor = payload;
    },
    setListBookingsEndTime: (state, { payload }: PayloadAction<string>) => {
      state.listBookingsEndTime = payload;
    },
    setListBookingsFilterBookingId: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listBookingsFilterBookingId = payload;
    },
    setListBookingsFilterBookingStatus: (
      state,
      { payload }: PayloadAction<BookingStatus>,
    ) => {
      state.listBookingsFilterBookingStatus = payload;
    },
    setListBookingsFilterBookingType: (
      state,
      { payload }: PayloadAction<BookingType | string>,
    ) => {
      state.listBookingsFilterBookingType = payload;
    },
    setListBookingsFilterCoach: (state, { payload }: PayloadAction<string>) => {
      state.listBookingsFilterCoach = payload;
    },
    setListBookingsFilterVenue: (state, { payload }: PayloadAction<string>) => {
      state.listBookingsFilterVenue = payload;
    },
    setListBookingsFirst: (
      state,
      { payload }: PayloadAction<number | null>,
    ) => {
      state.listBookingsFirst = payload;
    },
    setListBookingsFirstDate: (state, { payload }: PayloadAction<string>) => {
      state.listBookingsFirstDate = payload;
    },
    setListBookingsLastRecordDate: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listBookingsLastRecordDate = payload;
    },
    setListBookingsListBookingsActiveFirst: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listBookingsListBookingsActiveFirst = payload;
    },
    setListBookingsOrderBy: (state, { payload }: PayloadAction<OrderBy>) => {
      state.listBookingsOrderBy = payload;
    },
    setListBookingsPageInfo: (
      state,
      { payload }: PayloadAction<PageInfo | null>,
    ) => {
      state.listBookingsPageInfo = payload;
    },
    setListBookingsPageNumber: (state, { payload }: PayloadAction<number>) => {
      state.listBookingsPageNumber = payload;
    },
    setListBookingsPageSize: (state, { payload }: PayloadAction<number>) => {
      state.listBookingsPageSize = payload;
    },
    setListBookingsRecordLoaded: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listBookingsRecordLoaded = payload;
    },
    setListBookingsRowIndex: (state, { payload }: PayloadAction<number>) => {
      state.listBookingsRowIndex = payload;
    },
    setListBookingsStartTime: (state, { payload }: PayloadAction<string>) => {
      state.listBookingsStartTime = payload;
    },

    setListInvoicesDateRangeMode: (
      state,
      { payload }: PayloadAction<DateRangeMode>,
    ) => {
      state.listInvoicesDateRangeMode = payload;
    },
    setListInvoicesEndCursor: (state, { payload }: PayloadAction<string>) => {
      state.listInvoicesEndCursor = payload;
    },
    setListInvoicesEndDate: (state, { payload }: PayloadAction<string>) => {
      state.listInvoicesEndDate = payload;
    },
    setListInvoicesFilterPaymentMethod: (
      state,
      { payload }: PayloadAction<PaymentMethod | string>,
    ) => {
      state.listInvoicesFilterPaymentMethod = payload;
    },
    setListInvoicesFirst: (
      state,
      { payload }: PayloadAction<number | null>,
    ) => {
      state.listInvoicesFirst = payload;
    },
    setListInvoicesFirstDate: (state, { payload }: PayloadAction<string>) => {
      state.listInvoicesFirstDate = payload;
    },
    setListInvoicesOrderBy: (state, { payload }: PayloadAction<OrderBy>) => {
      state.listInvoicesOrderBy = payload;
    },
    setListInvoicesPageInfo: (
      state,
      { payload }: PayloadAction<PageInfo | null>,
    ) => {
      state.listInvoicesPageInfo = payload;
    },
    setListInvoicesPageNumber: (state, { payload }: PayloadAction<number>) => {
      state.listInvoicesPageNumber = payload;
    },
    setListInvoicesPageSize: (state, { payload }: PayloadAction<number>) => {
      state.listInvoicesPageSize = payload;
    },
    setListInvoicesRecordLoaded: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listInvoicesRecordLoaded = payload;
    },
    setListInvoicesRowIndex: (state, { payload }: PayloadAction<number>) => {
      state.listInvoicesRowIndex = payload;
    },

    setListInvoicesStartDate: (state, { payload }: PayloadAction<string>) => {
      state.listInvoicesStartDate = payload;
    },
    setListNotificationsEndCursor: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listNotificationsEndCursor = payload;
    },
    setListNotificationsFilterSearchTerm: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listNotificationsFilterSearchTerm = payload;
    },
    setListNotificationsFirst: (
      state,
      { payload }: PayloadAction<number | null>,
    ) => {
      state.listNotificationsFirst = payload;
    },
    setListNotificationsOrderBy: (
      state,
      { payload }: PayloadAction<OrderBy>,
    ) => {
      state.listNotificationsOrderBy = payload;
    },
    setListNotificationsPageInfo: (
      state,
      { payload }: PayloadAction<PageInfo>,
    ) => {
      state.listNotificationsPageInfo = payload;
    },
    setListNotificationsPageNumber: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listNotificationsPageNumber = payload;
    },
    setListNotificationsPageSize: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listNotificationsPageSize = payload;
    },
    setListNotificationsRecordLoaded: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listNotificationsRecordLoaded = payload;
    },
    setListNotificationsRowIndex: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listNotificationsRowIndex = payload;
    },

    setListPackagesDatesSort: (state, { payload }: PayloadAction<string>) => {
      state.listPackagesDatesSort = payload;
    },
    setListPackagesEndCursor: (state, { payload }: PayloadAction<string>) => {
      state.listPackagesEndCursor = payload;
    },
    setListPackagesFilterExpiry: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listPackagesFilterExpiry = payload;
    },
    setListPackagesFilterPackageName: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listPackagesFilterPackageName = payload;
    },
    setListPackagesFilterPackageStatus: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listPackagesFilterPackageStatus = payload;
    },
    setListPackagesFirst: (
      state,
      { payload }: PayloadAction<number | null>,
    ) => {
      state.listPackagesFirst = payload;
    },
    setListPackagesOrderBy: (state, { payload }: PayloadAction<OrderBy>) => {
      state.listPackagesOrderBy = payload;
    },
    setListPackagesPageInfo: (state, { payload }: PayloadAction<PageInfo>) => {
      state.listPackagesPageInfo = payload;
    },
    setListPackagesPageNumber: (state, { payload }: PayloadAction<number>) => {
      state.listPackagesPageNumber = payload;
    },
    setListPackagesPageSize: (state, { payload }: PayloadAction<number>) => {
      state.listPackagesPageSize = payload;
    },
    setListPackagesRecordLoaded: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listPackagesRecordLoaded = payload;
    },

    setListPackagesRowIndex: (state, { payload }: PayloadAction<number>) => {
      state.listPackagesRowIndex = payload;
    },
    setListServiceTransactionsDateRangeMode: (
      state,
      { payload }: PayloadAction<DateRangeMode>,
    ) => {
      state.listServiceTransactionsDateRangeMode = payload;
    },
    setListServiceTransactionsEndCursor: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listServiceTransactionsEndCursor = payload;
    },
    setListServiceTransactionsEndDate: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listServiceTransactionsEndDate = payload;
    },
    setListServiceTransactionsFilterServiceType: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listServiceTransactionsFilterServiceType = payload;
    },
    setListServiceTransactionsFilterTransactionType: (
      state,
      { payload }: PayloadAction<ServiceTransactionType | string>,
    ) => {
      state.listServiceTransactionsFilterTransactionType = payload;
    },
    setListServiceTransactionsFirst: (
      state,
      { payload }: PayloadAction<number | null>,
    ) => {
      state.listServiceTransactionsFirst = payload;
    },
    setListServiceTransactionsOrderBy: (
      state,
      { payload }: PayloadAction<OrderBy>,
    ) => {
      state.listServiceTransactionsOrderBy = payload;
    },
    setListServiceTransactionsPageInfo: (
      state,
      { payload }: PayloadAction<PageInfo>,
    ) => {
      state.listServiceTransactionsPageInfo = payload;
    },
    setListServiceTransactionsPageNumber: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listServiceTransactionsPageNumber = payload;
    },
    setListServiceTransactionsPageSize: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listServiceTransactionsPageSize = payload;
    },
    setListServiceTransactionsRecordLoaded: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listServiceTransactionsRecordLoaded = payload;
    },
    setListServiceTransactionsRowIndex: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listServiceTransactionsRowIndex = payload;
    },
    setListServiceTransactionsStartDate: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listServiceTransactionsStartDate = payload;
    },
    setListServicesFirstDate: (state, { payload }: PayloadAction<string>) => {
      state.listServicesFirstDate = payload;
    },
    setListServicesIsShowModal: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.listServicesIsShowModal = payload;
    },
    setListServicesPackageNo: (
      state,
      { payload }: PayloadAction<string | null>,
    ) => {
      state.listServicesPackageNo = payload;
    },

    setListServicesTransactionDetails: (
      state,
      { payload }: PayloadAction<ServiceTransactionBasic | null>,
    ) => {
      state.listServicesTransactionDetails = payload;
    },
    setListSubscriptionsEndCursor: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listSubscriptionsEndCursor = payload;
    },
    setListSubscriptionsFirst: (
      state,
      { payload }: PayloadAction<number | null>,
    ) => {
      state.listSubscriptionsFirst = payload;
    },
    setListSubscriptionsOrderBy: (
      state,
      { payload }: PayloadAction<OrderBy>,
    ) => {
      state.listSubscriptionsOrderBy = payload;
    },
    setListSubscriptionsPageInfo: (
      state,
      { payload }: PayloadAction<PageInfo>,
    ) => {
      state.listSubscriptionsPageInfo = payload;
    },
    setListSubscriptionsPageNumber: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listSubscriptionsPageNumber = payload;
    },
    setListSubscriptionsPageSize: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listSubscriptionsPageSize = payload;
    },
    setListSubscriptionsRecordLoaded: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listSubscriptionsRecordLoaded = payload;
    },
    setListSubscriptionsRowIndex: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listSubscriptionsRowIndex = payload;
    },
    setListSubscriptionsSearchTerm: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listSubscriptionsSearchTerm = payload;
    },
    setMemberIDs: (state, { payload }: PayloadAction<MemberIDs>) => {
      state.memberUuid = payload.memberUuid;
      state.membershipUuid = payload.membershipUuid;
      state.memberName = payload.memberName;
      state.memberIndividualId = payload.memberIndividualId;
      state.isGroupBookingAllowed = payload.isGroupBookingAllowed;
    },
    setResourceName: (state, { payload }: PayloadAction<string>) => {
      state.resourceName = payload;
    },
    setSelectedMember: (
      state,
      { payload }: PayloadAction<DrawerTempLinkButtonValue>,
    ) => {
      state.selectedMember = payload;
    },
    setServiceTxnsEndTime: (state, { payload }: PayloadAction<string>) => {
      state.serviceTxnsEndTime = payload;
    },
    setServiceTxnsStartTime: (state, { payload }: PayloadAction<string>) => {
      state.serviceTxnsStartTime = payload;
    },
    setSoaEndTime: (state, { payload }: PayloadAction<string>) => {
      state.soaEndTime = payload;
    },
    setSoaStartTime: (state, { payload }: PayloadAction<string>) => {
      state.soaStartTime = payload;
    },
    setTabIndex: (state, { payload }: PayloadAction<number>) => {
      state.tabIndex = payload;
    },
    setTransferAmount: (state, { payload }: PayloadAction<string>) => {
      state.transferAmount = payload;
    },
    setTransferRecipient: (
      state,
      { payload }: PayloadAction<TransferRecipient | null>,
    ) => {
      state.transferRecipient = payload;
    },
  },
});

export default membersEditMemberSlice.reducer;

export const {
  clearFacilities: clearFacilitiesAction,
  setActivationLineItems: setActivationLineItemsAction,
  setActivationOrderNo: setActivationOrderNoAction,
  setActivationPrice: setActivationPriceAction,
  setAcTxnsEndTime: setAcTxnsEndTimeAction,
  setAcTxnsStartTime: setAcTxnsStartTimeAction,
  setBookingId: setBookingIdAction,
  setBookingQuickLook: setBookingQuickLookAction,
  setCheckStatus: setCheckStatusAction,
  setCredit: setCreditAction,
  setFacilityCode: setFacilityCodeAction,
  setFacilityMode: setFacilityModeAction,
  setHasStudios: setHasStudiosAction,
  setHr1: setHr1Action,
  setHr2: setHr2Action,
  setHr3: setHr3Action,
  setHr4: setHr4Action,
  setHr5: setHr5Action,
  setIsGridReady: setIsGridReadyAction,
  setIsOpenAceCoinTransferModal: setIsOpenAceCoinTransferModalAction,
  setIsOpenAceCoinTransferSelectMemberModal:
    setIsOpenAceCoinTransferSelectMemberModalAction,
  setIsRefreshing: setIsRefreshingAction,
  setIsShowBDAddDrawer: setIsShowBDAddDrawerAction,
  setIsShowModalFacilitySelector: setIsShowModalFacilitySelectorAction,
  setIsShowNotificationDrawer: setIsShowNotificationDrawerAction,
  setIsShowPaymentDrawer: setIsShowPaymentDrawerAction,
  setIsShowTierUpdateDrawer: setIsShowTierUpdateDrawerAction,
  setIsShowUtilityDrawer: setIsShowUtilityDrawerAction,
  setListAcTransactionsDateRangeMode: setListAcTransactionsDateRangeModeAction,
  setListAcTransactionsEndCursor: setListAcTransactionsEndCursorAction,
  setListAcTransactionsEndDate: setListAcTransactionsEndDateAction,
  setListAcTransactionsFilterTransactionType:
    setListAcTransactionsFilterTransactionTypeAction,
  setListAcTransactionsFirst: setListAcTransactionsFirstAction,
  setListAcTransactionsFirstDate: setListAcTransactionsFirstDateAction,
  setListAcTransactionsOrderBy: setListAcTransactionsOrderByAction,
  setListAcTransactionsPageInfo: setListAcTransactionsPageInfoAction,
  setListAcTransactionsPageNumber: setListAcTransactionsPageNumberAction,
  setListAcTransactionsPageSize: setListAcTransactionsPageSizeAction,
  setListAcTransactionsRecordLoaded: setListAcTransactionsRecordLoadedAction,
  setListAcTransactionsRowIndex: setListAcTransactionsRowIndexAction,
  setListAcTransactionsStartDate: setListAcTransactionsStartDateAction,
  setListBookingsDateRangeMode: setListBookingsDateRangeModeAction,
  setListBookingsEndCursor: setListBookingsEndCursorAction,
  setListBookingsEndTime: setListBookingsEndTimeAction,
  setListBookingsFilterBookingId: setListBookingsFilterBookingIdAction,
  setListBookingsFilterBookingStatus: setListBookingsFilterBookingStatusAction,
  setListBookingsFilterBookingType: setListBookingsFilterBookingTypeAction,
  setListBookingsFilterCoach: setListBookingsFilterCoachAction,
  setListBookingsFilterVenue: setListBookingsFilterVenueAction,
  setListBookingsFirst: setListBookingsFirstAction,
  setListBookingsFirstDate: setListBookingsFirstDateAction,
  setListBookingsLastRecordDate: setListBookingsLastRecordDateAction,
  setListBookingsListBookingsActiveFirst:
    setListBookingsListBookingsActiveFirstAction,
  setListBookingsOrderBy: setListBookingsOrderByAction,
  setListBookingsPageInfo: setListBookingsPageInfoAction,
  setListBookingsPageNumber: setListBookingsPageNumberAction,
  setListBookingsPageSize: setListBookingsPageSizeAction,
  setListBookingsRecordLoaded: setListBookingsRecordLoadedAction,
  setListBookingsRowIndex: setListBookingsRowIndexAction,
  setListBookingsStartTime: setListBookingsStartTimeAction,
  setListInvoicesDateRangeMode: setListInvoicesDateRangeModeAction,
  setListInvoicesEndCursor: setListInvoicesEndCursorAction,
  setListInvoicesEndDate: setListInvoicesEndDateAction,
  setListInvoicesFilterPaymentMethod: setListInvoicesFilterPaymentMethodAction,
  setListInvoicesFirst: setListInvoicesFirstAction,
  setListInvoicesFirstDate: setListInvoicesFirstDateAction,
  setListInvoicesOrderBy: setListInvoicesOrderByAction,
  setListInvoicesPageInfo: setListInvoicesPageInfoAction,
  setListInvoicesPageNumber: setListInvoicesPageNumberAction,
  setListInvoicesPageSize: setListInvoicesPageSizeAction,
  setListInvoicesRecordLoaded: setListInvoicesRecordLoadedAction,
  setListInvoicesRowIndex: setListInvoicesRowIndexAction,
  setListInvoicesStartDate: setListInvoicesStartDateAction,
  setListNotificationsEndCursor: setListNotificationsEndCursorAction,
  setListNotificationsFilterSearchTerm:
    setListNotificationsFilterSearchTermAction,
  setListNotificationsFirst: setListNotificationsFirstAction,
  setListNotificationsOrderBy: setListNotificationsOrderByAction,
  setListNotificationsPageInfo: setListNotificationsPageInfoAction,
  setListNotificationsPageNumber: setListNotificationsPageNumberAction,
  setListNotificationsPageSize: setListNotificationsPageSizeAction,
  setListNotificationsRecordLoaded: setListNotificationsRecordLoadedAction,
  setListNotificationsRowIndex: setListNotificationsRowIndexAction,
  setListPackagesDatesSort: setListPackagesDatesSortAction,
  setListPackagesEndCursor: setListPackagesEndCursorAction,
  setListPackagesFilterExpiry: setListPackagesFilterExpiryAction,
  setListPackagesFilterPackageName: setListPackagesFilterPackageNameAction,
  setListPackagesFilterPackageStatus: setListPackagesFilterPackageStatusAction,
  setListPackagesFirst: setListPackagesFirstAction,
  setListPackagesOrderBy: setListPackagesOrderByAction,
  setListPackagesPageInfo: setListPackagesPageInfoAction,
  setListPackagesPageNumber: setListPackagesPageNumberAction,
  setListPackagesPageSize: setListPackagesPageSizeAction,
  setListPackagesRecordLoaded: setListPackagesRecordLoadedAction,
  setListPackagesRowIndex: setListPackagesRowIndexAction,
  setListServicesFirstDate: setListServicesFirstDateAction,
  setListServicesIsShowModal: setListServicesIsShowModalAction,
  setListServicesPackageNo: setListServicesPackageNoAction,
  setListServicesTransactionDetails: setListServicesTransactionDetailsAction,
  setListServiceTransactionsDateRangeMode:
    setListServiceTransactionsDateRangeModeAction,
  setListServiceTransactionsEndCursor:
    setListServiceTransactionsEndCursorAction,
  setListServiceTransactionsEndDate: setListServiceTransactionsEndDateAction,
  setListServiceTransactionsFilterServiceType:
    setListServiceTransactionsFilterServiceTypeAction,
  setListServiceTransactionsFilterTransactionType:
    setListServiceTransactionsFilterTransactionTypeAction,
  setListServiceTransactionsFirst: setListServiceTransactionsFirstAction,
  setListServiceTransactionsOrderBy: setListServiceTransactionsOrderByAction,
  setListServiceTransactionsPageInfo: setListServiceTransactionsPageInfoAction,
  setListServiceTransactionsPageNumber:
    setListServiceTransactionsPageNumberAction,
  setListServiceTransactionsPageSize: setListServiceTransactionsPageSizeAction,
  setListServiceTransactionsRecordLoaded:
    setListServiceTransactionsRecordLoadedAction,
  setListServiceTransactionsRowIndex: setListServiceTransactionsRowIndexAction,
  setListServiceTransactionsStartDate:
    setListServiceTransactionsStartDateAction,
  setListSubscriptionsEndCursor: setListSubscriptionsEndCursorAction,
  setListSubscriptionsFirst: setListSubscriptionsFirstAction,
  setListSubscriptionsOrderBy: setListSubscriptionsOrderByAction,
  setListSubscriptionsPageInfo: setListSubscriptionsPageInfoAction,
  setListSubscriptionsPageNumber: setListSubscriptionsPageNumberAction,
  setListSubscriptionsPageSize: setListSubscriptionsPageSizeAction,
  setListSubscriptionsRecordLoaded: setListSubscriptionsRecordLoadedAction,
  setListSubscriptionsRowIndex: setListSubscriptionsRowIndexAction,
  setListSubscriptionsSearchTerm: setListSubscriptionsSearchTermAction,
  setMemberIDs: setMemberIDsAction,
  setResourceName: setResourceNameAction,
  setSelectedMember: setSelectedMemberAction,
  setServiceTxnsEndTime: setServiceTxnsEndTimeAction,
  setServiceTxnsStartTime: setServiceTxnsStartTimeAction,
  setSoaEndTime: setSoaEndTimeAction,
  setSoaStartTime: setSoaStartTimeAction,
  setTabIndex: setTabIndexAction,
  setTransferAmount: setTransferAmountAction,
  setTransferRecipient: setTransferRecipientAction,
} = membersEditMemberSlice.actions;
