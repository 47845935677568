import {
  Input as CuiInput,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { FocusEventHandler, ReactNode } from 'react';
import {
  DeepMap,
  DeepPartial,
  FieldError,
  FieldValues,
  Path,
  useFormContext,
  useFormState,
} from 'react-hook-form';
import tw from 'twin.macro';

interface InputProps<State> {
  as?: any | undefined;
  autoComplete?: 'on' | 'off';
  containerOnFocus?: FocusEventHandler<HTMLDivElement> | undefined;
  helperText?: string;
  inputLeftElement?: ReactNode;
  inputRightElement?: ReactNode;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  label: string | ReactNode;
  name: Path<State>;
  placeholder?: string;
  requiredText?: string;
  type?:
    | 'button'
    | 'checkbox'
    | 'color'
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'file'
    | 'hidden'
    | 'image'
    | 'month'
    | 'number'
    | 'password'
    | 'radio'
    | 'range'
    | 'reset'
    | 'search'
    | 'submit'
    | 'tel'
    | 'text'
    | 'time'
    | 'url'
    | 'week';
  validationRules?: Record<string, any>;
  valueAsNumber?: boolean;
}

const Overlay = styled.div`
  ${tw`absolute inset-0 cursor-not-allowed`};
`;

export const Input = <State extends FieldValues>(props: InputProps<State>) => {
  const {
    as,
    autoComplete = 'off',
    containerOnFocus,
    helperText,
    inputLeftElement,
    inputRightElement,
    isDisabled,
    isReadOnly,
    label,
    name,
    placeholder,
    requiredText,
    type,
    validationRules,
    valueAsNumber = false,
  } = props;

  const { control, register } = useFormContext<State>();

  const { errors } = useFormState({
    control,
  });

  const errorIndex: keyof DeepMap<DeepPartial<State>, FieldError> = name as any;

  return (
    <FormControl
      isDisabled={isDisabled}
      //@ts-ignore
      isInvalid={errors[errorIndex] ? true : false}
      isRequired={requiredText ? true : false}
    >
      <FormLabel htmlFor={name as string}>{label}</FormLabel>
      <InputGroup onFocus={containerOnFocus}>
        {inputLeftElement && (
          <InputLeftElement>{inputLeftElement}</InputLeftElement>
        )}
        <CuiInput
          as={as}
          autoComplete={autoComplete}
          id={name}
          isDisabled={isDisabled}
          //@ts-ignore
          isInvalid={errors[errorIndex] ? true : false}
          {...register(name, {
            required: requiredText ? requiredText : false,
            ...validationRules,
            valueAsNumber,
          })}
          bg={isReadOnly ? 'gray.100' : undefined}
          borderColor={isReadOnly ? 'transparent' : undefined}
          isReadOnly={isReadOnly}
          isRequired={requiredText ? true : false}
          placeholder={placeholder}
          pointerEvents={isReadOnly ? 'none' : undefined}
          type={type}
          variant="outline"
        />
        {inputRightElement && (
          <InputRightElement>{inputRightElement}</InputRightElement>
        )}
      </InputGroup>
      {/* @ts-ignore */}
      {!errors[errorIndex] && helperText && helperText && (
        <FormHelperText>{helperText}</FormHelperText>
      )}
      {/* @ts-ignore */}
      {errors[errorIndex] && (
        <FormErrorMessage>
          <>
            <FormErrorIcon />
            {/* @ts-ignore */}
            {errors[errorIndex].message}
          </>
        </FormErrorMessage>
      )}
      {isReadOnly && <Overlay />}
    </FormControl>
  );
};
